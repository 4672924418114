import { GroupTwoTone } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import React, { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, Widget } from '../../../shared';
import { sortResident } from '../../utils';
import { ExpectingChildrenInfo } from '../expecting-children-info/expecting-children-info.component';
import { ResidentInfo } from '../resident-info/resident-info.component';

interface Props {
    rentalRequest: IRentalRequest;
}
export const FamilySituation: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();

    const primaryResident = useMemo(() => {
        return rentalRequest.residents?.find((resident) => resident.isPrimaryResident);
    }, [rentalRequest]);

    const otherResidents = useMemo(() => {
        return rentalRequest.residents?.filter((resident) => !resident.isPrimaryResident).sort(sortResident);
    }, [rentalRequest]);

    return (
        <Widget title={t('familySituation')} icon={<GroupTwoTone color="primary" />} hasTable>
            {primaryResident && otherResidents && (
                <Stack>
                    <ResidentInfo resident={primaryResident} />

                    {otherResidents?.map((resident) => (
                        <Fragment key={resident.id}>
                            <Divider />
                            <ResidentInfo resident={resident} />
                        </Fragment>
                    ))}

                    {rentalRequest?.expectingChildren?.isExpecting && (
                        <ExpectingChildrenInfo expectingChildren={rentalRequest?.expectingChildren} />
                    )}
                </Stack>
            )}
        </Widget>
    );
};
