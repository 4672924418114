import { useCallback } from 'react';
import { ICoResident, Relation } from '../../shared';
import { ageBetween18And25 } from './age-between-18-and-25.util';

export const useIsResidentIncomplete = () =>
    useCallback((resident: ICoResident) => {
        if (!resident.firstName || !resident.lastName || !resident.dateOfBirth || !resident.socialSecurityNumber)
            return true;
        if (resident.relation === Relation.CHILD && ageBetween18And25(resident.dateOfBirth)) {
            return resident.hasChildSupport === undefined || resident.hasChildSupport === null;
        }
        return false;
    }, []);
