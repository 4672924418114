import { FormControl, TextField } from '@mui/material';
import React, { ComponentProps, FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CarityInputLabel } from '../input-label/input-label.component';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    label: string;
    maxWidth?: number | string;
    externalOnBlur?: (fieldName: string) => void;
};

export const ControlledInput: FC<Props> = ({
    name,
    label,
    maxWidth,
    externalOnBlur,
    defaultValue,
    inputProps,
    ...textFieldProps
}) => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;

    const handleBlur = useCallback(
        (internalOnBlur: any) => {
            internalOnBlur();
            externalOnBlur?.(name);
        },
        [externalOnBlur, name],
    );

    return (
        <Controller
            name={name}
            defaultValue={defaultValue || ''}
            render={({ field: { onChange, value, onBlur: internalOnBlur }, fieldState: { error } }) => (
                <FormControl sx={{ maxWidth, '.MuiOutlinedInput-root': { minWidth: label.length * 8 } }}>
                    <CarityInputLabel
                        name={name}
                        label={label}
                        disabled={textFieldProps.disabled}
                        required={textFieldProps.required}
                        inlineLabel={theme.name !== 'antwerp'}
                    />
                    <TextField
                        id={`${name}-field`}
                        className={textFieldProps.multiline ? 'controlled-multiline-input' : 'controlled-input'}
                        value={value}
                        onChange={onChange}
                        inputProps={inputProps}
                        onBlur={() => handleBlur(internalOnBlur)}
                        error={!!error}
                        helperText={error ? error.message || t('fallbackError') : null}
                        {...textFieldProps}
                        required={false}
                    />
                </FormControl>
            )}
        />
    );
};
