export default {
    financial: 'Financieel',
    financial_antwerp: 'Inkomen',
    financialIntro:
        ' Vul de inkomensgegevens in van jezelf en elke medebewoner (18 jaar of ouder) voor wie je geen financiële hulp (groeipakket of kinderbijslag) meer krijgt.\n' +
        'We houden rekening met de financiële gegevens van de hoofdhuurder én elke meerderjarige medebewoner als we je aanvraag verwerken. \n\n' +
        'Je vindt de financiële gegevens op je belastingbrief.',
    financialHelpLinkText: 'Hulp nodig bij het invullen van deze gegevens? Lees meer op ',
    financialHelpLink: 'deze helppagina',
    hasNoTaxBillForFullYear: 'Ik heb nog geen aanslagbiljet voor een volledig werkjaar',
    hasBudgetRentingDocumentHelpText:
        'Je hebt een geldig attest nodig om te kunnen budgethuren. Je vraagt dit attest aan via ',
    hasBudgetRentingDocumentHelpTextLink: 'het Formulierenloket van Wonen in Vlaanderen.',

    primaryResidentFinances: {
        jointTaxableIncome: 'Hoeveel was je jaarlijks gezamenlijk belastbaar inkomen?',
        hasIndividualTaxableIncome: 'Had je een afzonderlijk belastbaar inkomen?',
        individualTaxableIncome: 'Hoeveel was je afzonderlijk belastbaar inkomen op jaarbasis?',
        hasMonthlyTaxableIncome: 'Ontvang je momenteel een loon?',
        monthlyTaxableIncome: 'Hoeveel is je maandelijks belastbaar inkomen, voor een reguliere maand?',
        hasLivingWage: 'Kreeg je in {{year}} een leefloon?',
        livingWageAmount: 'Hoeveel totaal leefloon ontving je in {{year}}?',
        hasDisabilityIncome_year:
            'Kreeg je een inkomensvervangende tegemoetkoming voor personen met een handicap in {{year}}?',
        disabilityIncomeAmount_year: 'Hoeveel totale inkomensvervangende tegemoetkoming ontving je in {{year}}?',
        hasDisabilityIncome_month:
            'Krijg je momenteel een inkomensvervangende tegemoetkoming voor personen met een handicap?',
        disabilityIncomeAmount_month: 'Hoeveel inkomensvervangende tegemoetkoming ontvang je maandelijks?',
        hasForeignIncome_year: 'Kreeg je van belasting vrijgestelde beroepsinkomsten uit het buitenland in {{year}}?',
        hasForeignIncome_month: 'Krijg je momenteel van belasting vrijgestelde beroepsinkomsten uit het buitenland?',
        foreignIncomeAmount_year: 'Hoeveel van dergelijke inkomsten ontving je in {{year}}?',
        foreignIncomeAmount_month: 'Hoeveel van dergelijke inkomsten ontvang je in een reguliere maand?',
        hasBudgetRentingDocument: 'Beschik je over een geldig Vlaams attest voor budgethuren?',
        expirationDateBudgetRentingDocument: 'Tot wanneer is jouw attest geldig?',
    },

    coResidentFinances: {
        jointTaxableIncome: 'Hoeveel was het jaarlijks gezamenlijk belastbaar inkomen van {{name}}?',
        hasIndividualTaxableIncome: 'Had {{name}} een afzonderlijk belastbaar inkomen?',
        individualTaxableIncome: 'Hoeveel was dit afzonderlijk belastbaar inkomen van {{name}} op jaarbasis?',
        hasMonthlyTaxableIncome: 'Ontvangt {{name}} momenteel een loon?',
        monthlyTaxableIncome: 'Hoeveel is het maandelijks belastbaar inkomen van {{name}}, voor een reguliere maand?',
        hasLivingWage: 'Kreeg {{name}} in {{year}} een leefloon?',
        livingWageAmount: 'Hoeveel totaal leefloon ontving {{name}} in {{year}}?',
        hasDisabilityIncome:
            'Kreeg {{name}} een inkomensvervangende tegemoetkoming voor personen met een handicap in {{year}}?',
        disabilityIncomeAmount: 'Hoeveel totale inkomensvervangende tegemoetkoming ontving {{name}} in {{year}}?',
        hasForeignIncome: 'Kreeg {{name}} van belasting vrijgestelde beroepsinkomsten uit het buitenland in {{year}}?',
        foreignIncomeAmount: 'Hoeveel van dergelijke inkomsten ontving {{name}} je in {{year}}?',
        hasBudgetRentingDocument: 'Beschikt {{name}} over een geldig Vlaams attest voor budgethuren?',
        expirationDateBudgetRentingDocument: 'Tot wanneer is het attest van {{name}} geldig?',
    },

    taxableIncome: 'Belastbaar inkomen',
    taxableIncomeHelpText: 'Je vindt deze info terug op jouw aanslagbiljet.',
    taxYear: 'Van welk jaar is je laatste aanslagbiljet?',
    isJointTaxReturn: 'Gemeenschappelijke aangifte',
    livingWageHelpText: 'Je kan deze informatie opvragen via ',
    livingWageLink: 'deze link',
    disabilityIncomeHelpText: 'Je kan deze informatie opvragen via ',
    disabilityIncomeDocumentUpload: 'Gelieve een attest van inkomensvervangende tegemoetkoming op te laden.',
    disabilityIncomeDocumentLink: 'My handicap',
    foreignIncomeHelpText:
        'Dit zijn lonen, pensioenen, dividenden, intresten of inkomsten die je ontvangt uit het buitenland.',
    taxBillDocumentUpload: 'Gelieve het laatst ingediende aanslagbiljet als bijlage toe te voegen aan jouw dossier.',
    monthlyTaxBillDocumentUpload: 'Gelieve je 3 laatste loonfiches op te laden.',
    taxBillDocumentUploadHelpText: 'Je kan het aanslagbiljet raadplegen en downloaden via ',
    taxBillDocumentLink: 'My Minfin',
    taxYearOption: 'Aanslagjaar {{taxYear}}, inkomensjaar {{incomeYear}}',

    hasRentSubsidy: 'Kreeg je een huursubsidie of een huurpremie?',
    hasRentSubsidyHelpText: 'Lees meer op de pagina van <0>Vlaamse huursubsidie</0> of <1>Vlaamse huurpremie</1>',
    rentSubsidy: 'Hoeveel bedraagt de huursubsidie of huurpremie die je maandelijks ontvangt?',
    incorrectRentSubsidy: 'Het bedrag van de subsidie of premie ligt tussen de €50 en €350',
    useRentSubsidyForMaxRent: 'Wil je dit bedrag mee opnemen in de berekening van de te besteden huurprijs?',
    rentSubsidyDocument: 'Gelieve een attest van huursubsidie of een huurpremie op te laden',
};
