import { IRegion, ListModel, ListQueryParam } from '../models';
import { rentingApi } from './renting-api.client';

class RegionClient {
    public async getRegions({ pageSize = 10, ...query }: ListQueryParam): Promise<ListModel<IRegion>> {
        const { data } = await rentingApi.get<ListModel<IRegion>>('/regions', {
            params: {
                pageSize,
                ...query,
            },
        });
        return data;
    }
}

export const regionClient = new RegionClient();
