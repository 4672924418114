import { Divider, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import React, { FC, useEffect, useMemo } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledCheckbox,
    ControlledSelect,
    FormGrid,
    IFinancialDataForm,
    IPrimaryResident,
    useGetValidation,
    useMyRentalRequest,
} from '../../../shared';
import { FinancialDataTaxBillFullYear } from '../financial-data-tax-bill-full-year/financial-data-tax-bill-full-year.component';
import { FinancialDataNoTaxBillFullYear } from '../financial-data-no-tax-bill-full-year/financial-data-no-tax-bill-full-year.component';
import { FinancialDataResidentCommonQuestions } from '../finacial-data-resident-common-questions/financial-data-resident-common-questions.component';

interface Props {
    name: string;
    field: FieldArrayWithId<IFinancialDataForm, 'residents'>;
    disabled: boolean;
    updateWithInvalidate: () => void;
}

export const FinancialDataResident: FC<Props> = ({ name, field, disabled, updateWithInvalidate }) => {
    const { t } = useTranslation();

    const { control, watch, setValue } = useFormContext();
    const watchValues = watch(name);
    const watchResidents = watch('residents');

    const { data: rentalRequest } = useMyRentalRequest();
    const resident = useMemo(() => rentalRequest?.residents.find((r) => r.id === field.id), [rentalRequest, field]);
    const { data: validation } = useGetValidation(rentalRequest?.showValidation || false);

    const primaryResident = useMemo(
        () => watchResidents?.find((res: IPrimaryResident) => res?.isPrimaryResident),
        [watchResidents],
    );

    const watchHasTaxBillForFullYear = useMemo(() => {
        return (
            (!!watchValues?.financialData?.taxYear && watchValues?.financialData?.taxYear !== 'none') ||
            watchValues?.financialData?.isJointTaxReturn
        );
    }, [watchValues?.financialData?.taxYear, watchValues?.financialData?.isJointTaxReturn]);

    useEffect(() => {
        if (primaryResident.financialData?.taxYear === 'none') {
            setValue(`${name}.financialData.isJointTaxReturn`, false);
        }
    }, [name, primaryResident.financialData?.taxYear, setValue]);

    const watchIncomeYear = useMemo(() => {
        const year = parseInt(
            watchValues?.financialData?.isJointTaxReturn
                ? primaryResident?.financialData?.taxYear
                : watchValues?.financialData?.taxYear,
            10,
        );
        if (year) {
            return year - 1;
        }
        return '';
    }, [
        watchValues?.financialData?.isJointTaxReturn,
        primaryResident?.financialData?.taxYear,
        watchValues?.financialData?.taxYear,
    ]);

    const isPrimaryResident = field.isPrimaryResident;

    const fullName = useMemo(() => `${field.firstName} ${field.lastName}`, [field]);

    const lastTwoYearsAndPreviouslyFilledInYear = useMemo(() => {
        const currentYear = getYear(new Date());

        const previouslyFilledInYear = resident?.financialData?.hasTaxBillForFullYear
            ? resident?.financialData?.taxYear || ''
            : '';
        const uniqueValues = [
            ...new Set([previouslyFilledInYear, currentYear.toString(), (currentYear - 1).toString()]),
        ].filter((year) => !!year);

        return uniqueValues.sort();
    }, [resident]);

    const taxYearOptions = [
        ...lastTwoYearsAndPreviouslyFilledInYear.map((year) => ({
            id: year,
            name: t('taxYearOption', {
                taxYear: year,
                incomeYear: parseInt(year, 10) - 1,
            }),
        })),
        { id: 'none', name: t('hasNoTaxBillForFullYear') },
    ];

    const coResidentNoTaxYear =
        !isPrimaryResident &&
        primaryResident?.financialData?.taxYear !== '' &&
        primaryResident?.financialData?.taxYear !== 'none';

    return (
        <>
            <Stack mb={3}>
                <Typography variant="subtitle1">{fullName}</Typography>
                <Typography variant="subtitle2">{t('taxableIncome')}</Typography>
                <Typography variant="caption">{t('taxableIncomeHelpText')}</Typography>
            </Stack>

            <FormGrid sx={{ '&:first-of-type': { width: coResidentNoTaxYear ? '100%' : 'auto' } }}>
                {coResidentNoTaxYear && (
                    <ControlledCheckbox
                        control={control}
                        label={t('isJointTaxReturn')}
                        name={`${name}.financialData.isJointTaxReturn`}
                        disabled={disabled}
                    />
                )}

                {(isPrimaryResident ||
                    primaryResident?.financialData?.taxYear === 'none' ||
                    !watchValues?.financialData?.isJointTaxReturn) && (
                    <ControlledSelect
                        name={`${name}.financialData.taxYear`}
                        options={taxYearOptions}
                        label={t('taxYear')}
                        disabled={disabled}
                        required
                        handleChange={updateWithInvalidate}
                    />
                )}

                {(watchValues?.financialData?.taxYear || watchValues?.financialData?.isJointTaxReturn) && (
                    <FormGrid width="100%">
                        {watchHasTaxBillForFullYear ? (
                            <FinancialDataTaxBillFullYear
                                name={name}
                                fullName={fullName}
                                isPrimaryResident={isPrimaryResident}
                                updateWithInvalidate={updateWithInvalidate}
                                field={field}
                                year={watchIncomeYear}
                                resident={resident}
                                validation={validation}
                                disabled={disabled}
                            />
                        ) : (
                            <FinancialDataNoTaxBillFullYear
                                name={name}
                                fullName={fullName}
                                isPrimaryResident={isPrimaryResident}
                                updateWithInvalidate={updateWithInvalidate}
                                field={field}
                                resident={resident}
                                validation={validation}
                                disabled={disabled}
                            />
                        )}

                        <Divider />

                        <FinancialDataResidentCommonQuestions
                            name={name}
                            fullName={fullName}
                            isPrimaryResident={isPrimaryResident}
                            resident={resident}
                            watchHasTaxBillForFullYear={watchHasTaxBillForFullYear}
                            year={watchIncomeYear}
                            updateWithInvalidate={updateWithInvalidate}
                            field={field}
                            disabled={disabled}
                        />
                    </FormGrid>
                )}
            </FormGrid>
        </>
    );
};
