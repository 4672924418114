import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { RentalRequestStatus, useBreakpoint, useMyRentalRequest } from '../../../shared';
import { ReadOnlyInfoMobile } from './read-only-info/read-only-info-mobile.component';
import { ReadOnlyInfo } from './read-only-info/read-only-info.component';

export const ReadOnlyInfoBox: FC = () => {
    const { data: rentalRequest } = useMyRentalRequest();
    const statusSubmittedOrChangesRequested =
        rentalRequest?.status === RentalRequestStatus.SUBMITTED ||
        rentalRequest?.status === RentalRequestStatus.CHANGES_REQUESTED;

    return (
        <Stack sx={{ position: 'sticky', height: 'fit-content' }}>
            {useBreakpoint('md') ? (
                <ReadOnlyInfo
                    rentalRequest={rentalRequest}
                    statusSubmittedOrChangesRequested={statusSubmittedOrChangesRequested}
                />
            ) : (
                <ReadOnlyInfoMobile
                    rentalRequest={rentalRequest}
                    statusSubmittedOrChangesRequested={statusSubmittedOrChangesRequested}
                />
            )}
        </Stack>
    );
};
