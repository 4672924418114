export default {
    pages: {
        home: 'Home | Betaalbaar huren',
        myRentalRequest: 'Mijn dossier | Betaalbaar huren',
        form: 'Formulier | Betaalbaar huren',
        login: 'Aanmelden | Betaalbaar huren',

        home_antwerp: 'Home | Budgethuren',
        myRentalRequest_antwerp: 'Mijn dossier | Budgethuren',
        form_antwerp: 'Formulier | Budgethuren',
        login_antwerp: 'Aanmelden | Budgethuren',
    },

    no: 'Nee',
    yes: 'Ja',
    unknown: 'Onbekend',
    back: 'Terug',
    save: 'Bewaar',
    cancel: 'Annuleer',
    confirm: 'Bevestig',
    edit: 'Bewerk',
    delete: 'Verwijder',
    next: 'Volgende',
    previous: 'Vorige',
    agree: 'Akkoord',
    here: 'hier',
    logOut: 'Uitloggen',
    logIn: 'Inloggen',

    lastSavedInfo: 'Laatst bewaard {{ lastSavedDateString }}',
    residentInfo: 'Persoonsgegevens',
    historyOfResidency: 'Woonhistoriek',
    coResident: 'Medebewoner',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    dateOfBirth: 'Geboortedatum',
    socialSecurityNumber: 'Rijksregisternummer',
    relation: 'Relatie',
    address: 'Adres',
    street: 'Straat',
    number: 'Nummer',
    box: 'Busnummer',
    zip: 'Postcode',
    city: 'Gemeente',
    country: 'Land',
    contactType: 'Communicatie voorkeur',
    email: 'Email',
    phone: 'Telefoonnummer',
    taxYear: 'Aanslagjaar',
    individualTaxableIncome: 'Afzonderlijk belastbaar inkomen',
    jointTaxableIncome: 'Gezamenlijk belastbaar inkomen',
    property: 'Eigendom',
    workRelation: 'Werkband',
    studyRelation: 'Studeerband',
    childSupport: 'Kinderbijslag of groeipakket',

    PARTNER: 'Partner',
    CHILD: 'Kind',
    OTHER: 'Andere',

    requiredInput: '* : antwoord verplicht',
    requiredField: '{{fieldName}} is verplicht',
    requiredQuestion: 'Deze vraag is verplicht',

    invalidFormat: '{{fieldName}} ongeldig formaat',
    ageYoungerThan18: 'Leeftijd is jonger dan 18 jaar',
    ageOlderThan18: 'Leeftijd is ouder dan 18 jaar',
    maxLength: 'Maximum {{amount}} karakters toegestaan',
    moneyFieldGreaterThanZero: 'Het bedrag moet groter dan 0 zijn',
    arrayNotEmpty: 'Selecteer minstens 1 item',
    dateInThePast: 'Datum moet in het verleden liggen',
    dateInTheFuture: 'Datum moet in de toekomst liggen',
    ageGreaterThan: 'Leeftijd mag niet hoger zijn dan {{amount}} jaar',
    isExpectedDateInTheFuture: 'De verwachte datum mag niet in het verleden liggen.',
    amountGreaterThan: 'Aantal moet groter zijn dan {{amount}}',
    amountSmallerThan: 'Aantal moet kleiner zijn dan {{amount}}',
    zipcodeIsInvalid: 'Een postcode bestaat uit 4 karakters.',
    phoneIsInvalid: 'Gelieve een geldig telefoonnummer in te vullen.',

    skipToMainContentText: 'Ga naar de hoofdinhoud',
    profileButton: 'Profiel knop',
    account: 'Gebruiker',
    generalInformation: ' Algemene informatie',
    privacyPolicy: 'Privacy verklaring',
    linkTextToPrivacyPolicy: 'Bekijk hier onze privacyverklaring.',

    faq: 'Veelgestelde vragen',
    faqLinkInfo: 'Meer weten over betaalbaar huren?\nLees meer ',
    faqLinkInfo_antwerp: 'Meer weten over budgethuren?\nLees meer ',
    contactFaqLink: 'FAQ',
    contactFaq: 'Is je financiële situatie sterk gewijzigd? In de <0>{{link}}</0> staat wat je kan doen in dit geval.',
    contactHelpdeskInfoText: 'Bij vragen of problemen kan u mailen naar betaalbaarhuren@antwerpen.be.',

    addAsset: 'Extra document toevoegen',
    uploadFile: 'Sleep je document hierheen, of klik om te selecteren',
    deleteAttachmentTitle: 'Bijlage verwijderen',
    deleteAttachmentDescription: 'Ben je zeker dat je deze bijlage wilt verwijderen?',
    maxFileSize: 'Maximum bestand grootte: ',
    uploadLabel: 'Gelieve het attest als bijlage toe te voegen',

    all: 'Selecteer alle',
};
