import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTimeString, Page, useMyRentalRequest } from '../../../shared';
import { EditRentalRequest } from '../../components';

export const SubmittedPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const faqLink = window.carity.environment.faqLink;
    const { data: myRentalRequest } = useMyRentalRequest();

    const isFirstSubmit = () => {
        if (myRentalRequest?.submittedDate) {
            return (
                formatDateTimeString(myRentalRequest?.submittedDate) >=
                formatDateTimeString(myRentalRequest?.updatedDate)
            );
        }
        return true;
    };

    return (
        <Page>
            <Paper sx={{ width: '80%', justifyContent: 'center', p: 4, mx: 'auto' }}>
                <Grid container justifyContent="center">
                    <Box sx={{ textAlign: 'center', maxWidth: '700px' }}>
                        <CheckCircleIcon color="success" fontSize="large" />
                        <Typography variant="subtitle2" sx={{ pb: 2 }}>
                            {isFirstSubmit()
                                ? t('myRentalRequest.submittedCompletedRequestPart1')
                                : t('myRentalRequest.resubmittedCompletedRequestPart1')}
                        </Typography>

                        <Typography variant="body1" sx={{ pb: 2 }}>
                            {t('myRentalRequest.completedRequestPart2')}
                        </Typography>

                        <Typography variant="body1" sx={{ pb: 4 }}>
                            <Trans
                                i18nKey="myRentalRequest.completedRequestPart3"
                                components={[<Link to={faqLink ? faqLink : '/'} target="__blank" />]}
                                values={{ link: t('here') }}
                            />
                        </Typography>

                        <EditRentalRequest onEdit={() => navigate('/my-rental-request/edit')} />
                        <Button
                            variant="contained"
                            component={Link}
                            to="/my-rental-request"
                            sx={{ width: { xs: '100%', md: 'auto' }, ml: { xs: 0, md: '10px' } }}
                        >
                            {t('viewMyRentalRequest')}
                        </Button>
                    </Box>
                </Grid>
            </Paper>
        </Page>
    );
};
