import { Checkbox, FormControl, Stack } from '@mui/material';
import React, { ComponentProps, FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { CarityInputLabel } from '../input-label/input-label.component';

type Props = ComponentProps<typeof Checkbox> & {
    control: Control<any>;
    name: string;
    label: string;
    initialValue?: boolean;
};

export const ControlledCheckbox: FC<Props> = ({ name, label, control, initialValue = false, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={initialValue}
            render={({ field: { name, onChange, value } }) => (
                <FormControl>
                    <Stack direction="row" alignItems="start" mt={1}>
                        <Checkbox
                            id={`${name}-field`}
                            {...props}
                            checked={value}
                            onChange={onChange}
                            name={name}
                            sx={{ p: 0, mr: 1 }}
                        />
                        <CarityInputLabel
                            name={name}
                            label={label}
                            disabled={props.disabled}
                            required={props.required}
                        />
                    </Stack>
                </FormControl>
            )}
        />
    );
};
