import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    FormGrid,
    IExtraInfoResidentsForm,
    Loading,
    RentalRequestStatus,
    useExtraInfoResidentsValidator,
    useGetValidation,
    useMyRentalRequest,
    useUpdateMyRentalRequest,
} from '../../../shared';
import { ChildSupport, ExpectingForm, FamilySituationForm, InfoBox } from '../../components';
import { extraInfoResidentsFromFormMapper, extraInfoResidentsToFormMapper } from '../../mappers';

export const RequestFormResidentsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: rentalRequest, isLoading } = useMyRentalRequest();
    const { data: validation } = useGetValidation(rentalRequest?.showValidation || false);
    const readOnly = useMemo(() => rentalRequest?.status !== RentalRequestStatus.DRAFT, [rentalRequest]);

    const { mutateAsync: updateRentalRequest } = useUpdateMyRentalRequest();

    const handlePreviousClick = useCallback(() => {
        navigate('/my-rental-request/edit/primary-resident');
    }, [navigate]);

    const hasChildren = rentalRequest?.residents.some((resident) => !resident.isAdult);
    const askMonthlyChildSupport = !window.carity.environment.forfaitChildSupport && hasChildren;
    const form = useForm<IExtraInfoResidentsForm>({
        mode: 'all',
        resolver: yupResolver(useExtraInfoResidentsValidator(askMonthlyChildSupport)),
    });

    const onSubmit = async (data: IExtraInfoResidentsForm) => {
        if (rentalRequest) {
            if (rentalRequest.status === RentalRequestStatus.DRAFT) {
                await updateRentalRequest({ rentalRequest: extraInfoResidentsFromFormMapper(rentalRequest, data) });
            }
            navigate('/my-rental-request/edit/financial');
        }
    };

    const update = useCallback(
        async (invalidate = false) => {
            if (rentalRequest?.status === RentalRequestStatus.DRAFT) {
                await updateRentalRequest({
                    rentalRequest: extraInfoResidentsFromFormMapper(rentalRequest, form.getValues()),
                    invalidate,
                });
            }
        },
        [rentalRequest, updateRentalRequest, form],
    );

    useEffect(() => {
        if (rentalRequest) {
            form.reset(extraInfoResidentsToFormMapper(rentalRequest));
        }
    }, [rentalRequest, form]);

    if (isLoading) return <Loading />;

    return (
        <>
            <Grid container columns={{ xs: 1, md: 2 }} justifyContent="space-between">
                <InfoBox readOnly={readOnly} />

                <Grid item sx={{ width: { xs: '100%', md: '79%' }, order: { xs: 2, md: 1 } }}>
                    <Card id="main-content">
                        <CardContent>
                            <FormGrid width="100%">
                                <FamilySituationForm />

                                <FormProvider {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} onBlur={() => update()} noValidate>
                                        {askMonthlyChildSupport && (
                                            <ChildSupport
                                                readOnly={readOnly}
                                                rentalRequest={rentalRequest}
                                                validation={validation}
                                            />
                                        )}
                                        <ExpectingForm
                                            readOnly={readOnly}
                                            update={update}
                                            disabled={readOnly}
                                            rentalRequest={rentalRequest}
                                            validation={validation}
                                        />
                                    </form>
                                </FormProvider>
                            </FormGrid>

                            <Typography variant="caption" color="grey" sx={{ display: 'block', mt: 3 }}>
                                {t('requiredInput')}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handlePreviousClick}>
                            {t('previous')}
                        </Button>

                        <Button variant="contained" onClick={form.handleSubmit(onSubmit)}>
                            {t('next')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
