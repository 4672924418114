import { Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledSwitch,
    FormGrid,
    ICoResident,
    IFinancialDataForm,
    IPrimaryResident,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { getErrorForResidentProperty } from '../../utils';
import { Assets } from '../assets/assets.component';

interface Props {
    name: string;
    updateWithInvalidate: () => void;
    field: FieldArrayWithId<IFinancialDataForm, 'residents'>;
    resident?: IPrimaryResident | ICoResident;
    validation?: IRentalRequestValidation;
    disabled?: boolean;
}

export const FinancialDataResidentBudgetRenting: FC<Props> = ({
    name,
    updateWithInvalidate,
    field,
    resident,
    validation,
    disabled,
}) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const hasBudgetRentingDocument = watch(`${name}.financialData.hasBudgetRentingDocument`);

    const { budgetRentingDocumentLink } = window.carity.environment;
    const budgetRentingDocumentError = getErrorForResidentProperty('BUDGET_RENTING_DOCUMENT', resident?.id, validation);

    return (
        <FormGrid width="100%">
            <ControlledSwitch
                name={`${name}.financialData.hasBudgetRentingDocument`}
                label={t('primaryResidentFinances.hasBudgetRentingDocument')}
                labelHelpText={
                    <Typography variant="caption" display="block">
                        {t('hasBudgetRentingDocumentHelpText')}
                        <Link href={budgetRentingDocumentLink} target="_blank" rel="noreferrer">
                            {t('hasBudgetRentingDocumentHelpTextLink')}
                        </Link>
                    </Typography>
                }
                onChangeSelected={updateWithInvalidate}
                disabled={disabled}
                required
            />

            {hasBudgetRentingDocument && (
                <FormGrid width="100%">
                    <ControlledDatePicker
                        name={`${name}.financialData.expirationDateBudgetRentingDocument`}
                        label={t('primaryResidentFinances.expirationDateBudgetRentingDocument')}
                        variant="outlined"
                        disabled={disabled}
                        sx={{ maxWidth: '300px' }}
                        required
                    />
                    <Assets
                        type={RentalRequestAssetType.BUDGET_RENTING_DOCUMENT}
                        residentId={field.id}
                        assets={resident?.financialData?.rentalRequestAssets}
                        label={t('uploadLabel')}
                        error={budgetRentingDocumentError}
                        disabled={disabled}
                    />
                </FormGrid>
            )}
        </FormGrid>
    );
};
