import { ChevronRight } from '@mui/icons-material';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Loading } from '../../../shared';
import { useGetProviders } from '../../hooks';
import { IProvider } from '../../models';

export const Login: React.FC = () => {
    const location = useLocation();
    const { city, theme } = window.carity.environment;
    const { data: providers } = useGetProviders();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.login', { context: theme.name });
    }, [t, theme]);

    const onClickProvider = useCallback(
        (provider: IProvider) => {
            window.location.href = `${provider.url}?redirect=${location.pathname}`;
        },
        [location.pathname],
    );

    useEffect(() => {
        if (providers?.length === 1) {
            onClickProvider(providers[0]);
        }
    }, [providers, onClickProvider]);

    return (
        <Paper
            sx={{
                maxWidth: 420,
                mt: 8,
                mx: 'auto',
                textAlign: 'center',
            }}
        >
            <Stack p={4} pb={0} spacing={2} alignItems="center">
                <img src={theme.logo} alt={city} width="80px" />
                <Typography variant="h2">{t('loginTitle')}</Typography>
                <Typography>{t('selectLoginMethod', { context: theme.name })}</Typography>

                {(providers?.length || 0) <= 1 ? (
                    <Box pt={2} pb={4}>
                        <Loading />
                    </Box>
                ) : (
                    <List sx={{ width: '100%' }}>
                        {providers?.map((provider) => (
                            <Fragment key={provider.name}>
                                <Divider component="li" />
                                <ListItem>
                                    <ListItemButton onClick={() => onClickProvider(provider)}>
                                        <ListItemText primary={provider.name} />
                                        <ChevronRight />
                                    </ListItemButton>
                                </ListItem>
                            </Fragment>
                        ))}
                    </List>
                )}
            </Stack>
        </Paper>
    );
};
