import { ArrowRight, ErrorOutline } from '@mui/icons-material';
import { Box, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IRentalRequestError,
    Loading,
    RentalRequestErrorProperties,
    RentalRequestErrorType,
    ResidentErrorProperties,
    useGetValidation,
    useMyRentalRequest,
} from '../../../shared';
import { propertyToStepMap } from '../../mappers';

export const Validation: FC = () => {
    const { t } = useTranslation();
    const { bondWithTheCity, theme } = window.carity.environment;
    const { data: myRentalRequest } = useMyRentalRequest();
    const { data: validation, isLoading } = useGetValidation();

    const getResident = useCallback(
        (id?: string) => {
            return myRentalRequest?.residents.find((res) => res.id === id);
        },
        [myRentalRequest],
    );

    const errors = useMemo(
        () =>
            validation?.errors
                .map((err) => ({
                    ...err,
                    fullName: `${getResident(err.residentId)?.lastName || ''} ${
                        getResident(err.residentId)?.firstName || ''
                    }`,
                }))
                .sort((a, b) => {
                    if (a.type === RentalRequestErrorType.RENTAL_REQUEST) return -1;
                    return a.fullName.localeCompare(b.fullName);
                }) || [],
        [getResident, validation],
    );

    const getStep = useCallback(
        (property: keyof RentalRequestErrorProperties | keyof ResidentErrorProperties, isPrimaryResident?: boolean) => {
            let href = '';
            let step = '';
            switch (propertyToStepMap[isPrimaryResident ? 'primary' : 'nonPrimary'][property]) {
                case '1':
                    href = 'primary-resident';
                    step = `1. ${t('primaryResident')}`;
                    break;
                case '2':
                    if (isPrimaryResident) {
                        href = 'primary-resident';
                        step = `1. ${t('primaryResident')}`;
                    } else {
                        href = 'residents';
                        step = `2. ${t('residents')}`;
                    }
                    break;
                case '3':
                    href = 'financial';
                    step = `3. ${t('financial', { context: theme.name })}`;
                    break;
                case '4':
                    href = 'bond-with-the-city';
                    step = `4. ${t('bondWithTheCity')}`;
                    break;
                case '5':
                    href = 'residence';
                    step = bondWithTheCity ? `5. ${t('residence')}` : `4. ${t('residence')}`;
                    break;
            }

            return (
                <Link href={href} color="#000000" rel="noopener">
                    {step}
                </Link>
            );
        },
        [bondWithTheCity, t, theme.name],
    );

    const getErrorSecondaryText = useCallback(
        (error: IRentalRequestError) => {
            return (
                <>
                    {error.errors.map((err, index) => (
                        <Box display="flex" alignItems="center" key={index} component="span">
                            <ArrowRight />
                            <Typography
                                sx={{ display: 'block' }}
                                variant="body2"
                                key={`${error.type} - ${error.residentId} - ${err.property} - ${err.message}`}
                                component="span"
                            >
                                {t(err.message, { property: t('errors.' + err.property, { context: theme.name }) })}{' '}
                                {getStep(err.property, getResident(error.residentId)?.isPrimaryResident)}.
                            </Typography>
                        </Box>
                    ))}
                </>
            );
        },
        [getResident, getStep, t, theme],
    );

    const getErrorMessage = (error: IRentalRequestError) => {
        switch (error.type) {
            case RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE:
                return '';
            case RentalRequestErrorType.RESIDENT:
                const resident = getResident(error.residentId);
                return (
                    <ListItemText
                        primary={
                            resident?.firstName === '' || resident?.lastName === ''
                                ? t('errors.unknown_name', 'Unknown')
                                : `${resident?.firstName} ${resident?.lastName}`
                        }
                        secondary={getErrorSecondaryText(error)}
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                        secondaryTypographyProps={{ color: '#000000' }}
                    />
                );
            case RentalRequestErrorType.RENTAL_REQUEST:
                return (
                    <ListItemText
                        primary={t('rentalRequestError')}
                        secondary={getErrorSecondaryText(error)}
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                        secondaryTypographyProps={{ color: '#000000' }}
                    />
                );
        }
    };

    if (isLoading) return <Loading />;

    return (
        <>
            <Box display="flex" alignItems="center" paddingBottom={1}>
                <ErrorOutline color="error" />
                <Typography sx={{ ml: 1, fontWeight: 'bold' }} color="error">
                    {t('myRentalRequest.incompleteTitle')}
                </Typography>
            </Box>
            <Typography component="span" variant="body2">
                {t('myRentalRequest.incompleteText')}
            </Typography>
            <List>
                {errors.map(
                    (error, index) =>
                        error.type !== RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE && (
                            <ListItem key={index} disableGutters>
                                {getErrorMessage(error)}
                            </ListItem>
                        ),
                )}
            </List>
        </>
    );
};
