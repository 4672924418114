import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as router from 'react-router-dom';
import { RentalRequestStatus, useMyRentalRequest } from '../../../shared';

export const HomeIntro: FC = () => {
    const { t } = useTranslation();
    const { data: myRentalRequest } = useMyRentalRequest();
    const { budgetRentingDocumentLink, theme } = window.carity.environment;

    return (
        <Card sx={{ height: '100%', px: 2 }}>
            <CardContent sx={{ '.MuiButton-root': { width: { xs: '100%', md: 'auto' } } }}>
                <Typography variant="h2">{t('home.title')}</Typography>

                <Typography my={3}>
                    {!!budgetRentingDocumentLink ? (
                        <Trans
                            i18nKey="home.intro"
                            components={[<Link href={budgetRentingDocumentLink} target="__blank" />]}
                            values={{ link: t('home.intro_antwerp_link'), context: theme.name }}
                        />
                    ) : (
                        <> {t('home.intro', { context: theme.name })}</>
                    )}
                </Typography>

                {!myRentalRequest || myRentalRequest?.status === RentalRequestStatus.DRAFT ? (
                    <Button id="main-content" variant="contained" component={router.Link} to="/my-rental-request/edit">
                        {t(myRentalRequest ? 'myRentalRequest.continueRequest' : 'processDescription.start')}
                    </Button>
                ) : (
                    <Button variant="contained" component={router.Link} to="/my-rental-request">
                        {t('myRentalRequest.title')}
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};
