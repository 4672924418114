export default {
    familySituation: 'Gezinsituatie',
    primary: 'Hoofdaanvrager',
    contactPreference: 'Voorkeur contact via',
    currentAddress: 'Huidig adres hoofdaanvrager',
    sameAsPrimaryResident: 'Zelfde als hoofdaanvrager',
    contactInformation: 'Contact informatie',
    expectingChildren: 'Zwangerschap',
    expectedAmount: 'Verwachtte aantal kinderen',
    expectedDate: 'Uitgerekende datum',
    pregnancyDocument: 'Zwangerschapsattest',

    residentialHistory: 'Minstens 3 jaar aaneensluitend gedomicilieerd in {{city}} in de afgelopen 10 jaar',
    aResidentOwnsProperty: 'Iemand uit het gezin is in het bezit van een eigendom',
    disabilityDocument: 'Attest van invaliditeit',
    downloadCertificate: 'Download attest',
    currentResidence: 'Huidige woonst',

    startMyRentalRequest: 'Je hebt nog geen dossier. Start nu met je inschrijving',

    deleteMyRentalRequest: 'Verwijder mijn dossier',
    deleteMyRentalRequestWarningTitle: 'Verwijder mijn dossier',
    deleteMyRentalRequestWarningText:
        'Ben je zeker dat je je dossier wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.',

    additionalQuestions: 'Bijkomende vragen',

    myRentalRequest: {
        title: 'Mijn Dossier',
        submit: 'Dossier indienen',
        reSubmit: 'Aangepast dossier indienen',
        incompleteTitle: 'Dossier incompleet',
        incompleteText:
            `Het lijkt er op dat er nog enkele gegevens ontbreken, of bepaalde gegevens niet geldig zijn. \n` +
            'Door op onderstaande links te klikken, komt u meteen bij het ontbrekend document terecht.',
        submittedCompletedRequestPart1: 'Bedankt voor het indienen van jouw dossier!',
        resubmittedCompletedRequestPart1: 'Bedankt voor het wijzigen van jouw dossier!',
        completedRequestPart2:
            'Jouw kandidatuur wordt nu verwerkt en alle persoonsgegevens worden gecontroleerd. Indien je voldoet aan ' +
            'alle criteria wordt jouw kandidatuur aanvaard. Voldoe je niet dan zal jouw kandidatuur automatisch worden ' +
            'verwijderd. Je wordt hiervan per mail op de hoogte gebracht. ',
        completedRequestPart3:
            'Heb je intussen nog vragen? Een antwoord op de meest gestelde vragen kan je <0>{{link}}</0> ' + 'vinden.',
        draftRequest: 'Je dossier is nog niet compleet.',
        continueRequest: 'Vervolledig dossier',
        autofillRentalRequestTitle: 'Jouw dossier wordt voorbereid.',
        autofillRentalRequestText: {
            partOne: 'We proberen zoveel mogelijk informatie m.b.t. jouw dossier automatisch aan te vullen.',
            partTwo: 'Zo hoef je zelf enkel nog de nodige extra informatie aan te vullen.',
        },
    },

    downloads: {
        RESIDENTIAL_HISTORY_DOCUMENT: 'Attesten woonhistoriek',
        TAX_BILL_DOCUMENT: 'Documenten-aanslagbiljet',
        MONTHLY_INCOME_DOCUMENT: 'Documenten-loonfiches',
        LIVING_WAGE_DOCUMENT: 'Attesten-leefloon',
        DISABILITY_INCOME_DOCUMENT: 'Attesten-invaliditeit-inkomsten',
        PREGNANCY_DOCUMENT: 'Zwangerschaps-attesten',
        CHILD_SUPPORT_DOCUMENT: 'Documenten-groeipakket',
    },
};
