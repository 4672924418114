import React, { FC, useMemo } from 'react';
import { ControlledInput, FormGrid } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface Props {
    name?: string;
    disabled?: boolean;
}

export const ResidentAddress: FC<Props> = ({ name, disabled }) => {
    const { t } = useTranslation();

    const prefix = useMemo(() => (name ? `${name}.` : ''), [name]);

    return (
        <>
            <Typography variant="subtitle2" mt={1}>
                {t('address')}
            </Typography>
            <FormGrid xs={12} md={6} lg>
                <ControlledInput
                    name={`${prefix}currentAddress.street`}
                    label={t('street')}
                    disabled={disabled}
                    required
                />
                <ControlledInput
                    name={`${prefix}currentAddress.number`}
                    label={t('number')}
                    disabled={disabled}
                    required
                />
                <ControlledInput name={`${prefix}currentAddress.box`} label={t('box')} disabled={disabled} />
                <ControlledInput name={`${prefix}currentAddress.zip`} label={t('zip')} disabled={disabled} required />
                <ControlledInput name={`${prefix}currentAddress.city`} label={t('city')} disabled={disabled} required />
                <ControlledInput
                    name={`${prefix}currentAddress.country`}
                    label={t('country')}
                    defaultValue={'België'}
                    disabled
                    required
                />
            </FormGrid>
        </>
    );
};
