import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { expectingChildrenValidator } from './expecting-children.validator';

export function useExtraInfoResidentsValidator(monthlyChildSupportRequired = false) {
    const { t } = useTranslation();

    return useMemo(() => {
        return object({
            monthlyChildSupport: string().test(
                'required',
                t('requiredQuestion'),
                (value) => (value !== null && value !== '') || !monthlyChildSupportRequired,
            ),
            expectingChildren: expectingChildrenValidator(t),
        });
    }, [t, monthlyChildSupportRequired]);
}
