import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, object, string } from 'yup';

export function usePreferencesValidator(hasRegions?: boolean, hasPropertyTypes?: boolean) {
    const { t } = useTranslation();

    return useMemo(() => {
        return object({
            id: string().optional(),
            hasRegionPreference: hasRegions
                ? boolean()
                      .nullable()
                      .test('required', t('requiredQuestion'), (value) => value !== null)
                : boolean().nullable(),
            regions: array(string().required(t('requiredQuestion'))).when('hasRegionPreference', {
                is: true,
                then: () => array(string()).min(1, t('arrayNotEmpty')),
            }),
            elevator: boolean()
                .nullable()
                .test('required', t('requiredQuestion'), (value) => value !== null),
            requiredAmountOfBedrooms: array(string().required()).required().min(1, t('arrayNotEmpty')),
            remarks: string().nullable(),
            maxRentPrice: string().nullable(),
            hasPropertyTypePreference: hasPropertyTypes
                ? boolean()
                      .nullable()
                      .test('required', t('requiredQuestion'), (value) => value !== null)
                : boolean().nullable(),
            propertyTypes: array(string().required(t('requiredQuestion'))).when('hasPropertyTypePreference', {
                is: true,
                then: () => array(string()).min(1, t('arrayNotEmpty')),
            }),
        });
    }, [t, hasRegions, hasPropertyTypes]);
}
