import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, ControlledSwitch, FormGrid, useGetRegions } from '../../../shared';
import { Divider } from '@mui/material';

type Props = {
    disabled?: boolean;
};

export const RegionPreference: FC<Props> = ({ disabled }) => {
    const { t } = useTranslation();

    const { watch } = useFormContext();
    const watchHasRegionPreference = watch('hasRegionPreference');

    const { data: regions } = useGetRegions({ pageSize: 50 });

    if (regions?.data === undefined || regions?.data.length == 0) {
        return <></>;
    }
    return (
        <FormGrid width="100%">
            <ControlledSwitch
                name={'hasRegionPreference'}
                label={t('hasRegionPreference')}
                disabled={disabled}
                required
            />
            {watchHasRegionPreference && (
                <ControlledSelect
                    name="regions"
                    label={t('pickRegions')}
                    options={regions?.data || []}
                    multiple
                    checkBox
                    disabled={disabled}
                    required
                />
            )}
            <Divider />
        </FormGrid>
    );
};
