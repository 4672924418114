export default {
    bondWithTheCity: 'Binding met de stad',
    residentialRelation: 'Band met {{city}} als woonplaats',
    residentialRelationLabel: 'Is minstens een van de kandidaat-huurders gedomicilieerd in {{city}}?',
    residentialRelationDocument: 'Gelieve het attest als bijlage toe te voegen',
    residentialRelationDocumentHelpText:
        'Laad een attest op van woonst met adressenhistoriek. Je kan een attest opvragen ',
    residentialRelationDocumentLink: 'via deze link.',
    isCurrentlyRentingFromCity: 'Huurt u vandaag een budgethuurwoning van {{city}}?',
    isCurrentlyRentingFromCity_leuven: 'Huurt u vandaag een budgethuurwoning van het AG Stadsontwikkeling Leuven?',
    workStudyRelation: 'Band met {{city}} als plaats waar je werkt of naar school gaat',
    workStudyRelationLabel: 'Werkt minstens een van de kandidaat-huurders in {{city}} of ga je er naar school?',
    workStudyRelationDocument: 'Gelieve een bewijs als bijlage toe te voegen',

    ResidentialRelation: {
        IN_THE_LAST_3_YEARS_FOR_AT_LEAST_3_YEARS: 'Ja, in de afgelopen 3 jaar, minstens 3 jaar lang',
        IN_THE_LAST_10_YEARS_AT_FOR_LEAST_3_YEARS: 'Ja, in de afgelopen 10 jaar, minstens 3 jaar lang',
        AT_SOMETIME_AT_LEAST_FOR_3_YEARS: 'Ja, ooit, minstens 3 jaar lang',
        NEVER_OR_LESS_THAN_3_YEARS: 'Nee, ik woonde nooit of minder dan 3 jaar in Leuven',
    },

    WorkStudyRelation: {
        IN_THE_LAST_3_YEARS: 'Ja, in de afgelopen 3 jaar',
        IN_THE_LAST_5_YEARS_FOR_AT_LEAST_3_YEARS: 'Ja, in de afgelopen 5 jaar, minstens 3 jaar lang',
        IN_THE_LAST_10_YEARS_FOR_AT_LEAST_3_YEARS: 'Ja, in de afgelopen 10 jaar, minstens 3 jaar lang',
        ONLY_FOR_A_SHORT_PERIOD_OF_TIME: 'Ja, maar voor kortere periode',
        NEVER_OR_LESS_THAN_3_YEARS: 'Nee, ik heb nooit of minder dan 3 jaar in Leuven gewerkt of opleiding gevolgd',
    },
};
