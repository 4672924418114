export default {
    helpIcon: {
        //primary resident page
        aResidentOwnsProperty: {
            text:
                'Je kan je niet inschrijven als je een woning of bouwgrond volledig of voor een deel:' +
                '\n- als eigendom hebt of mag gebruiken (vruchtgebruik, erfpacht, opstal)\n- aan anderen gaf om te gebruiken (vruchtgebruik, erfpacht, opstal)\nJe mag ook geen eigendom in het buitenland hebben' +
                '\n\nBracht je een woning of bouwgrond in een vennootschap als zaakvoerder, bestuurder of aandeelhouder in? Dan kan je je ook niet inschrijven.' +
                'Je kan je wel inschrijven:' +
                '\n- als de woning niet te bewonen of geschikt is (onbewoonbaar of ongeschikt verklaard is). ' +
                '\n- als je woning niet aangepast is aan je fysieke beperking.' +
                '\n- als je recht heb op een aangepaste woning voor mensen met een beperking (woning voor Algemene Dagelijkse Levensverrichtingen).' +
                '\n- als je woont in een ruimtelijke bestemmingszone waar wonen niet mag (zoals op een camping).' +
                '\n- als je een woning verliest door een schuldenregeling of faillissement.' +
                '\n- als je een eigendom hebt samen met je (ex-)partner die niet meer met je zal samenwonen.' +
                '\n- als je gedeeltelijk eigenaar werd van een woning of bouwgrond door een erfenis of schenking.',
        },

        //co-resident page
        monthlyChildSupport: {
            text:
                'Heb je een overzicht nodig van recente uitbetalingen?\n' +
                'Vraag dan een attest van de uitbetalingen via de website van de uitbetaler van je Groeipakket (<0>{{link}}</0>, MyFamily, MyPatrenia …). \n' +
                'Weet je niet wie je Groeipakket uitbetaalt? Kijk dan naar je laatste storting.',
            link: 'MyFons',
        },

        //financial page
        hasIndividualTaxableIncome: {
            text: 'Indien u afzonderlijk belastbaar inkomen geniet, ziet u dit apart staan op uw aanslagbiljet. Dit betreft niet het inkomen van u en uw partner afzonderlijk.',
        },
        monthlyTaxableIncome: {
            text: 'We vragen hier naar het bedrag van een gewone maand, niet de maand waarop u vakantiegeld of andere premies hebt ontvangen.',
        },
        hasForeignIncome: {
            text: 'Het is mogelijk dat er in het land waar je gaat werken regels bestaan rond het belastingvrij maken van beperkte inkomstenbedragen. Meer informatie daarover vraag je aan je werkgever. Voor België is het zo dat elke belastingplichtige recht heeft op een belastingvrij gedeelte van zijn of haar inkomsten. Dat recht is van toepassing op het geheel van je inkomsten en dus ook op deze die je in het buitenland vergaarde.',
        },
        hasForeignIncome_year: {
            text: 'Het is mogelijk dat er in het land waar je gaat werken regels bestaan rond het belastingvrij maken van beperkte inkomstenbedragen. Meer informatie daarover vraag je aan je werkgever. Voor België is het zo dat elke belastingplichtige recht heeft op een belastingvrij gedeelte van zijn of haar inkomsten. Dat recht is van toepassing op het geheel van je inkomsten en dus ook op deze die je in het buitenland vergaarde.',
        },
        hasForeignIncome_month: {
            text: 'Het is mogelijk dat er in het land waar je gaat werken regels bestaan rond het belastingvrij maken van beperkte inkomstenbedragen. Meer informatie daarover vraag je aan je werkgever. Voor België is het zo dat elke belastingplichtige recht heeft op een belastingvrij gedeelte van zijn of haar inkomsten. Dat recht is van toepassing op het geheel van je inkomsten en dus ook op deze die je in het buitenland vergaarde.',
        },
        requiredAmountOfBedrooms: {
            text: 'Hou er rekening mee dat het aantal slaapkamers ook invloed heeft op de huurprijs.',
        },

        'undefined-WORK_STUDY_RELATION_DOCUMENT-undefined': {
            text: 'Voorbeelden van documenten:\n- Attesten of verklaringen van werkgevers, organisaties, instellingen en/of onderwijsinstanties\n- Arbeidsovereenkomst\n- Bewijs statuut zelfstandige\n- Inschrijvingsbewijs school of opleiding\n- Attest structureel vrijwilligerswerk',
        },
    },
};
