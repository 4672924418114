import React, { FC } from 'react';
import { Typography } from '@mui/material';
import {
    ControlledNumberInput,
    FormGrid,
    IRentalRequest,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { Assets } from '../assets/assets.component';
import { getErrorForRentalRequestProperty } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Props {
    readOnly: boolean;
    rentalRequest?: IRentalRequest;
    validation?: IRentalRequestValidation;
}

export const ChildSupport: FC<Props> = ({ readOnly, rentalRequest, validation }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="subtitle2">{t('childSupport')}</Typography>
            <FormGrid width="100%">
                <ControlledNumberInput
                    name={'monthlyChildSupport'}
                    label={t('monthlyChildSupport')}
                    required
                    disabled={readOnly}
                    money
                />
                <Assets
                    type={RentalRequestAssetType.CHILD_SUPPORT_DOCUMENT}
                    assets={rentalRequest?.rentalRequestAssets}
                    error={getErrorForRentalRequestProperty('CHILD_SUPPORT_DOCUMENT', validation)}
                    disabled={readOnly}
                />
            </FormGrid>
        </>
    );
};
