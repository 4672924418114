import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { IRentalRequest } from '../../../../shared';
import { EditRentalRequest } from '../../edit-rental-request/edit-rental-request.component';

interface Props {
    rentalRequest: IRentalRequest | undefined;
    statusSubmittedOrChangesRequested: boolean;
}

export const ReadOnlyInfoMobile: FC<Props> = ({ rentalRequest, statusSubmittedOrChangesRequested }) => {
    const { t } = useTranslation();

    return (
        <>
            {rentalRequest && (
                <Alert severity="info" sx={{ mb: 3, '.MuiAlert-message': { width: '100%' } }}>
                    <Accordion
                        sx={{
                            backgroundColor: 'inherit',
                            color: 'inherit',
                            border: 'none',
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                pl: 0,
                                '&, &.Mui-expanded': { minHeight: 0 },
                                '.MuiAccordionSummary-expandIconWrapper': { color: 'inherit' },
                                '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded, .MuiAlertTitle-root':
                                    {
                                        m: 0,
                                    },
                            }}
                            expandIcon={<ExpandMore />}
                        >
                            <AlertTitle>
                                <Typography variant="body2" fontWeight="bold">
                                    {`${t('status')}: ${t(`${rentalRequest.status}.summary`)}`}
                                </Typography>
                            </AlertTitle>
                        </AccordionSummary>

                        <AccordionDetails sx={{ borderTop: 'none', pb: 0, button: { maxHeight: 40 } }}>
                            <Stack spacing={1}>
                                <Typography variant="body2">{t(`${rentalRequest.status}.description`)}</Typography>
                                {statusSubmittedOrChangesRequested && <EditRentalRequest />}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Alert>
            )}
        </>
    );
};
