import { Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NotEligibleReason } from '../../../../shared';
import { useRentalRequest } from '../../../contexts';

export const ContactFaq: FC = () => {
    const { t } = useTranslation();
    const { rentalRequest: contextRentalRequest } = useRentalRequest();

    const faqLink = window.carity.environment.faqLink;
    const incomeToHighOrToLow =
        contextRentalRequest?.notEligibleReason === NotEligibleReason.REFERENCE_INCOME_TOO_HIGH ||
        contextRentalRequest?.notEligibleReason === NotEligibleReason.REFERENCE_INCOME_TOO_LOW;

    return (
        <>
            {incomeToHighOrToLow && (
                <Typography
                    variant="body2"
                    sx={{
                        a: {
                            color: 'inherit',
                            textDecorationColor: 'inherit',
                            fontWeight: 500,
                        },
                    }}
                >
                    <Trans
                        i18nKey="contactFaq"
                        components={[<Link href={faqLink ? faqLink : '/'} target="__blank" />]}
                        values={{ link: t('contactFaqLink') }}
                    />
                </Typography>
            )}
        </>
    );
};
