import { alpha, Components, createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { ITheme } from '../../shared';
import { createBaseTheme } from './base.theme';
import { getComponents, getPalette, getTypography } from '../utils';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const createAntwerpTheme = (theme: ITheme): Theme => {
    const palette = getPalette(theme.color);
    const antwerpTheme = createTheme({ palette });
    const typography = getTypography(antwerpTheme, typographyOverride());
    const components = getComponents(antwerpTheme, componentOverride(antwerpTheme));

    return deepmerge(
        createBaseTheme(theme),
        createTheme(antwerpTheme, { shape: { borderRadius: 0 }, palette, typography, components }),
    );
};

function typographyOverride(): TypographyOptions {
    return {
        h2: { fontFamily: '"Antwerpen Regular", sans-serif' },
        subtitle1: { fontFamily: '"Antwerpen Regular", sans-serif' },
        subtitle2: { fontFamily: '"Antwerpen Regular", sans-serif' },
    };
}

function componentOverride({ palette }: Theme): Components {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    height: 48,
                    [':focus']: {
                        outlineWidth: '2px',
                        outlineStyle: 'solid',
                        outlineColor: palette.primary.main,
                        outlineOffset: '1px',
                    },
                },
            },
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    top: 0,
                    '.carity-label': {
                        marginBottom: 6,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::after': { display: 'none' },
                    '&::before': { display: 'none' },
                },
                input: {
                    borderStyle: 'solid',
                    borderColor: '#6F6F6F',
                    borderWidth: 1,
                    padding: '0 1.5rem',
                    boxSizing: 'border-box',
                    '&::placeholder': {
                        opacity: 0.6,
                        color: '#141414',
                    },
                    '&:focus': {
                        borderColor: palette.primary.main,
                        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    transform: 'none',
                    color: '#141414',
                    marginBottom: 6,
                    position: 'relative',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&::after': {
                        display: 'none',
                    },
                    '&::before': {
                        display: 'none',
                    },
                    fieldset: {
                        display: 'none',
                    },
                    borderStyle: 'solid',
                    borderColor: '#6f6f6f',
                    borderWidth: 1,
                    boxSizing: 'border-box',
                    height: 48,
                    '&.Mui-focused': {
                        borderStyle: 'none',
                        borderColor: palette.primary.main,
                        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    height: 48,
                    paddingLeft: 12,
                    paddingTop: 12,
                },
                multiple: {
                    marginBottom: 12,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '& .app-logo': {
                        maxHeight: '55px',
                        marginRight: 12,
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
    };
}
