import { Alert, AlertTitle, Button, Link, Typography } from '@mui/material';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FormGrid,
    ICoResident,
    RentalRequestStatus,
    useGetValidation,
    useMyRentalRequest,
    useUpdateMyRentalRequest,
} from '../../../shared';
import { sortResident, useIsResidentIncomplete } from '../../utils';
import { CoResidentModal } from '../co-resident-modal/co-resident-modal.component';
import { DisplayResidentRow } from '../display-resident-row/display-resident-row.component';

export const FamilySituationForm: FC = () => {
    const { t } = useTranslation();
    const [residentModal, setResidentModal] = useState<{ show: boolean; resident: ICoResident | null }>({
        show: false,
        resident: null,
    });

    const { data: rentalRequest } = useMyRentalRequest();
    const { data: validation } = useGetValidation(rentalRequest?.showValidation || false);
    const readOnly = useMemo(() => rentalRequest?.status !== RentalRequestStatus.DRAFT, [rentalRequest]);
    const { mutateAsync: updateRentalRequest } = useUpdateMyRentalRequest();

    const openModal = useCallback((resident: ICoResident | null) => {
        setResidentModal({ show: true, resident: resident });
    }, []);

    const closeModal = useCallback(() => {
        setResidentModal({ show: false, resident: null });
    }, []);

    const secondaryResidents = useMemo(
        () =>
            rentalRequest?.residents
                .filter((resident) => !resident.isPrimaryResident)
                .map((res) => res as ICoResident)
                .sort(sortResident) || [],
        [rentalRequest],
    );
    const onDeleteResident = async (resident: ICoResident) => {
        await updateRentalRequest({
            rentalRequest: {
                ...rentalRequest,
                preferences: undefined,
                residents: rentalRequest?.residents?.filter((item) => resident.id !== item.id),
            },
        });
    };

    const isResidentIncomplete = useIsResidentIncomplete();

    const incompleteSecondaryResidents = useMemo(
        () => secondaryResidents.filter((resident) => isResidentIncomplete(resident)),
        [isResidentIncomplete, secondaryResidents],
    );
    return (
        <>
            {rentalRequest && residentModal.show && (
                <CoResidentModal
                    rentalRequest={rentalRequest}
                    resident={residentModal.resident}
                    show={residentModal.show}
                    handleClose={closeModal}
                    validation={validation}
                />
            )}
            <Typography variant="subtitle1">{t('residents')}</Typography>
            <Typography mb={2}>{t('residentsHelpText')}</Typography>
            <Typography variant="subtitle2">{t('familyComposition')}</Typography>

            {incompleteSecondaryResidents.length !== 0 && (
                <Alert severity="warning">
                    <AlertTitle>{t('residentsIncompleteWarning')}</AlertTitle>
                    {incompleteSecondaryResidents.map((resident) => (
                        <Fragment key={resident.id}>
                            {resident.firstName} {resident.lastName}
                            <br />
                        </Fragment>
                    ))}
                </Alert>
            )}

            {secondaryResidents.length === 0 && (
                <Alert severity="info" sx={{ height: 'fit-content', mb: 2 }}>
                    <AlertTitle>{t('addResidents')}</AlertTitle>
                    <Typography mb={1}>{t('addCoResidentsHelpText')}</Typography>
                    <Button
                        variant="contained"
                        sx={{ width: 'fit-content' }}
                        onClick={() => openModal(null)}
                        disabled={readOnly}
                    >
                        {t('addResident')}
                    </Button>
                </Alert>
            )}

            {secondaryResidents.length > 0 && (
                <FormGrid width="100%">
                    {secondaryResidents.map((field, index) => (
                        <Fragment key={`${field.id}-${index}`}>
                            <DisplayResidentRow
                                resident={field}
                                openEdit={(resident) => openModal(resident)}
                                onDelete={(resident) => onDeleteResident(resident)}
                                error={validation?.errors?.find((error) => error.residentId === field.id)}
                                disabled={readOnly}
                            />
                        </Fragment>
                    ))}
                </FormGrid>
            )}

            {secondaryResidents.length > 0 && !readOnly && (
                <Link variant="button" href="#" mb={2} display="block" onClick={() => openModal(null)}>
                    + {t('addResident')}
                </Link>
            )}
        </>
    );
};
