export default {
    fallbackError: 'Er ging iets mis',
    somethingWentWrong: 'Er liep iets mis:',
    validationErrorText: 'Enkele gegevens zijn foutief',
    missingData: 'Enkele gegevens ontbreken',
    rentalRequestError: 'Algemeen',
    errorSetIncomplete: 'Je kan je dossier momenteel niet wijzigen, herlaad je pagina om je huidige status te zien',

    errors: {
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        unknown_name: 'Onbekend',
        dateOfBirth: 'Geboortedatum',
        socialSecurityNumber: 'Rijksregisternummer',
        contactType: 'Contact type',
        phone: 'Telefoonnummer',

        street: 'Straat',
        number: 'Nummer',
        city: 'Stad',
        zip: 'Postcode',
        country: 'Land',

        hasResidentialHistory: 'Antwoord vraag woonhistoriek',
        aResidentOwnsProperty: 'Antwoord vraag heeft woning',
        RESIDENTIAL_HISTORY_DOCUMENT: 'Attest domicilie',

        expectedAmount: 'Aantal verwachte kinderen',
        expectedDate: 'Uitgerekende datum',
        PREGNANCY_DOCUMENT: 'Zwangerschapsattest',

        hasChildSupport: 'Kinderbijslag of groeipakket',
        CHILD_SUPPORT_DOCUMENT: 'Attest van kinderbijslag of groeipakket',

        financialData: 'Financieel',
        financialData_antwerp: 'Inkomen',
        taxYear: 'Aanslagjaar',
        hasTaxBillForFullYear: 'Antwoord op van welk jaar is je laatste aanslagbiljet',
        jointTaxableIncome: 'Gezamelijk belastbaar inkomen',
        individualTaxableIncome: 'Afzonderlijk belastbaar inkomen',
        hasIndividualTaxableIncome: 'Antwoord vraag afzonderlijk belastbaar inkomen',
        TAX_BILL_DOCUMENT: 'Aanslagbiljet',

        monthlyTaxableIncome: 'Maandelijks belastbaar inkomen',
        hasMonthlyTaxableIncome: 'Maandelijks belastbaar inkomen',
        MONTHLY_INCOME_DOCUMENT: 'Loonfiche',

        disabilityIncome: 'Inkomensvervangende tegemoetkoming',
        hasDisabilityIncome: 'Inkomensvervangende tegemoetkoming',
        hasDisabilityDocument: 'Antwoord vraag heeft attest invaliditeit',
        DISABILITY_INCOME_DOCUMENT: 'Attest inkomensvervangende tegemoetkoming voor personen met een handicap',

        livingWage: 'Leefloon',
        hasLivingWage: 'Leefloon',
        LIVING_WAGE_DOCUMENT: 'Attest leefloon',

        foreignIncome: 'Buitenlandse inkomsten',
        hasForeignIncome: 'Buitenlandse inkomsten',

        budgetRenting: 'Budgethuren',
        hasBudgetRentingDocument: 'Budgethuren',
        expirationDateBudgetRentingDocument: 'Vervaldatum Vlaams attest budgethuren',
        BUDGET_RENTING_DOCUMENT: 'Vlaams attest voor budgethuren',

        hasRentSubsidy: 'Antwoord vraag huurpremie',
        rentSubsidy: 'Antwoord vraag huurpremie',
        useRentSubsidyForMaxRent: 'Antwoord vraag huurpremie mee opnemen in de berekening van de te besteden huurprijs',
        RENT_SUBSIDY_DOCUMENT: 'Attest huursubsidie of huurpremie',

        RESIDENTIAL_RELATION_DOCUMENT: 'Attest van woonst met adressenhistoriek',
        WORK_STUDY_RELATION_DOCUMENT: 'Bewijs van werk of school',
        residentialRelation: 'Band met stad als woonplaats',
        workStudyRelation: 'Band met stad als werkplaats of school',

        requiredAmountOfBedrooms: 'Keuze aantal slaapkamers',
        agreeToChangeResidence: 'Akkoord aanpassing domiciliëring',
    },

    asset: {
        EMPTY: '{{asset}} ontbreekt nog.',
    },

    formError: {
        DUPLICATE_USE: 'Dubbel gevonden in ander dossier',
        DUPLICATE_USE_IN_RENTAL_REQUEST: 'Dubbel in jouw dossier',
    },

    uploadError: {
        GENERIC_ERROR: 'Er ging iets mis bij het uploaden van het bestand.',
        FILE_TOO_BIG: 'Het bestand mag niet groter zijn dan 4MB. Probeer opnieuw met een kleiner bestand.',
        WRONG_FILE_TYPE:
            'Het bestand heeft een type dat niet toegestaan is. De toegelaten types zijn PDF, JPEG en PNG.',
        FILE_TOO_BIG_AND_WRONG_FILE_TYPE:
            'Het bestand mag niet groter zijn dan 4MB en is niet van een toegelaten type. Probeer opnieuw met een kleiner bestand. De toegelaten types zijn PDF, JPEG en PNG.',
    },

    DUPLICATE_USE: 'Het {{property}} van deze persoon wordt al gebruikt in een ander dossier, zie',
    DUPLICATE_USE_IN_RENTAL_REQUEST: 'Het {{property}} van deze persoon wordt al gebruikt in dit dossier',
    EMPTY: '{{property}} ontbreekt nog in ',
    DOCUMENT_NOT_EXPECTED: 'Geen document verwacht.',
    TOO_LOW: '{{property}} is te laag in ',
    TOO_HIGH: '{{property}} is te hoog in ',
    INVALID_FORMAT: '{{property}} heeft geen geldig formaat in ',
    INVALID_LENGTH: '{{property}} heeft een ongeldige lengte in ',
    NOT_AN_ENUM_VALUE: '{{property}} is ongeldig in ',
    INVALID_DATE_FORMAT: '{{property}} is geen geldige datum in ',
    INVALID_STRING: '{{property}} heeft geen geldig formaat in  ',

    PRIMARY_RESIDENT_TOO_YOUNG: 'Hoofdaanvrager is onder de 18 jaar.',
    RESIDENT_HAS_PROPERTY: 'Eén van de bewoners heeft een eigendom.',
    REFERENCE_INCOME_TOO_HIGH: 'Het referentie inkomen is te hoog.',
    REFERENCE_INCOME_TOO_LOW: 'Het referentie inkomen is te laag.',
    RESIDENT_NOT_IN_REGISTER: 'Eén van de medebewoners zit niet in het bevolkingsregister.',
    TAX_YEAR_OR_INCOME_EMPTY: 'Zowel het referentie inkomen als het aanslagjaar moeten ingevuld zijn.',
    INVALID_BUDGET_RENTING_DOCUMENT: 'Een van de bewoners heeft geen geldig Vlaams attest voor budgethuren.',
};
