import { Check, Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { FC } from 'react';

interface Props {
    status: 'completed' | 'active' | 'todo' | 'error';
    step: number;
}

export const StepStatus: FC<Props> = ({ step, status }) => {
    return (
        <Box
            sx={{
                width: 25,
                height: 25,
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'solid 2px',
                borderColor: ({ palette }) => (status === 'error' ? palette.error.main : palette.primary.main),
                backgroundColor: ({ palette }) =>
                    status === 'error'
                        ? palette.error.main
                        : status === 'active'
                          ? palette.primary.main
                          : status === 'completed'
                            ? palette.primary.shade
                            : 'transparent',
                color: ({ palette }) =>
                    status === 'active' || status === 'error' ? palette.common.white : palette.primary.main,
                fontWeight: 'bold',
                fontSize: '0.8rem',
            }}
        >
            {status === 'error' ? (
                <Close sx={{ fontSize: '0.8rem' }} />
            ) : status === 'completed' ? (
                <Check sx={{ fontSize: '0.8rem' }} />
            ) : (
                step
            )}
        </Box>
    );
};
