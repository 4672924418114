import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest } from '../../../../shared';
import { EditRentalRequest } from '../../edit-rental-request/edit-rental-request.component';

interface Props {
    rentalRequest: IRentalRequest | undefined;
    statusSubmittedOrChangesRequested: boolean;
}

export const ReadOnlyInfo: FC<Props> = ({ rentalRequest, statusSubmittedOrChangesRequested }) => {
    const { t } = useTranslation();
    return (
        <>
            {rentalRequest && (
                <Alert severity="info">
                    <Stack spacing={1}>
                        <AlertTitle>
                            <Typography variant="body2" fontWeight="bold">
                                {`${t('status')}: ${t(`${rentalRequest.status}.summary`)}`}
                            </Typography>
                        </AlertTitle>
                        <Typography variant="body2">{t(`${rentalRequest.status}.description`)}</Typography>
                        {statusSubmittedOrChangesRequested && <EditRentalRequest />}
                    </Stack>
                </Alert>
            )}
        </>
    );
};
