import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { IRentalRequest } from '../../shared';

interface IRentalRequestContext {
    rentalRequest?: IRentalRequest;
    setRentalRequest: (rentalRequest: IRentalRequest) => void;
}

type Props = {
    children?: ReactNode;
};

const RentalRequestContext = createContext<IRentalRequestContext>({
    rentalRequest: undefined,
    setRentalRequest: () => {},
});

export const RentalRequestContextProvider: FC<Props> = ({ children }) => {
    const [rentalRequest, setRentalRequest] = useState<IRentalRequest | undefined>();

    return (
        <RentalRequestContext.Provider value={{ rentalRequest, setRentalRequest }}>
            {children}
        </RentalRequestContext.Provider>
    );
};

export const useRentalRequest = (): IRentalRequestContext => useContext(RentalRequestContext);
