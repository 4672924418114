import { CloudDownloadOutlined, CloudUploadTwoTone, DeleteOutline } from '@mui/icons-material';
import { Alert, Box, IconButton, Paper, Stack, styled, Typography } from '@mui/material';
import { transparentize } from 'polished';
import React, {
    ChangeEvent,
    DragEventHandler,
    FC,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    FileUploadStatus,
    IAsset,
    IError,
    Loading,
    RemoveModal,
    RentalRequestAssetType,
    useDeleteAsset,
    useUploadAsset,
} from '../../../shared';

interface Props {
    type: RentalRequestAssetType;
    residentId?: string;
    questionId?: string;
    asset?: IAsset;
    error?: IError;
    disabled?: boolean;
}

const FilePickerLabel = styled('label')(({ theme: { palette, spacing } }) => ({
    borderStyle: 'dashed',
    borderColor: palette.divider,
    borderWidth: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: spacing(4),
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        borderColor: palette.primary.main,
        backgroundColor: transparentize(0.9, palette.primary.main),
    },
}));

const AccessibilitySpan = styled('span')(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    outlineOffset: '2px',
}));

const MAX_FILE_SIZE = 4 * 1024 * 1024; // 4MB
const ALLOWED_FILE_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];

export const Asset: FC<Props> = ({ type, residentId, questionId, asset, error, disabled }) => {
    const { t } = useTranslation();
    const [uploadError, setUploadError] = useState<FileUploadStatus>(FileUploadStatus.OK);

    const { mutateAsync: uploadAsset, isPending: uploading } = useUploadAsset();
    const { mutateAsync: deleteAsset } = useDeleteAsset();

    const uniqueFileUploadLabelId = `file-upload-label-${type}-${residentId}-${questionId}`;
    const uniqueInputElementId = `hidden-input-${type}-${residentId}-${questionId}`;

    const onUploadAsset = useCallback(
        async (file?: File) => {
            if (file) {
                if (file.size > MAX_FILE_SIZE && !ALLOWED_FILE_TYPES.includes(file.type)) {
                    console.error(
                        `File size bigger than 4MB ==> ${file.size} and file type is not allowed ==> ${file.type}`,
                    );
                    return setUploadError(FileUploadStatus.FILE_TOO_BIG_AND_WRONG_FILE_TYPE);
                }

                if (file.size > MAX_FILE_SIZE) {
                    console.error(`File size bigger than 4MB ==> ${file.size}`);
                    return setUploadError(FileUploadStatus.FILE_TOO_BIG);
                }

                if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                    console.error(`File type is not allowed ==> ${file.type}`);
                    return setUploadError(FileUploadStatus.WRONG_FILE_TYPE);
                }

                try {
                    await uploadAsset({ query: { type, residentId, questionId }, file });
                    setUploadError(FileUploadStatus.OK);
                } catch (err) {
                    setUploadError(FileUploadStatus.GENERIC_ERROR);
                }
            }
        },
        [uploadAsset, type, residentId, questionId],
    );

    const onDeleteAsset = useCallback(async () => {
        if (asset?.id) {
            await deleteAsset(asset?.id);
        }
    }, [asset, deleteAsset]);

    // Disable dragover event from the window to prevent loading the image in the browser when dropping
    useEffect(() => {
        const preventDefaultFunction = (event: DragEvent) => {
            event.preventDefault();
        };

        window.addEventListener('dragover', preventDefaultFunction);

        return () => window.removeEventListener('dragover', preventDefaultFunction);
    }, []);

    const handleDrop: DragEventHandler = useCallback(
        async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                await onUploadAsset(e.dataTransfer.files[0]);
            }
        },
        [onUploadAsset],
    );

    // Function used to make the file-upload accessible via keyboard events
    const onEnter: KeyboardEventHandler = useCallback(
        (event) => {
            if (event.code === 'Enter') {
                const el = document.getElementById(uniqueFileUploadLabelId);
                el?.click();
            }
        },
        [uniqueFileUploadLabelId],
    );

    return (
        <>
            {uploading ? (
                <Loading />
            ) : (
                <>
                    {uploadError !== FileUploadStatus.OK && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {t(`uploadError.${uploadError}`)}
                        </Alert>
                    )}

                    {error && (
                        <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
                            {t(`asset.${error.message}`, { asset: t(`errors.${error.property}`) })}
                        </Alert>
                    )}

                    {asset ? (
                        <Paper variant="outlined" sx={{ borderStyle: 'dashed', p: 2, height: '100%' }}>
                            <Stack direction="row" alignItems="center" height="100%" spacing={1}>
                                <Box sx={{ flex: 1 }}>{asset.fileName}</Box>
                                <IconButton
                                    href={`/renting-api/my-rental-request${asset.downloadUrl}`}
                                    target="__blank"
                                >
                                    <CloudDownloadOutlined />
                                </IconButton>
                                <RemoveModal
                                    handleDelete={onDeleteAsset}
                                    button={
                                        <IconButton aria-label="delete" disabled={disabled}>
                                            <DeleteOutline />
                                        </IconButton>
                                    }
                                    title={t('deleteAttachmentTitle')}
                                    text={t('deleteAttachmentDescription')}
                                />
                            </Stack>
                        </Paper>
                    ) : (
                        <>
                            <FilePickerLabel
                                htmlFor={uniqueInputElementId}
                                onDrop={handleDrop}
                                id={uniqueFileUploadLabelId}
                            >
                                <AccessibilitySpan
                                    aria-controls={uniqueFileUploadLabelId}
                                    tabIndex={0}
                                    onKeyDown={onEnter}
                                />

                                <CloudUploadTwoTone color="primary" fontSize="large" sx={{ mb: 1 }} />
                                <Typography>{t('uploadFile')}</Typography>
                            </FilePickerLabel>
                            <input
                                id={uniqueInputElementId}
                                type="file"
                                hidden
                                accept={ALLOWED_FILE_TYPES.join(',')}
                                disabled={uploading}
                                multiple={false}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onUploadAsset(e.target.files?.[0])}
                            />
                            <Typography variant="caption" sx={{ mt: '8px !important' }}>
                                {t('maxFileSize')} 4MB
                            </Typography>
                        </>
                    )}
                </>
            )}
        </>
    );
};
