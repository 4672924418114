import { RentalRequestErrorProperties, ResidentErrorProperties } from '../../shared';

export const propertyToStepMap: Record<
    'primary' | 'nonPrimary',
    Record<keyof RentalRequestErrorProperties | keyof ResidentErrorProperties, string>
> = {
    nonPrimary: {
        RESIDENTIAL_HISTORY_DOCUMENT: '1',
        socialSecurityNumber: '2',
        livingWage: '3',
        LIVING_WAGE_DOCUMENT: '3',
        disabilityIncome: '3',
        DISABILITY_INCOME_DOCUMENT: '3',
        foreignIncome: '3',
        email: '1',
        phone: '1',
        street: '1',
        number: '1',
        country: '1',
        zip: '1',
        city: '1',
        financialData: '3',
        firstName: '1',
        lastName: '1',
        taxYear: '3',
        dateOfBirth: '2',
        hasChildSupport: '2',
        CHILD_SUPPORT_DOCUMENT: '2',
        PREGNANCY_DOCUMENT: '2',
        expectedAmount: '2',
        expectedDate: '2',
        TAX_BILL_DOCUMENT: '3',
        MONTHLY_INCOME_DOCUMENT: '3',
        jointTaxableIncome: '3',
        hasIndividualTaxableIncome: '3',
        individualTaxableIncome: '3',
        monthlyTaxableIncome: '3',
        hasBudgetRentingDocument: '3',
        expirationDateBudgetRentingDocument: '3',
        BUDGET_RENTING_DOCUMENT: '3',
        hasRentSubsidy: '3',
        rentSubsidy: '3',
        useRentSubsidyForMaxRent: '3',
        RENT_SUBSIDY_DOCUMENT: '3',
        RESIDENTIAL_RELATION_DOCUMENT: '4',
        WORK_STUDY_RELATION_DOCUMENT: '4',
        residentialRelation: '4',
        workStudyRelation: '4',
        requiredAmountOfBedrooms: '5',
        agreeToChangeResidence: '5',
    },
    primary: {
        RESIDENTIAL_HISTORY_DOCUMENT: '1',
        socialSecurityNumber: '1',
        livingWage: '3',
        LIVING_WAGE_DOCUMENT: '3',
        disabilityIncome: '3',
        DISABILITY_INCOME_DOCUMENT: '3',
        foreignIncome: '3',
        email: '1',
        phone: '1',
        street: '1',
        number: '1',
        country: '1',
        zip: '1',
        city: '1',
        financialData: '3',
        firstName: '1',
        lastName: '1',
        taxYear: '3',
        dateOfBirth: '1',
        hasChildSupport: '2',
        CHILD_SUPPORT_DOCUMENT: '2',
        PREGNANCY_DOCUMENT: '2',
        expectedAmount: '2',
        expectedDate: '2',
        TAX_BILL_DOCUMENT: '3',
        MONTHLY_INCOME_DOCUMENT: '3',
        jointTaxableIncome: '3',
        hasIndividualTaxableIncome: '3',
        individualTaxableIncome: '3',
        monthlyTaxableIncome: '3',
        hasBudgetRentingDocument: '3',
        expirationDateBudgetRentingDocument: '3',
        BUDGET_RENTING_DOCUMENT: '3',
        hasRentSubsidy: '3',
        rentSubsidy: '3',
        useRentSubsidyForMaxRent: '3',
        RENT_SUBSIDY_DOCUMENT: '3',
        RESIDENTIAL_RELATION_DOCUMENT: '4',
        WORK_STUDY_RELATION_DOCUMENT: '4',
        residentialRelation: '4',
        workStudyRelation: '4',
        requiredAmountOfBedrooms: '5',
        agreeToChangeResidence: '5',
    },
};
