import { ComponentProps } from 'react';
import { GlobalStyles, Palette } from '@mui/material';

export function getGlobalStyles(palette: Palette): ComponentProps<typeof GlobalStyles>['styles'] {
    const name = window.carity.environment.theme?.name;

    const defaultStyle = {
        '.MuiDataGrid-root': { background: palette.background.paper },
        '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': { display: 'none' },
    };

    switch (name) {
        case 'antwerp':
            return defaultStyle;
        case 'leuven':
            return {
                ...defaultStyle,
                'header::before': {
                    backgroundImage: 'url(/leuven-header-line-mobile.svg)',
                    backgroundRepeat: 'repeat-x',
                    content: '""',
                    display: 'block',
                    height: '0.5rem',
                    '@media screen and (min-width: 768px)': {
                        backgroundImage: 'url(/leuven-header-line.svg)',
                        backgroundRepeat: 'no-repeat',
                        height: '0.8rem',
                    },
                    '@media screen and (min-width: 1300px)': {
                        backgroundImage: 'url(/leuven-header-line-side.svg)',
                        backgroundColor: palette.background.paper,
                        height: '100vh',
                        width: '1rem',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                    },
                },
            };

        default:
            return defaultStyle;
    }
}
