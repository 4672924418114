import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledInput, ControlledPhoneNumberInput, FormGrid } from '../../../shared';
import { Box, Typography } from '@mui/material';

type Props = {
    disabled?: boolean;
};

export const ResidentContactInfo: FC<Props> = ({ disabled }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="subtitle2" mt={1}>
                {t('contactInfo')}
            </Typography>
            <FormGrid xs={12} md={6} lg={4}>
                <ControlledInput name="email" label={t('email')} disabled={disabled} required />
                <ControlledPhoneNumberInput name="phone" label={t('phone')} disabled={disabled} required />
            </FormGrid>
        </Box>
    );
};
