import { Box, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const AppTitle: FC = () => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;
    const navigate = useNavigate();

    return (
        <Typography
            variant="h1"
            component={Link}
            onClick={() => navigate('')}
            underline="none"
            sx={{ cursor: 'pointer' }}
        >
            <Box className="app-logo">
                <img src={theme.logo} alt={t('nav.title', { context: theme.name })} />
            </Box>
            {t('nav.title', { context: theme.name })}
        </Typography>
    );
};
