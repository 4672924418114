import { FormControlLabel, Radio, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactType, ControlledRadioGroup } from '../../../shared';

type Props = {
    disabled?: boolean;
};

export const ResidentContactPreference: FC<Props> = ({ disabled }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="subtitle2">{t('contactPreference')}</Typography>
            <ControlledRadioGroup name="contactType" label={t('communicationPreference')} disabled={disabled} required>
                <FormControlLabel control={<Radio />} label={t(ContactType.EMAIL)} value={ContactType.EMAIL} />
                <FormControlLabel control={<Radio />} label={t(ContactType.LETTER)} value={ContactType.LETTER} />
            </ControlledRadioGroup>
        </>
    );
};
