import { FormControl, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { ComponentProps, FC } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { DateFormat } from '../../enums';
import { CarityInputLabel } from '../input-label/input-label.component';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    label: string;
};

export const ControlledDatePicker: FC<Props> = ({ name, label, ...textFieldProps }) => {
    const theme = window.carity.environment.theme;

    return (
        <Controller
            name={name}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl>
                    <CarityInputLabel
                        name={name}
                        label={label}
                        disabled={textFieldProps.disabled}
                        required={textFieldProps.required}
                        inlineLabel={theme.name !== 'antwerp'}
                    />
                    <DatePicker
                        slotProps={{
                            textField: {
                                id: `${name}-field`,
                                ...textFieldProps,
                                helperText: (error as FieldError | undefined)?.message,
                                error: !!error,
                            },
                            inputAdornment: { position: 'start' },
                        }}
                        value={value ? new Date(value) : null}
                        onChange={onChange}
                        format={DateFormat.DD_MM_YYYY}
                        disabled={textFieldProps.disabled}
                        views={['year', 'month', 'day']}
                    />
                </FormControl>
            )}
        />
    );
};
