import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CarityTheme, Main, Statusbar } from './main';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const App: FC = () => {
    return (
        <BrowserRouter>
            <CarityTheme>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <QueryClientProvider client={queryClient}>
                        <Main />
                        <Statusbar />
                    </QueryClientProvider>
                </LocalizationProvider>
            </CarityTheme>
        </BrowserRouter>
    );
};
