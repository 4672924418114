import { AlertProps, Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useRentalRequest } from '../../contexts';
import { IsEligibleInfo } from './is-eligible-info/is-eligible-info.component';
import { IsEligibleInfoMobile } from './is-eligible-info/is-eligible-info-mobile.component';
import { EligibleStatus, useBreakpoint } from '../../../shared';

const eligibleStatusToSeverityMap: Record<EligibleStatus, AlertProps['severity']> = {
    [EligibleStatus.UNABLE_TO_CALCULATE]: 'info',
    [EligibleStatus.NOT_ELIGIBLE]: 'error',
    [EligibleStatus.ELIGIBLE]: 'success',
};

const eligibleStatusToTranslation: Record<EligibleStatus, string> = {
    [EligibleStatus.UNABLE_TO_CALCULATE]: 'unableToCalculateInfoText',
    [EligibleStatus.NOT_ELIGIBLE]: 'notEligibleInfoText',
    [EligibleStatus.ELIGIBLE]: 'eligibleInfoText',
};

export const IsEligibleInfoBox: FC = () => {
    const { rentalRequest: contextRentalRequest } = useRentalRequest();

    // Give precedence to the prop, otherwise take the value from the context
    const isEligibleStatus = useMemo((): EligibleStatus => {
        return contextRentalRequest?.eligibleStatus || EligibleStatus.UNABLE_TO_CALCULATE;
    }, [contextRentalRequest]);

    return (
        <Stack sx={{ position: 'sticky', height: 'fit-content' }}>
            {useBreakpoint('md') ? (
                <IsEligibleInfo
                    severity={eligibleStatusToSeverityMap}
                    translation={eligibleStatusToTranslation}
                    status={isEligibleStatus}
                />
            ) : (
                <IsEligibleInfoMobile
                    severity={eligibleStatusToSeverityMap}
                    translation={eligibleStatusToTranslation}
                    status={isEligibleStatus}
                />
            )}
        </Stack>
    );
};
