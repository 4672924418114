import React, { FC } from 'react';
import { Box, Divider, Link, Typography } from '@mui/material';
import {
    ControlledSwitch,
    ICoResident,
    IPrimaryResident,
    IRentalRequest,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { Assets } from '../assets/assets.component';
import { getErrorForRentalRequestProperty } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

interface Props {
    readOnly: boolean;
    update: (x: boolean) => void;
    primaryResident: IPrimaryResident | ICoResident | undefined;
    rentalRequest?: IRentalRequest;
    validation?: IRentalRequestValidation;
}

export const HasResidentialHistory: FC<Props> = ({ readOnly, update, primaryResident, rentalRequest, validation }) => {
    const { t } = useTranslation();

    const form = useFormContext();
    const hasResidentialHistoryWatch = form.watch('hasResidentialHistory');

    return (
        <Box>
            <Typography variant="subtitle2">{t('hasResidentialHistoryTitle')}</Typography>
            <ControlledSwitch
                name="hasResidentialHistory"
                label={t('hasResidentialHistory', {
                    city: window.carity.environment.city,
                })}
                labelHelpText={t('hasResidentialHistoryHelpText')}
                disabled={readOnly}
                onChangeSelected={() => update(true)}
                required
            />

            {!hasResidentialHistoryWatch && <Divider sx={{ mt: 2 }} />}

            {hasResidentialHistoryWatch && (
                <Assets
                    labelHelpText={
                        <Typography variant="caption" display="block">
                            {t('residentialHistoryDocumentHelpText')}
                            <Link
                                href={window.carity.environment.residentialHistoryDocumentLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('residentialHistoryDocumentLink')}
                            </Link>
                        </Typography>
                    }
                    type={RentalRequestAssetType.RESIDENTIAL_HISTORY_DOCUMENT}
                    residentId={primaryResident?.id}
                    assets={rentalRequest?.rentalRequestAssets}
                    error={getErrorForRentalRequestProperty('RESIDENTIAL_HISTORY_DOCUMENT', validation)}
                    disabled={readOnly}
                    required
                />
            )}
        </Box>
    );
};
