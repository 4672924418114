import { Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const FaqLink: FC<{ faqLink: string }> = ({ faqLink }) => {
    const { t } = useTranslation();

    return (
        <Typography>
            {t('faqLinkInfo', { context: window.carity.environment.theme.name })}
            <Link href={faqLink} target="__blank">
                {t('here')}.
            </Link>
        </Typography>
    );
};
