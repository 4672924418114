import { Divider } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, ControlledSwitch, FormGrid, useGetPreferencePropertyTypes } from '../../../shared';

type Props = {
    disabled?: boolean;
};

export const PreferencePropertyType: FC<Props> = ({ disabled }) => {
    const { t } = useTranslation();

    const { watch } = useFormContext();
    const watchHasPropertyTypePreference = watch('hasPropertyTypePreference');

    const { data: preferencePropertyTypes } = useGetPreferencePropertyTypes({});

    return (
        <FormGrid width="100%">
            <ControlledSwitch
                name={'hasPropertyTypePreference'}
                label={t('hasPropertyTypePreference')}
                disabled={disabled}
                required
            />
            {watchHasPropertyTypePreference && (
                <ControlledSelect
                    name="propertyTypes"
                    label={t('pickPropertyTypePreferences')}
                    options={preferencePropertyTypes?.data?.map((type) => ({ ...type, id: type.name })) || []}
                    multiple
                    checkBox
                    disabled={disabled}
                    required
                />
            )}
            <Divider />
        </FormGrid>
    );
};
