import { format } from 'date-fns';
import { isDateValid } from '../../shared';

export function mapDateString(date: string | undefined | null | Date): string | null | undefined {
    if (date instanceof Date && !isNaN(date.getTime())) {
        if (isDateValid(date)) return format(date, 'yyyy-MM-dd');
        return null;
    } else if (date instanceof Date) {
        return undefined;
    }
    return date || null;
}
