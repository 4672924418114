import { IPrimaryResident, IPrimaryResidentForm, IRentalRequest, ISaveRentalRequest } from '../../shared';
import { mapDateString } from './date-string.mapper';

export const primaryResidentToFormMapper = (rentalRequest: IRentalRequest): IPrimaryResidentForm => {
    const primary = rentalRequest.residents.find((resident) => resident.isPrimaryResident);

    return {
        ...rentalRequest,
        primaryResident: {
            ...primary,
            firstName: primary?.firstName || '',
            lastName: primary?.lastName || '',
            socialSecurityNumber: primary?.socialSecurityNumber || '',
            dateOfBirth: primary?.dateOfBirth || '',
            currentAddress: {
                street: primary?.currentAddress?.street || '',
                number: primary?.currentAddress?.number || '',
                zip: primary?.currentAddress?.zip || '',
                city: primary?.currentAddress?.city || '',
                box: primary?.currentAddress?.box || '',
                country: primary?.currentAddress?.country || 'België',
            },
        },
        email: rentalRequest.email || '',
        phone: rentalRequest.phone || '',
    };
};

export const primaryResidentFromFormMapper = (
    originalRentalRequest: IRentalRequest,
    { primaryResident, ...rentalRequest }: IPrimaryResidentForm,
): ISaveRentalRequest => {
    return {
        ...originalRentalRequest,
        ...rentalRequest,
        residents: [
            {
                ...originalRentalRequest.residents.find((resident) => resident.isPrimaryResident),
                ...primaryResident,
                isPrimaryResident: true,
                dateOfBirth: mapDateString(primaryResident.dateOfBirth),
                isCurrentAddressSameAsPrimaryResident: true,
            } as IPrimaryResident,
            ...originalRentalRequest.residents.filter((resident) => !resident.isPrimaryResident),
        ],
        preferences: undefined,
        expectingChildren: undefined,
    };
};
