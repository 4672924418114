import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import nl from './nl';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: 'nl',
    lng: 'nl',
    resources: {
        nl,
    },
    returnNull: false,
});

export default i18n;
