import { Grid } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../shared';
import { FAQ, HomeIntro, ProcessDescription } from '../../components';

export const HomePage: FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.home', { context: window.carity.environment.theme.name });
    }, [t]);

    return (
        <Page>
            <Grid container component="main" spacing={3} mb={3}>
                <Grid item xs={12} lg={7}>
                    <HomeIntro />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <ProcessDescription />
                </Grid>
            </Grid>
            <FAQ />
        </Page>
    );
};
