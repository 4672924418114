import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IFaq, useFaq } from '../../../shared';

export const FaqItems: FC = () => {
    const { t } = useTranslation();
    const { data: faqItems } = useFaq({ page: 1, pageSize: 200 });

    const groupedAndSortedFAQ = useMemo(() => {
        return faqItems?.data
            ?.reduce(
                (groups, faq) => {
                    const category: string = faq.category || t('home.others');
                    const categoryGroup = groups.find((group) => group.category === category);
                    if (categoryGroup) {
                        categoryGroup.faq.push(faq);
                    } else {
                        groups.push({ category, faq: [faq] });
                    }
                    return groups;
                },
                [] as { category: string; faq: IFaq[] }[],
            )
            .sort((a, b) => {
                if (a.category === t('home.others')) return 1;
                if (b.category === t('home.others')) return -1;
                return a.category.localeCompare(b.category);
            });
    }, [faqItems, t]);

    return (
        <>
            <Typography variant="subtitle1">{t('faq')}</Typography>
            <Stack spacing={1} sx={{ width: '100%' }}>
                <>
                    {groupedAndSortedFAQ?.map((group, groupIndex) => (
                        <Accordion key={groupIndex}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
                                    {group.category}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {group.faq.map((faq) => (
                                    <Box
                                        key={faq.id}
                                        sx={{
                                            pb: 2,
                                            pt: 2,
                                        }}
                                    >
                                        <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
                                            {faq.question}
                                        </Typography>
                                        <Typography>{faq.answer}</Typography>
                                    </Box>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            </Stack>
        </>
    );
};
