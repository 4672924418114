import { Link, Paper, Stack, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const LogoutPage: FC = () => {
    const { t } = useTranslation();

    const onLoginAgain = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    return (
        <Paper
            sx={{
                maxWidth: 420,
                mt: 8,
                mx: 'auto',
                textAlign: 'center',
            }}
        >
            <Stack p={4} spacing={2} alignItems="center">
                <img src={window.carity.environment.theme.logo} alt={window.carity.environment.city} width="80px" />
                <Typography variant="h2">{t('logoutTitle')}</Typography>
                <Link onClick={onLoginAgain}>{t('loginAgain')}</Link>
            </Stack>
        </Paper>
    );
};
