import { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback } from '../../../main';
import { Page, useMyRentalRequest } from '../../../shared';
import { RequestFormSteps } from '../../components';
import { RentalRequestContextProvider } from '../../contexts';
import { requestFormSubRoutes } from '../../rental-request.routes';
import { RequestFormIntroPage } from '../request-form-intro/request-form-intro.page';
import { useTranslation } from 'react-i18next';

export const RequestFormPage: FC = () => {
    const { data: rentalRequest, isLoading } = useMyRentalRequest();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.form', { context: window.carity.environment.theme.name });
    }, [t]);

    if (rentalRequest !== undefined && !rentalRequest?.privacyConsent) {
        return (
            <Page loading={isLoading}>
                <RequestFormIntroPage />
            </Page>
        );
    }

    return (
        <RentalRequestContextProvider>
            <Page loading={isLoading}>
                <nav>
                    <RequestFormSteps />
                </nav>
                <Routes>
                    {requestFormSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    <main>{<subRoute.component />}</main>
                                </ErrorBoundary>
                            }
                        />
                    ))}
                </Routes>
            </Page>
        </RentalRequestContextProvider>
    );
};
