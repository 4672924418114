import { CheckCircle } from '@mui/icons-material';
import { Alert, AlertProps, AlertTitle, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EligibleStatus, QueryKeys } from '../../../../shared';
import { formatDateToZonedTimeString } from '../../../../shared/utils/date-utils';
import { useRentalRequest } from '../../../contexts';
import { ContactFaq } from './contact-faq.component';

interface Props {
    severity: Record<EligibleStatus, AlertProps['severity']>;
    translation: Record<EligibleStatus, string>;
    status: EligibleStatus;
}

export const IsEligibleInfo: FC<Props> = ({ severity, translation, status }) => {
    const { t } = useTranslation();
    const { faqLink, city, theme } = window.carity.environment;
    const { rentalRequest: contextRentalRequest } = useRentalRequest();
    const isSaving = useIsMutating({ mutationKey: [QueryKeys.MY_RENTAL_REQUEST] });
    const lastSavedDateString =
        contextRentalRequest && formatDateToZonedTimeString(contextRentalRequest.lastUpdatedByRequester);

    return (
        <Stack direction="column" spacing={1}>
            <Alert severity={severity[status]}>
                <AlertTitle>{t('status')}</AlertTitle>
                <Typography variant="body2">{t(translation[status], { context: theme.name })}</Typography>

                {contextRentalRequest?.notEligibleReason && (
                    <Stack spacing={1}>
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'bold' }}>{`${t('reason')}: `}</span>
                            {t(contextRentalRequest?.notEligibleReason, { city: city })}
                        </Typography>
                        <ContactFaq />
                    </Stack>
                )}
            </Alert>

            {contextRentalRequest && (
                <Stack direction="row" spacing={1}>
                    {isSaving != 0 ? <CircularProgress size={20} /> : <CheckCircle color="success" />}
                    <Typography variant="body2" color="text.secondary">
                        {t('lastSavedInfo', { lastSavedDateString, interpolation: { escapeValue: false } })}
                    </Typography>
                </Stack>
            )}

            {faqLink && (
                <Typography variant="caption" color="text.secondary">
                    {t('faqLinkInfo', { context: theme.name })}
                    <Link href={faqLink} target="__blank">
                        {t('here')}.
                    </Link>
                </Typography>
            )}
        </Stack>
    );
};
