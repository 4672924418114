import { Button, Card, CardContent, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../../auth';
import { useMyRentalRequest, useUpdateMyRentalRequest } from '../../../shared';
import { AutofillLoadingComponent } from '../../components';

export const RequestFormIntroPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: userData } = useGetUser();
    const privacyPolicyLink = window.carity.environment.privacyPolicyLink;
    const processingPersonalDataConsentRequired = window.carity.environment.processingPersonalDataConsentRequired;
    const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
    const [acceptProcessingPersonalData, setAcceptProcessingPersonalData] = useState(false);

    const { data: myRentalRequest, isLoading } = useMyRentalRequest();
    const { mutateAsync: upsertRentalRequest, isPending: isUpdating, isSuccess } = useUpdateMyRentalRequest();

    const handleClickOnAgree = useCallback(async () => {
        await upsertRentalRequest({
            rentalRequest: {
                ...myRentalRequest,
                preferences: undefined,
                privacyConsent: checkPrivacyPolicy,
                processingPersonalDataConsent: acceptProcessingPersonalData,
            },
        });
        navigate('/my-rental-request/edit/primary-resident');
    }, [myRentalRequest, navigate, upsertRentalRequest, checkPrivacyPolicy, acceptProcessingPersonalData]);

    const handleClickOnDisagree = useCallback(async () => {
        navigate('/');
    }, [navigate]);

    if (isUpdating || isSuccess) {
        return <AutofillLoadingComponent />;
    }

    return (
        <Card>
            <CardContent>
                <Stack>
                    <Typography variant="h2">{t('greeting', { firstName: userData?.data?.firstName })}</Typography>
                    <Typography mb={1}>{t('privacyConsentInfo')}</Typography>

                    <Typography component={Link} href={privacyPolicyLink} target="__blank" mb={3}>
                        {t('linkTextToPrivacyPolicy')}
                    </Typography>

                    <FormControlLabel
                        control={<Checkbox onChange={() => setCheckPrivacyPolicy(!checkPrivacyPolicy)} size="small" />}
                        label={t('acceptPrivacyPolicy')}
                        sx={{ mb: processingPersonalDataConsentRequired ? 0 : 2 }}
                    />

                    {processingPersonalDataConsentRequired && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => setAcceptProcessingPersonalData(!acceptProcessingPersonalData)}
                                    size="small"
                                />
                            }
                            label={t('acceptProcessingPersonalData')}
                            sx={{ mb: 2 }}
                        />
                    )}
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={handleClickOnAgree}
                            disabled={
                                isLoading ||
                                !checkPrivacyPolicy ||
                                (processingPersonalDataConsentRequired && !acceptProcessingPersonalData)
                            }
                        >
                            {t('agree')}
                        </Button>

                        <Button variant="outlined" onClick={handleClickOnDisagree} disabled={isLoading}>
                            {t('cancel')}
                        </Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
