import { Divider, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
    ControlledNumberInput,
    ControlledSwitch,
    FormGrid,
    ICoResident,
    IFinancialDataForm,
    IPrimaryResident,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { Assets } from '../assets/assets.component';
import { getErrorForResidentProperty } from '../../utils';

interface Props {
    name: string;
    field: FieldArrayWithId<IFinancialDataForm, 'residents'>;
    resident?: IPrimaryResident | ICoResident;
    disabled?: boolean;
    validation?: IRentalRequestValidation;
}

export const FinancialDataRentSubsidy: FC<Props> = ({ name, field, resident, disabled, validation }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const watchValues = watch(name);
    const { rentSubsidyLink, rentSubsidyLink2 } = window.carity.environment;

    return (
        <FormGrid width="100%">
            <Divider />
            <ControlledSwitch
                name={`${name}.financialData.hasRentSubsidy`}
                label={t('hasRentSubsidy')}
                labelHelpText={
                    <Typography variant="caption">
                        <Trans
                            i18nKey="hasRentSubsidyHelpText"
                            components={[
                                <Link href={rentSubsidyLink} target="__blank" />,
                                <Link href={rentSubsidyLink2} target="__blank" />,
                            ]}
                        />
                    </Typography>
                }
                disabled={disabled}
                required
            />

            {watchValues.financialData.hasRentSubsidy && (
                <ControlledNumberInput
                    name={`${name}.financialData.rentSubsidy`}
                    label={t('rentSubsidy')}
                    disabled={disabled}
                    required
                    money
                />
            )}

            {watchValues.financialData.hasRentSubsidy && (
                <ControlledSwitch
                    name={`${name}.financialData.useRentSubsidyForMaxRent`}
                    label={t('useRentSubsidyForMaxRent')}
                    disabled={disabled}
                    required
                />
            )}

            {watchValues.financialData.hasRentSubsidy && watchValues.financialData.useRentSubsidyForMaxRent && (
                <Assets
                    type={RentalRequestAssetType.RENT_SUBSIDY_DOCUMENT}
                    residentId={field.id}
                    assets={resident?.financialData?.rentalRequestAssets}
                    error={getErrorForResidentProperty('RENT_SUBSIDY_DOCUMENT', resident?.id, validation)}
                    label={t('rentSubsidyDocument')}
                    disabled={disabled}
                    required
                />
            )}
        </FormGrid>
    );
};
