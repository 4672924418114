import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { IProvider } from '../models';
import { QueryKeys } from '../../shared';

async function getProviders(onGetErrorMessage?: string): Promise<IProvider[]> {
    const { data } = await axios.get('/auth/providers', { errorMessage: onGetErrorMessage });
    return data;
}

export function useGetProviders() {
    const { t } = useTranslation();
    return useQuery<IProvider[], AxiosError>({
        queryKey: [QueryKeys.AUTH_PROVIDERS],
        queryFn: () => getProviders(t('getProvidersError')),
    });
}
