import { Box, Button, Card, CardActions, CardContent, CircularProgress, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    RentalRequestErrorType,
    RentalRequestStatus,
    useGetValidation,
    useMyRentalRequest,
    useSetShowValidation,
    useSubmitMyRentalRequest,
} from '../../../shared';
import { CompleteRentalRequestOverview, Validation } from '../../components';

export const RequestFormOverviewPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: myRentalRequest } = useMyRentalRequest();
    const { data: validation } = useGetValidation();
    const { mutateAsync: submitRentalRequest, isPending } = useSubmitMyRentalRequest();
    const { mutateAsync: setShowValidation } = useSetShowValidation();

    useEffect(() => {
        if (myRentalRequest && !myRentalRequest.showValidation) {
            setShowValidation();
        }
    }, [myRentalRequest, setShowValidation]);

    const isRentalRequestComplete = useMemo(
        () =>
            validation?.errors?.filter((err) => err?.type !== RentalRequestErrorType.RENTAL_REQUEST_NOT_ELIGIBLE)
                ?.length == 0,
        [validation],
    );

    const handlePreviousClick = useCallback(() => {
        navigate('/my-rental-request/edit/residence');
    }, [navigate]);

    const onSubmit = useCallback(async () => {
        await submitRentalRequest();
        navigate('/my-rental-request/submitted');
    }, [navigate, submitRentalRequest]);

    const submitButton = (
        <Button
            variant="contained"
            onClick={onSubmit}
            disabled={
                validation?.errors.length !== 0 || myRentalRequest?.status !== RentalRequestStatus.DRAFT || isPending
            }
            startIcon={isPending && <CircularProgress color="inherit" size={25} />}
        >
            {myRentalRequest?.submittedDate ? t('myRentalRequest.reSubmit') : t('myRentalRequest.submit')}
        </Button>
    );

    return (
        <Stack direction="row" spacing={2}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Card id="main-content">
                    <CardContent>
                        {!isRentalRequestComplete && <Validation />}
                        {isRentalRequestComplete && <CompleteRentalRequestOverview />}
                    </CardContent>
                    <CardActions sx={{ ml: 1, mb: 1 }}>{submitButton}</CardActions>
                </Card>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '10px' }}>
                    <Button variant="outlined" onClick={handlePreviousClick} disabled={isPending}>
                        {t('previous')}
                    </Button>
                    {submitButton}
                </Box>
            </Stack>
        </Stack>
    );
};
