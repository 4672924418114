import { Box, CircularProgress, Grid, Step, StepButton, StepLabel, Stepper, Typography } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    RentalRequestErrorType,
    RentalRequestStatus,
    useBreakpoint,
    useGetValidation,
    useMyRentalRequest,
} from '../../../shared';
import { propertyToStepMap } from '../../mappers';

export const RequestFormSteps: FC = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { bondWithTheCity, theme } = window.carity.environment;
    const navigate = useNavigate();

    const { data: myRentalRequest } = useMyRentalRequest();
    const { data: validation } = useGetValidation(myRentalRequest?.showValidation || false);

    const steps = useMemo(() => {
        const isDraft = !myRentalRequest || myRentalRequest.status === RentalRequestStatus.DRAFT;
        return [
            { url: '/primary-resident', title: t('primaryResident') },
            { url: '/residents', title: t('residents') },
            { url: '/financial', title: t('financial', { context: theme.name }) },
            ...(bondWithTheCity ? [{ url: '/bond-with-the-city', title: t('bondWithTheCity') }] : []),
            { url: '/residence', title: t('residence') },
            { url: '/overview', title: t('overview'), disabled: !isDraft },
        ];
    }, [bondWithTheCity, myRentalRequest, t, theme]);

    const activeUrl = pathname.replace('/my-rental-request/edit', '');
    const activeStep = steps.findIndex((step) => step.url === activeUrl);
    const spinnerProgress = (activeStep + 1) * (100 / steps.length);

    const primaryResident = useMemo(
        () => myRentalRequest?.residents.find((res) => res.isPrimaryResident),
        [myRentalRequest],
    );

    const errorInStep = useCallback(
        (step: number) => {
            if (!myRentalRequest?.showValidation) {
                return false;
            }
            return validation?.errors?.some((error) =>
                error.errors
                    .map((val) => {
                        if (
                            error.type === RentalRequestErrorType.RESIDENT &&
                            primaryResident?.id === error.residentId
                        ) {
                            return propertyToStepMap['primary'][`${val.property}`];
                        } else {
                            return propertyToStepMap['nonPrimary'][`${val.property}`];
                        }
                    })
                    .includes(step.toString(10)),
            );
        },
        [myRentalRequest, validation, primaryResident],
    );

    const buttonDisabled = useMemo(() => {
        return !myRentalRequest?.showValidation;
    }, [myRentalRequest]);

    return (
        <>
            {useBreakpoint('sm') ? (
                <Stepper sx={{ mb: 4 }} activeStep={activeStep} alternativeLabel nonLinear>
                    {steps.map((step, i) => (
                        <Step key={step.url} completed={i < activeStep}>
                            <StepButton
                                disabled={buttonDisabled || step.disabled}
                                color="inherit"
                                onClick={() => {
                                    navigate(`/my-rental-request/edit${steps[i].url}`);
                                }}
                            >
                                <StepLabel error={errorInStep(i + 1)}>{step.title}</StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            ) : (
                <Grid container alignItems="center" sx={{ mb: 3 }}>
                    <Grid item position="relative" display="inline-flex" sx={{ position: 'relative' }}>
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size={45}
                            thickness={5}
                            sx={{ color: 'lightgrey' }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={spinnerProgress}
                            size={45}
                            thickness={5}
                            sx={{ position: 'absolute', left: 0 }}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="body2" component="div" color="textSecondary" fontWeight="bold">
                                {`${activeStep + 1}/${steps.length}`}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item sx={{ ml: '25px' }}>
                        <Typography variant="h5" component="div" color="textSecondary">
                            {steps[activeStep].title}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
