import { IFaq, ListQueryParam } from '../models';
import { rentingApi } from './renting-api.client';

class FaqClient {
    public async getAllFaq(query: ListQueryParam): Promise<IFaq> {
        const { data } = await rentingApi.get<IFaq>('/faq', { params: query });
        return data;
    }
}

export const faqClient = new FaqClient();
