import { createTheme } from '@mui/material';
import { nlNL } from '@mui/material/locale';
import { createAntwerpTheme, createBaseTheme } from '../themes';
import { createLeuvenTheme } from '../themes/leuven.theme';

export const useTheme = () => {
    const theme = window.carity.environment.theme;

    switch (theme.name) {
        case 'antwerp':
            return createTheme(createAntwerpTheme(theme), nlNL);
        case 'leuven':
            return createTheme(createLeuvenTheme(theme), nlNL);
        default:
            return createTheme(createBaseTheme(theme), nlNL);
    }
};
