import shared from './shared';
import main from './main';
import auth from './auth';
import errors from './errors';
import eligibleStatus from './eligible-status';
import myRentalRequest from './my-rental-request';
import rentalRequest from './rental-request';
import primaryResident from './primary-resident';
import coResident from './co-resident';
import financial from './financial';
import preferences from './preferences';
import helpText from './help-text';
import bondWithTheCity from './bond-with-the-city';

export default {
    translation: {
        ...main,
        ...shared,
        ...auth,
        ...errors,
        ...eligibleStatus,
        ...myRentalRequest,
        ...rentalRequest,
        ...primaryResident,
        ...coResident,
        ...financial,
        ...preferences,
        ...helpText,
        ...bondWithTheCity,
    },
};
