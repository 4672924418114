import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ControlledAutocomplete,
    ControlledDatePicker,
    ControlledInput,
    ControlledSocialSecurityNumberInput,
    FormGrid,
    Relation,
} from '../../../shared';
import { Typography } from '@mui/material';

interface Props {
    name?: string;
    disabled?: boolean;
}

export const CoResidentInfo: FC<Props> = ({ name, disabled }) => {
    const { t } = useTranslation();
    const prefix = useMemo(() => (name ? `${name}.` : ''), [name]);

    return (
        <>
            {name === 'primaryResident' && <Typography variant="subtitle1">{t('requester')}</Typography>}
            <FormGrid xs={12} md={6} lg={name === 'primaryResident' ? true : 3}>
                <ControlledInput name={`${prefix}firstName`} label={t('firstName')} disabled={disabled} required />
                <ControlledInput name={`${prefix}lastName`} label={t('lastName')} disabled={disabled} required />

                <ControlledSocialSecurityNumberInput
                    name={`${prefix}socialSecurityNumber`}
                    label={t('socialSecurityNumber')}
                    disabled={disabled}
                    required
                />

                <ControlledDatePicker
                    name={`${prefix}dateOfBirth`}
                    label={t('dateOfBirth')}
                    variant="outlined"
                    disabled={disabled}
                    required
                />

                {name !== 'primaryResident' && (
                    <ControlledAutocomplete
                        name={`${prefix}relation`}
                        label={t('relation')}
                        disabled={disabled}
                        required
                        options={Object.values(Relation).map((relation) => relation)}
                        getOptionLabel={(relation) => t(relation)}
                    />
                )}
            </FormGrid>
        </>
    );
};
