import { Delete, Edit } from '@mui/icons-material';
import { Avatar, Chip, Divider, Grid, IconButton, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatSocialSecurityNumber, ICoResident, IRentalRequestError, RemoveModal } from '../../../shared';
import { useIsResidentIncomplete } from '../../utils';

interface Props {
    resident: ICoResident;
    openEdit: (resident: ICoResident) => void;
    onDelete: (resident: ICoResident) => void;
    error?: IRentalRequestError;
    disabled?: boolean;
}

export const DisplayResidentRow: FC<Props> = ({ resident, openEdit, onDelete, error, disabled }) => {
    const { t } = useTranslation();

    const personalInfo = useMemo((): { initials: string; firstName: string; lastName: string } => {
        return {
            initials: `${resident.firstName?.toUpperCase().at(0)}${resident.lastName?.toUpperCase().at(0)}`,
            firstName: resident.firstName || '',
            lastName: resident.lastName || '',
        };
    }, [resident]);

    const containsPersonalInfoError = useMemo(
        () => error?.errors?.some((err) => ['socialSecurityNumber'].includes(err.property)),
        [error],
    );

    const isResidentIncomplete = useIsResidentIncomplete();

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" columnSpacing={2}>
                <Grid item>
                    <Avatar sx={{ backgroundColor: blue[100], color: blue[800], fontWeight: 'bold' }}>
                        {personalInfo.initials}
                    </Avatar>
                </Grid>

                <Grid item mr="auto">
                    <Typography>{`${personalInfo.firstName} ${personalInfo.lastName}`}</Typography>
                    <Typography variant="body2" color="grey">
                        {formatSocialSecurityNumber(resident.socialSecurityNumber)} <br />
                        {resident.relation && t(resident.relation)}
                    </Typography>
                </Grid>

                <Grid item ml={{ sm: 'auto' }} my={{ xs: 1, sm: 0 }} order={{ xs: 2, sm: 1 }}>
                    {!isResidentIncomplete(resident) && containsPersonalInfoError && (
                        <Chip
                            label={t('validationErrorText')}
                            color="error"
                            variant="outlined"
                            onClick={() => openEdit(resident)}
                        />
                    )}
                    {isResidentIncomplete(resident) && (
                        <Chip
                            label={t('missingData')}
                            color="warning"
                            variant="outlined"
                            onClick={() => openEdit(resident)}
                        />
                    )}
                </Grid>

                <Grid item order={{ xs: 1, sm: 2 }}>
                    <IconButton onClick={() => openEdit(resident)} disabled={disabled}>
                        <Edit />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => {
                            onDelete(resident);
                        }}
                        button={
                            <IconButton disabled={disabled}>
                                <Delete />
                            </IconButton>
                        }
                        title={t('deleteResidentTitle')}
                        text={t('deleteResidentText')}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ my: 1 }} />
        </>
    );
};
