import { Divider, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledNumberInput,
    ControlledSwitch,
    FormGrid,
    ICoResident,
    IFinancialDataForm,
    IPrimaryResident,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { getErrorForResidentProperty } from '../../utils';
import { Assets } from '../assets/assets.component';
import { FinancialDataResidentBudgetRenting } from '../finacial-data-resident-budget-renting/financial-data-resident-budget-renting.component';

interface Props {
    name: string;
    fullName: string;
    isPrimaryResident: boolean;
    updateWithInvalidate: () => void;
    field: FieldArrayWithId<IFinancialDataForm, 'residents'>;
    year: string | number;
    resident?: IPrimaryResident | ICoResident;
    validation?: IRentalRequestValidation;
    disabled?: boolean;
}

export const FinancialDataTaxBillFullYear: FC<Props> = ({
    name,
    fullName,
    isPrimaryResident,
    updateWithInvalidate,
    field,
    year,
    resident,
    validation,
    disabled,
}) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const watchValues = watch(name);
    const { taxBillDocumentLink, livingWageLink, budgetRentingDocument } = window.carity.environment;

    const taxBillDocumentError = getErrorForResidentProperty('TAX_BILL_DOCUMENT', resident?.id, validation);
    const livingWageDocumentError = getErrorForResidentProperty('LIVING_WAGE_DOCUMENT', resident?.id, validation);

    return (
        <FormGrid width="100%">
            <ControlledNumberInput
                name={`${name}.financialData.jointTaxableIncome`}
                label={
                    isPrimaryResident
                        ? t('primaryResidentFinances.jointTaxableIncome')
                        : t('coResidentFinances.jointTaxableIncome', { name: fullName })
                }
                disabled={disabled}
                required
                money
            />

            <ControlledSwitch
                name={`${name}.financialData.hasIndividualTaxableIncome`}
                label={
                    isPrimaryResident
                        ? t('primaryResidentFinances.hasIndividualTaxableIncome')
                        : t('coResidentFinances.hasIndividualTaxableIncome', {
                              name: fullName,
                          })
                }
                onChangeSelected={updateWithInvalidate}
                disabled={disabled}
                required
            />

            {watchValues.financialData?.hasIndividualTaxableIncome && (
                <ControlledNumberInput
                    name={`${name}.financialData.individualTaxableIncome`}
                    label={
                        isPrimaryResident
                            ? t('primaryResidentFinances.individualTaxableIncome')
                            : t('coResidentFinances.individualTaxableIncome', {
                                  name: fullName,
                              })
                    }
                    disabled={disabled}
                    required
                    money
                />
            )}

            {!watchValues?.financialData?.isJointTaxReturn && (
                <Assets
                    type={RentalRequestAssetType.TAX_BILL_DOCUMENT}
                    residentId={field.id}
                    assets={resident?.financialData?.rentalRequestAssets}
                    label={t('taxBillDocumentUpload')}
                    labelHelpText={
                        <Typography variant="caption" display="block">
                            {t('taxBillDocumentUploadHelpText')}
                            <Link href={taxBillDocumentLink} target="_blank" rel="noreferrer">
                                {t('taxBillDocumentLink')}
                            </Link>
                        </Typography>
                    }
                    error={taxBillDocumentError}
                    disabled={disabled}
                    required
                />
            )}

            {budgetRentingDocument && isPrimaryResident && (
                <FormGrid width="100%">
                    <Divider />
                    <FinancialDataResidentBudgetRenting
                        name={name}
                        updateWithInvalidate={updateWithInvalidate}
                        field={field}
                        resident={resident}
                        validation={validation}
                        disabled={disabled}
                    />
                </FormGrid>
            )}

            <Divider />

            <ControlledSwitch
                name={`${name}.financialData.hasLivingWage`}
                label={
                    isPrimaryResident
                        ? t('primaryResidentFinances.hasLivingWage', {
                              year: year,
                          })
                        : t('coResidentFinances.hasLivingWage', {
                              name: fullName,
                              year: year,
                          })
                }
                labelHelpText={
                    <>
                        {t('livingWageHelpText')}
                        <Link href={livingWageLink} target="_blank" rel="noreferrer">
                            {t('livingWageLink')}
                        </Link>
                    </>
                }
                onChangeSelected={updateWithInvalidate}
                disabled={disabled}
                required
            />

            {watchValues.financialData.hasLivingWage && (
                <ControlledNumberInput
                    name={`${name}.financialData.livingWage`}
                    label={
                        isPrimaryResident
                            ? t('primaryResidentFinances.livingWageAmount', {
                                  year: year,
                              })
                            : t('coResidentFinances.livingWageAmount', {
                                  name: fullName,
                                  year: year,
                              })
                    }
                    disabled={disabled}
                    required
                    money
                />
            )}

            {watchValues.financialData.hasLivingWage && (
                <Assets
                    type={RentalRequestAssetType.LIVING_WAGE_DOCUMENT}
                    residentId={field.id}
                    assets={resident?.financialData?.rentalRequestAssets}
                    error={livingWageDocumentError}
                    label={t('uploadLabel')}
                    disabled={disabled}
                    required
                />
            )}
        </FormGrid>
    );
};
