import React, { FC } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledNumberInput,
    ControlledSwitch,
    FormGrid,
    ICoResident,
    IFinancialDataForm,
    IPrimaryResident,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { getErrorForResidentProperty } from '../../utils';
import { Assets } from '../assets/assets.component';
import { Divider } from '@mui/material';
import { FinancialDataResidentBudgetRenting } from '../finacial-data-resident-budget-renting/financial-data-resident-budget-renting.component';

interface Props {
    name: string;
    fullName: string;
    isPrimaryResident: boolean;
    updateWithInvalidate: () => void;
    field: FieldArrayWithId<IFinancialDataForm, 'residents'>;
    resident?: IPrimaryResident | ICoResident;
    validation?: IRentalRequestValidation;
    disabled?: boolean;
}

export const FinancialDataNoTaxBillFullYear: FC<Props> = ({
    name,
    fullName,
    isPrimaryResident,
    updateWithInvalidate,
    field,
    resident,
    validation,
    disabled,
}) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const watchValues = watch(name);

    const { budgetRentingDocument } = window.carity.environment;
    const monthlyIncomeDocumentError = getErrorForResidentProperty('MONTHLY_INCOME_DOCUMENT', resident?.id, validation);

    return (
        <FormGrid width="100%">
            <ControlledSwitch
                name={`${name}.financialData.hasMonthlyTaxableIncome`}
                label={
                    isPrimaryResident
                        ? t('primaryResidentFinances.hasMonthlyTaxableIncome')
                        : t('coResidentFinances.hasMonthlyTaxableIncome', {
                              name: fullName,
                          })
                }
                onChangeSelected={updateWithInvalidate}
                disabled={disabled}
                required
            />

            {watchValues.financialData?.hasMonthlyTaxableIncome && (
                <ControlledNumberInput
                    name={`${name}.financialData.monthlyTaxableIncome`}
                    label={
                        isPrimaryResident
                            ? t('primaryResidentFinances.monthlyTaxableIncome')
                            : t('coResidentFinances.monthlyTaxableIncome', {
                                  name: fullName,
                              })
                    }
                    disabled={disabled}
                    required
                    money
                />
            )}

            {watchValues.financialData?.hasMonthlyTaxableIncome && (
                <Assets
                    type={RentalRequestAssetType.MONTHLY_INCOME_DOCUMENT}
                    residentId={field.id}
                    assets={resident?.financialData?.rentalRequestAssets}
                    label={t('monthlyTaxBillDocumentUpload')}
                    error={monthlyIncomeDocumentError}
                    disabled={disabled}
                    required
                />
            )}

            {budgetRentingDocument && isPrimaryResident && (
                <>
                    <Divider sx={{ mb: 2 }} />
                    <FinancialDataResidentBudgetRenting
                        name={name}
                        updateWithInvalidate={updateWithInvalidate}
                        field={field}
                        resident={resident}
                        validation={validation}
                        disabled={disabled}
                    />
                </>
            )}
        </FormGrid>
    );
};
