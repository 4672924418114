import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    ControlledSwitch,
    FormGrid,
    IPreferencesForm,
    RentalRequestStatus,
    useGetPreferencePropertyTypes,
    useGetRegions,
    useMyRentalRequest,
    usePreferencesValidator,
    useUpdateMyRentalRequest,
} from '../../../shared';
import { InfoBox, PreferencePropertyType, RegionPreference } from '../../components';
import { preferencesFromFormMapper, preferencesToFormMapper } from '../../mappers/preferences.mapper';

export const RequestFormPreferencesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { bondWithTheCity, agreementToChangeResidence, showPreferencesRentPrice, showRemarks, theme } =
        window.carity.environment;
    const [agreeToChangeResidence, setAgreeToChangeResidence] = useState(false);

    const { data: rentalRequest } = useMyRentalRequest();
    const readOnly = useMemo(() => rentalRequest?.status !== RentalRequestStatus.DRAFT, [rentalRequest]);
    const { mutateAsync: updateMyRentalRequest } = useUpdateMyRentalRequest();
    const { data: regions } = useGetRegions({ pageSize: 50 });
    const { data: preferencePropertyTypes } = useGetPreferencePropertyTypes({});

    const form = useForm<IPreferencesForm>({
        mode: 'all',
        resolver: yupResolver(
            usePreferencesValidator(regions?.data.length != 0, preferencePropertyTypes?.data.length != 0),
        ),
    });

    useEffect(() => {
        if (rentalRequest) {
            form.reset(preferencesToFormMapper(rentalRequest, regions?.data.length != 0));
            setAgreeToChangeResidence(rentalRequest.agreeToChangeResidence || false);
        }
    }, [rentalRequest, regions, form]);

    //Get array of possible amount of bedrooms
    const possibleBedroomAmounts = useMemo(() => {
        const min = rentalRequest?.preferences?.minimumAmountOfBedrooms;
        const max = rentalRequest?.preferences?.maximumAmountOfBedrooms;

        if (min !== null && min !== undefined && max) {
            return Array.from({ length: max - min + 1 }, (value, index) => min + index);
        }
    }, [rentalRequest]);

    const handlePreviousClick = useCallback(() => {
        bondWithTheCity
            ? navigate('/my-rental-request/edit/bond-with-the-city')
            : navigate('/my-rental-request/edit/financial');
    }, [navigate, bondWithTheCity]);

    const updateWithoutInvalidate = useCallback(async () => {
        if (rentalRequest?.status === RentalRequestStatus.DRAFT) {
            await updateMyRentalRequest({
                rentalRequest: preferencesFromFormMapper(rentalRequest, form.getValues(), agreeToChangeResidence),
                invalidate: false,
            });
        }
    }, [rentalRequest, updateMyRentalRequest, form, agreeToChangeResidence]);

    const handleSubmit = async (form: IPreferencesForm) => {
        if (rentalRequest) {
            if (rentalRequest.status === RentalRequestStatus.DRAFT) {
                await updateMyRentalRequest({
                    rentalRequest: preferencesFromFormMapper(rentalRequest, form, agreeToChangeResidence),
                });
                navigate('/my-rental-request/edit/overview');
            } else {
                navigate('/my-rental-request');
            }
        }
    };

    return (
        <FormProvider {...form}>
            <form onBlur={updateWithoutInvalidate} onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <main>
                    <Grid container columns={{ xs: 1, md: 2 }} justifyContent="space-between">
                        <InfoBox readOnly={readOnly} />

                        <Grid item sx={{ width: { xs: '100%', md: '79%' }, order: { xs: 2, md: 1 } }}>
                            <Card id="main-content">
                                <CardContent>
                                    <Stack mb={3}>
                                        <Typography variant="subtitle1">{t('residence')}</Typography>
                                        <Typography variant="body2">
                                            {t('preferencesIntro', { context: theme.name })}
                                        </Typography>
                                    </Stack>

                                    <FormGrid width="100%">
                                        <RegionPreference disabled={readOnly} />

                                        {(preferencePropertyTypes?.data?.length || 0) > 1 && (
                                            <PreferencePropertyType disabled={readOnly} />
                                        )}

                                        <ControlledSwitch
                                            name={'elevator'}
                                            label={t('elevator')}
                                            disabled={readOnly}
                                            required
                                        />

                                        {(possibleBedroomAmounts?.length || 0) > 1 && (
                                            <FormGrid width="100%">
                                                <ControlledSelect
                                                    name="requiredAmountOfBedrooms"
                                                    label={t('requiredAmountOfBedrooms')}
                                                    options={
                                                        possibleBedroomAmounts?.map((val) => ({
                                                            id: val.toString(),
                                                            name: val.toString(),
                                                        })) || []
                                                    }
                                                    multiple
                                                    checkBox
                                                    disabled={readOnly}
                                                    required
                                                    includeAll
                                                />
                                            </FormGrid>
                                        )}

                                        {showPreferencesRentPrice && (
                                            <ControlledNumberInput
                                                name="maxRentPrice"
                                                label={t('maxRentPrice')}
                                                money
                                                disabled={readOnly}
                                            />
                                        )}

                                        {showRemarks && (
                                            <ControlledInput
                                                name="remarks"
                                                label={t('remarks')}
                                                multiline
                                                rows={4}
                                                disabled={readOnly}
                                            />
                                        )}
                                        {agreementToChangeResidence && (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={agreeToChangeResidence}
                                                        onChange={() =>
                                                            setAgreeToChangeResidence(!agreeToChangeResidence)
                                                        }
                                                        size="small"
                                                    />
                                                }
                                                label={t('agreeToChangeResidence')}
                                                sx={{ mb: 2 }}
                                                required
                                            />
                                        )}
                                    </FormGrid>

                                    <Typography variant="caption" color="grey" sx={{ display: 'block', mt: 3 }}>
                                        {t('requiredInput')}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={handlePreviousClick}>
                                    {t('previous')}
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={agreementToChangeResidence && !agreeToChangeResidence}
                                >
                                    {t('next')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </main>
            </form>
        </FormProvider>
    );
};
