import { FormControl, FormHelperText, RadioGroup, RadioGroupProps, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CarityInputLabel } from '../input-label/input-label.component';

interface Props extends RadioGroupProps {
    name: string;
    label?: string;
    labelHelpText?: string;
    disabled?: boolean;
    required?: boolean;
}

export const ControlledRadioGroup: FC<Props> = ({
    children,
    name,
    label,
    labelHelpText,
    defaultValue,
    disabled,
    required,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <FormControl disabled={disabled} sx={{ '.carity-label': { mb: labelHelpText ? 0 : 1 } }}>
            {label && (
                <>
                    <CarityInputLabel name={name} label={label} required={required} />
                    {labelHelpText && (
                        <Typography variant="caption" sx={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined }}>
                            {labelHelpText}
                        </Typography>
                    )}
                </>
            )}

            <Controller
                name={name}
                defaultValue={defaultValue || null}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <>
                        <RadioGroup
                            id={`${name}-field`}
                            aria-describedby={`${name}-helper-text`}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            {...rest}
                        >
                            {children}
                        </RadioGroup>
                        {error && (
                            <FormHelperText error id={`${name}-helper-text`}>
                                {error.message || t('fallbackError')}
                            </FormHelperText>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};
