import {
    IRentalRequestValidation,
    RentalRequestErrorProperties,
    RentalRequestErrorType,
    ResidentErrorProperties,
} from '../../shared';

export const getErrorForRentalRequestProperty = (
    property: keyof RentalRequestErrorProperties,
    validation?: IRentalRequestValidation,
) => {
    return validation?.errors
        ?.find((error) => error.type === RentalRequestErrorType.RENTAL_REQUEST)
        ?.errors?.find((e) => e.property === property);
};

export const getErrorForResidentProperty = (
    property: keyof ResidentErrorProperties,
    residentId?: string,
    validation?: IRentalRequestValidation,
) => {
    return validation?.errors
        ?.find((error) => error.type === RentalRequestErrorType.RESIDENT && error.residentId === residentId)
        ?.errors?.find((e) => e.property === property);
};
