import { TFunction } from 'i18next';
import { object, string } from 'yup';
import { isValidSocialSecurityNumber } from '../utils';
import { validatePastDate } from './date.validator';

export const baseResidentSchema = (t: TFunction) => {
    return object().shape({
        id: string().optional(),
        firstName: string()
            .trim()
            .min(1, t('requiredField', { fieldName: t('firstName') }))
            .max(200, t('maxLength', { amount: 200 }))
            .required(),
        lastName: string()
            .trim()
            .min(1, t('requiredField', { fieldName: t('lastName') }))
            .max(200, t('maxLength', { amount: 200 }))
            .required(),
        socialSecurityNumber: string()
            .min(1, t('requiredField', { fieldName: t('socialSecurityNumber') }))
            .test('socialSecurityNumber', t('invalidFormat', { fieldName: t('socialSecurityNumber') }), (value) =>
                value ? isValidSocialSecurityNumber(value) : false,
            )
            .required(t('requiredField', { fieldName: t('socialSecurityNumber') })),
        dateOfBirth: validatePastDate(t),
    });
};
