import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertProps,
    AlertTitle,
    Stack,
    Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EligibleStatus } from '../../../../shared';
import { useRentalRequest } from '../../../contexts';
import { ContactFaq } from './contact-faq.component';

interface Props {
    severity: Record<EligibleStatus, AlertProps['severity']>;
    translation: Record<EligibleStatus, string>;
    status: EligibleStatus;
}

export const IsEligibleInfoMobile: FC<Props> = ({ severity, translation, status }) => {
    const { t } = useTranslation();
    const { rentalRequest: contextRentalRequest } = useRentalRequest();

    return (
        <Alert severity={severity[status]} sx={{ mb: 3, '.MuiAlert-message': { width: '100%' } }}>
            <Accordion
                sx={{
                    backgroundColor: 'inherit',
                    color: 'inherit',
                    border: 'none',
                }}
            >
                <AccordionSummary
                    sx={{
                        pl: 0,
                        '&, &.Mui-expanded': { minHeight: 0 },
                        '.MuiAccordionSummary-expandIconWrapper': { color: 'inherit' },
                        '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded, .MuiAlertTitle-root':
                            { m: 0 },
                    }}
                    expandIcon={<ExpandMore />}
                >
                    <AlertTitle>{t('status')}</AlertTitle>
                </AccordionSummary>

                <AccordionDetails sx={{ borderTop: 'none', pb: 1, pl: 0 }}>
                    <Typography variant="caption">
                        <Typography variant="body2">{t(translation[status])}</Typography>

                        {contextRentalRequest?.notEligibleReason && (
                            <Stack spacing={1}>
                                <Typography variant="body2">
                                    <span style={{ fontWeight: 'bold' }}>{`${t('reason')}: `}</span>
                                    {t(contextRentalRequest?.notEligibleReason, {
                                        city: window.carity.environment.city,
                                    })}
                                </Typography>
                                <ContactFaq />
                            </Stack>
                        )}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Alert>
    );
};
