import { useTranslation } from 'react-i18next';
import { addressSchema } from './address.validator';
import { baseResidentSchema } from './base-resident.validator';
import { boolean, mixed, object } from 'yup';
import { Relation } from '../enums';
import { ageBetween18And25 } from '../../rental-request/utils';
import { useMemo } from 'react';

export function useCoResidentSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            baseResidentSchema(t).shape({
                relation: mixed<Relation>()
                    .oneOf(Object.values(Relation))
                    .required(t('requiredField', { fieldName: t('relation') })),
                hasChildSupport: boolean()
                    .nullable()
                    .when(['relation', 'dateOfBirth'], {
                        is: (relation: Relation, dateOfBirth: string) =>
                            relation === Relation.CHILD && ageBetween18And25(dateOfBirth),

                        then: () => boolean().nullable().required(t('requiredQuestion')),
                    }),
                isCurrentAddressSameAsPrimaryResident: boolean()
                    .required()
                    .nullable()
                    .test('required', t('requiredQuestion'), (value) => value !== null),
                currentAddress: object().when('isCurrentAddressSameAsPrimaryResident', {
                    is: true,
                    then: (schema) => schema.strip(),
                    otherwise: () => addressSchema(t),
                }),
            }),
        [t],
    );
}
