import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledNumberInput,
    ControlledSwitch,
    FormGrid,
    IRentalRequest,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { getErrorForRentalRequestProperty } from '../../utils';
import { Assets } from '../assets/assets.component';
import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

type Props = {
    readOnly: boolean;
    update: (x: boolean) => void;
    disabled?: boolean;
    rentalRequest?: IRentalRequest;
    validation?: IRentalRequestValidation;
};
export const ExpectingForm: FC<Props> = ({ readOnly, update, disabled, rentalRequest, validation }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const isExpectingWatch = form.watch('expectingChildren.isExpecting');

    return (
        <FormGrid width="100%">
            <Box>
                <Typography variant="subtitle2">{t('expectingChildren')}</Typography>
                <ControlledSwitch
                    label={t('isExpecting')}
                    name={`expectingChildren.isExpecting`}
                    onChangeSelected={() => update(true)}
                    disabled={readOnly}
                />
            </Box>

            {isExpectingWatch && (
                <FormGrid width="100%">
                    <ControlledNumberInput
                        name="expectingChildren.expectedAmount"
                        label={t('expectedAmount')}
                        disabled={disabled}
                        maxWidth={300}
                        required
                    />
                    <ControlledDatePicker
                        name={`expectingChildren.expectedDate`}
                        label={t('expectedDate')}
                        variant="outlined"
                        disabled={disabled}
                        sx={{ maxWidth: '300px' }}
                        required
                    />
                    <Assets
                        type={RentalRequestAssetType.PREGNANCY_DOCUMENT}
                        assets={rentalRequest?.expectingChildren?.rentalRequestAssets}
                        error={getErrorForRentalRequestProperty('PREGNANCY_DOCUMENT', validation)}
                        label={t('pregnancyDocument')}
                        disabled={disabled}
                    />
                </FormGrid>
            )}
        </FormGrid>
    );
};
