import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useGetUser } from '../../auth';
import { useRentalRequest } from '../../rental-request/contexts';
import { myRentalRequestClient } from '../clients';
import { IRentalRequest, IRentalRequestAssetQuery, IRentalRequestValidation, ISaveRentalRequest } from '../models';
import { QueryKeys } from '../enums';

export function useMyRentalRequest(): UseQueryResult<IRentalRequest, AxiosError> {
    const { data: user } = useGetUser();
    const { setRentalRequest } = useRentalRequest();
    return useQuery({
        queryKey: [QueryKeys.MY_RENTAL_REQUEST],
        queryFn: async () => {
            const result = await myRentalRequestClient.getMyRentalRequest();
            if (result) setRentalRequest(result);
            return result;
        },
        enabled: user?.success,
    });
}

export function useUpdateMyRentalRequest(): UseMutationResult<
    IRentalRequest,
    AxiosError,
    { rentalRequest: ISaveRentalRequest; invalidate?: boolean }
> {
    const queryClient = useQueryClient();
    const { setRentalRequest } = useRentalRequest();

    return useMutation({
        mutationKey: [QueryKeys.MY_RENTAL_REQUEST],
        mutationFn: ({ rentalRequest }) => myRentalRequestClient.updateMyRentalRequest(rentalRequest),
        onSuccess: async (result: IRentalRequest, { invalidate = true }) => {
            setRentalRequest(result);
            if (invalidate) await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST_VALIDATION] });
        },
    });
}

export function useUploadAsset(): UseMutationResult<void, AxiosError, { query: IRentalRequestAssetQuery; file: File }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ query, file }) => myRentalRequestClient.uploadAsset(query, file),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST_VALIDATION] });
        },
    });
}

export function useDeleteAsset(): UseMutationResult<void, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id) => myRentalRequestClient.deleteAsset(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST_VALIDATION] });
        },
    });
}

export function useGetValidation(enabled = true): UseQueryResult<IRentalRequestValidation, AxiosError> {
    return useQuery({
        queryKey: [QueryKeys.MY_RENTAL_REQUEST_VALIDATION],
        queryFn: () => myRentalRequestClient.getValidation(),
        enabled,
    });
}

export function useSetShowValidation(): UseMutationResult<IRentalRequestValidation, AxiosError, void> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => myRentalRequestClient.setShowValidation(),
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] });
            await queryClient.setQueryData([QueryKeys.MY_RENTAL_REQUEST_VALIDATION], data);
        },
    });
}

export function useSubmitMyRentalRequest(): UseMutationResult<IRentalRequest, AxiosError, void> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => myRentalRequestClient.submit(),
        onSuccess: async () => queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] }),
    });
}

export function useSetMyRentalRequestEditable(): UseMutationResult<IRentalRequest, AxiosError, void> {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    return useMutation({
        mutationKey: [QueryKeys.MY_RENTAL_REQUEST],
        mutationFn: () => myRentalRequestClient.setEditable(t('errorSetIncomplete')),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] }),
    });
}

export function useDeleteMyRentalRequest(): UseMutationResult<IRentalRequest, AxiosError, void> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [QueryKeys.MY_RENTAL_REQUEST],
        mutationFn: () => myRentalRequestClient.deleteMyRentalRequest(),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_RENTAL_REQUEST_VALIDATION] });
        },
    });
}
