import { IExtraInfoResidentsForm, IRentalRequest, ISaveRentalRequest } from '../../shared';
import { mapDateString } from './date-string.mapper';

export const extraInfoResidentsToFormMapper = (rentalRequest: IRentalRequest): IExtraInfoResidentsForm => {
    const expecting = rentalRequest.expectingChildren;

    return {
        monthlyChildSupport: rentalRequest.monthlyChildSupport?.toString() || '',
        expectingChildren: expecting?.isExpecting
            ? {
                  ...expecting,
                  expectedAmount: expecting.expectedAmount ? expecting.expectedAmount.toString() : '',
                  expectedDate: expecting.expectedDate ? new Date(expecting.expectedDate) : null,
              }
            : {
                  isExpecting: false,
                  expectedAmount: '',
                  expectedDate: null,
              },
    };
};

export const extraInfoResidentsFromFormMapper = (
    originalRentalRequest: IRentalRequest,
    extraInfo: IExtraInfoResidentsForm,
): ISaveRentalRequest => {
    const expecting = extraInfo?.expectingChildren;

    return {
        ...originalRentalRequest,
        monthlyChildSupport:
            extraInfo?.monthlyChildSupport !== '' || extraInfo?.monthlyChildSupport !== undefined
                ? Number(extraInfo?.monthlyChildSupport)
                : null,
        expectingChildren: expecting.isExpecting
            ? {
                  ...expecting,
                  expectedAmount: Number(expecting?.expectedAmount) || null,
                  expectedDate: mapDateString(expecting?.expectedDate) || null,
              }
            : { isExpecting: false, expectedAmount: null, expectedDate: null },
        preferences: undefined,
    };
};
