import { useMemo } from 'react';
import { boolean, mixed, object, string } from 'yup';
import { ResidentialRelation, WorkStudyRelation } from '../enums';
import { useTranslation } from 'react-i18next';

export function useBondWithTheCityValidator() {
    const { t } = useTranslation();

    return useMemo(() => {
        return object({
            id: string().optional(),
            residentialRelation: mixed<ResidentialRelation>()
                .oneOf(Object.values(ResidentialRelation))
                .nullable()
                .when('isCurrentlyRentingFromCity', {
                    is: false,
                    then: (schema) => schema.required(t('requiredQuestion')),
                    otherwise: (schema) => schema.optional(),
                }),
            workStudyRelation: mixed<WorkStudyRelation>()
                .oneOf(Object.values(WorkStudyRelation))
                .nullable()
                .when('isCurrentlyRentingFromCity', {
                    is: false,
                    then: (schema) => schema.required(t('requiredQuestion')),
                    otherwise: (schema) => schema.optional(),
                }),
            isCurrentlyRentingFromCity: boolean()
                .optional()
                .test('required', t('requiredQuestion'), (value) => value !== null),
        });
    }, [t]);
}
