export default {
    status: 'Status',
    currentStatus: 'Huidige status',
    reason: 'Reden',
    unableToCalculateInfoText:
        'Je gegevens zijn niet volledig. Zo kunnen we je aanvraag niet voldoende evalueren. Vul alle gegevens volledig en correct in.',
    notEligibleInfoText: 'Sorry, je kan geen huurwoning aanvragen.',
    eligibleInfoText:
        'Goed nieuws! Op basis van ingevulde gegevens maakt jouw kandidatuur aanspraak op betaalbaar huren. Na het indienen van jouw dossier worden eerst alle gegevens nog een keer gecontroleerd. Nadien krijg je een finale bevestiging.',
    eligibleInfoText_antwerp:
        'Goed nieuws! Op basis van ingevulde gegevens maakt jouw kandidatuur aanspraak op budgethuren. Na het indienen van jouw dossier worden eerst alle gegevens nog een keer gecontroleerd. Nadien krijg je een finale bevestiging.',

    SUBMITTED: {
        summary: 'Ter verificatie voorgelegd',
        description: 'Jouw kandidatuur wordt nu verwerkt en alle persoonsgegevens worden gecontroleerd.',
    },
    CHANGES_REQUESTED: {
        summary: 'Wijzigingen gevraagd',
        description:
            'Er werd je een mail gestuurd met vragen over je dossier. Van zodra deze beantwoord zijn, kan het dossier verder verwerkt worden.',
    },
    IN_VERIFICATION: {
        summary: 'In verificatie',
        description: 'Je dossier wordt op dit moment nagekeken door een medewerker.',
    },
    DRAFT: {
        summary: 'Incompleet',
        description: 'Je dossier is nog incompleet. Vervolledig je dossier en dien je dossier in.',
    },
    DISAPPROVED: {
        summary: 'Afgekeurd',
        description:
            'Je beantwoordt spijtig genoeg niet aan alle voorwaarden. Daardoor ben je geen kandidaat voor betaalbaar huren.',
    },
    VERIFIED: {
        summary: 'Geverifieerd',
        description:
            'We bekeken je aanvraag en controleerden je gegevens. Je beantwoordt aan alle voorwaarden. Binnenkort koppelen we de kandidaat-huurders aan de beschikbare appartementen. We houden je daarvan op de hoogte via e-mail.',
    },
    INVITED: {
        summary: 'Uitgenodigd',
        description:
            'We vonden een betaalbare huurwoning die geschikt is voor jou. We nodigen je graag uit om de woning te bekijken.',
    },
    ALLOCATED: {
        summary: 'Toegekend',
        description: 'Je hebt een woning toegewezen gekregen na een positieve evaluatie.',
    },
    APPOINTMENT_PLANNED: {
        summary: 'Bezoek ingepland',
        description: 'Het plaatsbezoek voor de geselecteerde woning werd ingepland.',
    },
    AWAITING_VISIT_FEEDBACK: {
        summary: 'Afwachting feedback bezoek',
        description:
            'Na jouw geplande bezoek kan je instemmen om de woning te huren. Binnen de 3 kalenderdagen verwachten we van jou een antwoord.',
    },
    CONTRACT_SIGNED: {
        summary: 'Contract ondertekend',
        description: 'Het contract voor je huurwoning is ondertekend en het toewijstraject is afgerond.',
    },
};
