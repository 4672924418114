import { InputLabel, InputLabelProps, Link, Stack, Tooltip, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import { IEnvironment } from '../../models';

interface Props extends InputLabelProps {
    name: string;
    label: string;
    inlineLabel?: boolean;
    disabled?: boolean;
    required?: boolean;
}

export const CarityInputLabel: FC<Props> = ({
    label,
    name,
    inlineLabel = false,
    disabled,
    required,
    ...inputLabelProps
}) => {
    const { t, i18n } = useTranslation();
    const themeColors = useTheme().palette;
    const itemName = name.split('.').pop();
    const helpIconExists = i18n.exists(`helpIcon.${itemName}.text`);
    const helpIconLink = i18n.exists(`helpIcon.${itemName}.link`);

    return (
        <Stack
            className={inlineLabel ? 'carity-inline-label' : 'carity-label'}
            direction="row"
            alignItems="center"
            sx={{
                position: 'relative',
                width: 'fit-content',
                zIndex: 1,
                label: { minWidth: 'fit-content' },
                backgroundColor: inlineLabel ? themeColors.background.paper : 'none',
                ...inputLabelProps.sx,
            }}
        >
            <InputLabel
                disabled={disabled}
                htmlFor={`${name}-field`}
                sx={{
                    whiteSpace: 'pre-line',
                    pr: required ? 0 : 0.5,
                    span: { color: themeColors.error.main, px: required ? 1 : 0 },
                }}
                {...inputLabelProps}
            >
                {label}
                <span>{required && '*'}</span>
            </InputLabel>

            {helpIconExists && (
                <Tooltip
                    id={`${name}-helper-text`}
                    sx={{ top: 7 }}
                    title={
                        helpIconLink ? (
                            <Trans
                                i18nKey={`helpIcon.${itemName}.text`}
                                components={[
                                    <Link
                                        target="__blank"
                                        href={
                                            window.carity.environment[`${itemName}Link` as keyof IEnvironment] as string
                                        }
                                        sx={{
                                            fontWeight: 'bold',
                                            color: themeColors.info.main,
                                            textDecorationColor: themeColors.info.main,
                                        }}
                                    />,
                                ]}
                                values={{ link: t(`helpIcon.${itemName}.link`) }}
                            />
                        ) : (
                            t(`helpIcon.${itemName}.text`)
                        )
                    }
                    arrow
                >
                    <InfoOutlined sx={{ pr: 1, color: themeColors.primary.main }} />
                </Tooltip>
            )}
        </Stack>
    );
};
