import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Theme } from '@mui/material';

export function getTypography(
    { palette, breakpoints, typography }: Theme,
    options?: TypographyOptions,
): TypographyOptions {
    const fontFamily = options?.fontFamily ? options.fontFamily : typography.fontFamily;

    return {
        h1: {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            paddingRight: 24,
            height: 55,
            width: 182,
            borderRight: `solid 1px ${palette.grey['300']}`,
            color: palette.text.secondary,
            [breakpoints.up('xl')]: { width: 224 },
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily,
            ...options?.h1,
        },

        h2: {
            marginBottom: 24,
            fontSize: 24,
            fontWeight: 'bold',
            fontFamily,
            ...options?.h2,
        },

        h3: {
            fontFamily,
            ...options?.h3,
        },

        h4: {
            fontFamily,
            ...options?.h4,
        },

        h5: {
            fontFamily,
            ...options?.h5,
        },

        h6: {
            fontFamily,
            ...options?.h6,
        },

        subtitle1: {
            marginBottom: 16,
            fontSize: 18,
            fontWeight: 'bold',
            fontFamily,
            ...options?.subtitle1,
        },

        subtitle2: {
            marginBottom: 16,
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily,
            ...options?.subtitle2,
        },

        body1: {
            fontFamily,
            ...options?.body1,
        },

        body2: {
            fontFamily,
            ...options?.body2,
        },

        button: {
            textTransform: 'none',
            fontWeight: 'bold',
            fontFamily,
            ...options?.button,
        },

        overline: {
            fontFamily,
            ...options?.overline,
        },

        caption: {
            marginBottom: 6,
            fontSize: 12,
            fontFamily,
            ...options?.caption,
        },
    };
}
