import { Box, Link, Stack } from '@mui/material';
import { IRentalRequestAsset, RentalRequestAssetType } from '../../../shared';
import { CloudDownloadOutlined } from '@mui/icons-material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    rentalRequestAssets?: IRentalRequestAsset[];
    rentalRequestAssetType: RentalRequestAssetType;
}
export const DownloadAssets: FC<Props> = ({ rentalRequestAssets = [], rentalRequestAssetType }) => {
    const { t } = useTranslation();

    const assets = useMemo(
        () => rentalRequestAssets.filter((asset) => asset.type === rentalRequestAssetType).map(({ asset }) => asset),
        [rentalRequestAssets, rentalRequestAssetType],
    );

    const params = useMemo(() => assets?.map(({ id }) => id).join('&assetIds='), [assets]);

    return (
        <>
            {assets?.length <= 1 && (
                <>
                    {assets.map((asset) => (
                        <Link
                            key={asset.id}
                            href={`/renting-api/my-rental-request${asset.downloadUrl}`}
                            target="__blank"
                        >
                            <Stack component="span" direction="row" alignItems="center" spacing={1} sx={{ pr: '14px' }}>
                                <Box component="span" sx={{ fontSize: '14px' }}>
                                    {asset.fileName}
                                </Box>
                                <CloudDownloadOutlined fontSize="small" />
                            </Stack>
                        </Link>
                    ))}
                </>
            )}

            {assets?.length > 1 && (
                <Link
                    href={`/renting-api/my-rental-request/assets/downloads?assetIds=${params}`}
                    download={t(`downloads.${rentalRequestAssetType}`)}
                    target="__blank"
                >
                    <Stack component="span" direction="row" alignItems="center" spacing={1} sx={{ pr: '14px' }}>
                        <Box component="span" sx={{ fontSize: '14px' }}>
                            {t(`downloads.${rentalRequestAssetType}`)}
                        </Box>
                        <CloudDownloadOutlined fontSize="small" />
                    </Stack>
                </Link>
            )}
        </>
    );
};
