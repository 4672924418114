import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Grid, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatAddress,
    formatDateString,
    formatSocialSecurityNumber,
    ICoResident,
    IPrimaryResident,
    LabelValue,
    Relation,
} from '../../../shared';
import { ageBetween18And25 } from '../../utils';

interface Props {
    resident: IPrimaryResident | ICoResident;
}

export const ResidentInfo: FC<Props> = ({ resident }) => {
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <ListItem sx={{ p: 0 }}>
                <ListItemButton sx={{ p: 2 }} onClick={() => setShowMore((prev) => !prev)}>
                    <ListItemText
                        primary={
                            resident.firstName && resident.lastName ? `${resident.firstName} ${resident.lastName}` : ''
                        }
                        secondary={
                            resident.isPrimaryResident ? t('primary') : t((resident as ICoResident).relation || '')
                        }
                    />
                    {showMore ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>

            <Collapse in={showMore} timeout="auto" unmountOnExit>
                <ListItem sx={{ p: 2, pt: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('socialSecurityNumber')}
                                value={
                                    resident.socialSecurityNumber
                                        ? formatSocialSecurityNumber(resident.socialSecurityNumber)
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue label={t('dateOfBirth')} value={formatDateString(resident.dateOfBirth)} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <LabelValue
                                label={t('relation')}
                                value={
                                    resident.isPrimaryResident
                                        ? t('primary')
                                        : t((resident as ICoResident).relation || '')
                                }
                            />
                        </Grid>
                        {!resident.isPrimaryResident && (
                            <Grid item xs={12} sm={6} md={3}>
                                <LabelValue
                                    label={t('address')}
                                    value={
                                        resident.currentAddress && !resident.isCurrentAddressSameAsPrimaryResident
                                            ? formatAddress(resident.currentAddress)
                                            : t('sameAsPrimaryResident')
                                    }
                                />
                            </Grid>
                        )}
                        {!resident.isPrimaryResident &&
                            (resident as ICoResident).relation === Relation.CHILD &&
                            ageBetween18And25(resident.dateOfBirth) && (
                                <Grid item xs={12} sm={6} md={3}>
                                    <LabelValue
                                        label={t('childSupport')}
                                        value={resident.hasChildSupport ? t('yes') : t('no')}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </ListItem>
            </Collapse>
        </>
    );
};
