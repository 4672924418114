import { FC } from 'react';
import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AutofillLoadingComponent: FC = () => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent sx={{ textAlign: 'center', m: 5 }}>
                <CircularProgress sx={{ mb: 5, mx: 'auto', display: 'block' }} />
                <Typography variant="h2">{t('myRentalRequest.autofillRentalRequestTitle')}</Typography>
                <Typography>
                    {t('myRentalRequest.autofillRentalRequestText.partOne')} <br />
                    {t('myRentalRequest.autofillRentalRequestText.partTwo')}
                </Typography>
            </CardContent>
        </Card>
    );
};
