import { object, string } from 'yup';
import { TFunction } from 'i18next';

export const addressSchema = (t: TFunction) => {
    return object().shape({
        street: string()
            .trim()
            .min(1, t('requiredField', { fieldName: t('street') }))
            .max(200, t('maxLength', { amount: 200 })),
        number: string()
            .trim()
            .min(1, t('requiredField', { fieldName: t('number') }))
            .max(20, t('maxLength', { amount: 20 })),
        box: string()
            .trim()
            .max(15, t('maxLength', { amount: 15 })),
        zip: string().trim().length(4, t('zipcodeIsInvalid')),
        city: string()
            .trim()
            .min(1, t('requiredField', { fieldName: t('city') }))
            .max(200, t('maxLength', { amount: 200 })),
        country: string()
            .trim()
            .min(1, t('requiredField', { fieldName: t('country') })),
    });
};
