import { Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';

interface Props {
    label: ReactElement | string;
    value?: ReactElement | string;
}

export const LabelValue: FC<Props> = ({ label, value }) => {
    return (
        <Stack>
            <Typography variant="caption">{label}</Typography>
            <Typography>{value || '-'}</Typography>
        </Stack>
    );
};
