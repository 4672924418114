import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IFaq, ListModel, ListQueryParam } from '../models';
import { faqClient } from '../clients/faq.client';
import { QueryKeys } from '../enums';

export function useFaq(query: ListQueryParam): UseQueryResult<ListModel<IFaq>, AxiosError> {
    return useQuery({
        queryKey: [QueryKeys.FAQ],
        queryFn: () => faqClient.getAllFaq(query),
    });
}
