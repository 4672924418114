import React, { ComponentProps, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MuiTelInput } from 'mui-tel-input';
import { FormControl } from '@mui/material';
import { CarityInputLabel } from '../input-label/input-label.component';

type Props = ComponentProps<typeof MuiTelInput> & {
    name: string;
    label: string;
};

export const ControlledPhoneNumberInput: FC<Props> = ({ name, label, ...textFieldProps }) => {
    const { control } = useFormContext();
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl>
                    <CarityInputLabel
                        name={name}
                        label={label}
                        disabled={textFieldProps.disabled}
                        required={textFieldProps.required}
                        inlineLabel={theme.name !== 'antwerp'}
                    />
                    <MuiTelInput
                        id={`${name}-field`}
                        {...field}
                        defaultCountry="BE"
                        error={!!error}
                        helperText={error ? error.message || t('fallbackError') : null}
                        {...textFieldProps}
                    />
                </FormControl>
            )}
        />
    );
};
