import { Divider, Link } from '@mui/material';
import React, { FC } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledNumberInput,
    ControlledSwitch,
    FormGrid,
    ICoResident,
    IFinancialDataForm,
    IPrimaryResident,
    IRentalRequestValidation,
    RentalRequestAssetType,
} from '../../../shared';
import { getErrorForResidentProperty } from '../../utils';
import { Assets } from '../assets/assets.component';
import { FinancialDataRentSubsidy } from '../financial-data-rent-subsidy/financial-data-rent-subsidy.component';

interface Props {
    name: string;
    fullName: string;
    isPrimaryResident: boolean;
    watchHasTaxBillForFullYear: boolean;
    updateWithInvalidate: () => void;
    field: FieldArrayWithId<IFinancialDataForm, 'residents'>;
    year: string | number;
    resident?: IPrimaryResident | ICoResident;
    validation?: IRentalRequestValidation;
    disabled?: boolean;
}

export const FinancialDataResidentCommonQuestions: FC<Props> = ({
    name,
    fullName,
    isPrimaryResident,
    watchHasTaxBillForFullYear,
    updateWithInvalidate,
    field,
    year,
    resident,
    validation,
    disabled,
}) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const watchValues = watch(name);
    const { rentSubsidy, disabilityDocumentLink } = window.carity.environment;

    return (
        <FormGrid width="100%">
            <ControlledSwitch
                name={`${name}.financialData.hasDisabilityIncome`}
                label={
                    isPrimaryResident
                        ? t('primaryResidentFinances.hasDisabilityIncome', {
                              context: watchHasTaxBillForFullYear ? 'year' : 'month',
                              year: year,
                          })
                        : t('coResidentFinances.hasDisabilityIncome', {
                              context: watchHasTaxBillForFullYear ? 'year' : 'month',
                              name: fullName,
                              year: year,
                          })
                }
                labelHelpText={
                    <>
                        {t('disabilityIncomeHelpText')}
                        <Link href={disabilityDocumentLink} target="_blank" rel="noreferrer">
                            {t('disabilityIncomeDocumentLink')}
                        </Link>
                    </>
                }
                onChangeSelected={updateWithInvalidate}
                disabled={disabled}
                required
            />

            {watchValues.financialData.hasDisabilityIncome && (
                <ControlledNumberInput
                    name={`${name}.financialData.disabilityIncome`}
                    label={
                        isPrimaryResident
                            ? t('primaryResidentFinances.disabilityIncomeAmount', {
                                  context: watchHasTaxBillForFullYear ? 'year' : 'month',
                                  year: year,
                              })
                            : t('coResidentFinances.disabilityIncomeAmount', {
                                  context: watchHasTaxBillForFullYear ? 'year' : 'month',
                                  name: fullName,
                                  year: year,
                              })
                    }
                    disabled={disabled}
                    required
                    money
                />
            )}

            {watchValues.financialData.hasDisabilityIncome && (
                <Assets
                    type={RentalRequestAssetType.DISABILITY_INCOME_DOCUMENT}
                    residentId={field.id}
                    assets={resident?.financialData?.rentalRequestAssets}
                    error={getErrorForResidentProperty('DISABILITY_INCOME_DOCUMENT', resident?.id, validation)}
                    label={t('disabilityIncomeDocumentUpload')}
                    disabled={disabled}
                    required
                />
            )}

            <Divider />

            <ControlledSwitch
                name={`${name}.financialData.hasForeignIncome`}
                label={
                    isPrimaryResident
                        ? t('primaryResidentFinances.hasForeignIncome', {
                              context: watchHasTaxBillForFullYear ? 'year' : 'month',
                              year: year,
                          })
                        : t('coResidentFinances.hasForeignIncome', {
                              context: watchHasTaxBillForFullYear ? 'year' : 'month',
                              name: fullName,
                              year: year,
                          })
                }
                labelHelpText={t('foreignIncomeHelpText')}
                onChangeSelected={updateWithInvalidate}
                disabled={disabled}
                required
            />

            {watchValues.financialData.hasForeignIncome && (
                <ControlledNumberInput
                    name={`${name}.financialData.foreignIncome`}
                    label={
                        isPrimaryResident
                            ? t('primaryResidentFinances.foreignIncomeAmount', {
                                  context: watchHasTaxBillForFullYear ? 'year' : 'month',
                                  year: year,
                              })
                            : t('coResidentFinances.foreignIncomeAmount', {
                                  context: watchHasTaxBillForFullYear ? 'year' : 'month',
                                  name: fullName,
                                  year: year,
                              })
                    }
                    disabled={disabled}
                    required
                    money
                />
            )}

            {isPrimaryResident && rentSubsidy && (
                <FinancialDataRentSubsidy
                    name={name}
                    disabled={disabled}
                    field={field}
                    resident={resident}
                    validation={validation}
                />
            )}
        </FormGrid>
    );
};
