import { IBondWithTheCityForm, IRentalRequest, ISaveRentalRequest } from '../../shared';

export const bondWithTheCityFromFormMapper = (
    originalRequest: IRentalRequest,
    bondWithTheCity: IBondWithTheCityForm,
): ISaveRentalRequest => {
    return {
        ...originalRequest,
        bondWithTheCity: bondWithTheCity.isCurrentlyRentingFromCity
            ? {
                  ...bondWithTheCity,
                  residentialRelation: null,
                  workStudyRelation: null,
              }
            : {
                  ...bondWithTheCity,
                  residentialRelation: bondWithTheCity.residentialRelation || null,
                  workStudyRelation: bondWithTheCity.workStudyRelation || null,
              },
        preferences: undefined,
    };
};
