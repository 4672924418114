import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

interface SkipLinkProps {
    skipToElement: string;
}

export const SkipLink: React.FC<SkipLinkProps> = ({ skipToElement }) => {
    const { t } = useTranslation();

    const onClick = () => {
        const targetElement = document.getElementById(skipToElement);
        targetElement?.focus();
    };

    return (
        <Button className="skip-link" onClick={onClick} href={`#${skipToElement}`} component="a">
            {t('skipToMainContentText')}
        </Button>
    );
};
