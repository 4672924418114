import { IFinancialDataForm, IRentalRequest, ISaveRentalRequest } from '../../shared';
import { sortResident } from '../utils';
import { mapDateString } from './date-string.mapper';

const mapIncomeToForm = (income?: number | null, monthly?: boolean) => {
    if (income === undefined || income === null) {
        return '';
    }
    return (income / (monthly ? 12 : 1)).toString(10);
};

export const financialDataToFormMapper = (rentalRequest: IRentalRequest): IFinancialDataForm => {
    const adultResidents = rentalRequest.residents.filter((resident) => resident.isAdult);

    return {
        residents: adultResidents
            .map((adultResident) => {
                const financialData = adultResident.financialData;
                return {
                    id: adultResident.id,
                    createdDate: adultResident.createdDate || '',
                    isPrimaryResident: adultResident.isPrimaryResident,
                    firstName: adultResident.firstName || '',
                    lastName: adultResident.lastName || '',
                    financialData: {
                        ...financialData,
                        taxYear: financialData?.hasTaxBillForFullYear === false ? 'none' : financialData?.taxYear || '',
                        jointTaxableIncome: mapIncomeToForm(financialData?.jointTaxableIncome),
                        individualTaxableIncome: mapIncomeToForm(financialData?.individualTaxableIncome),
                        monthlyTaxableIncome: mapIncomeToForm(financialData?.monthlyTaxableIncome),
                        livingWage: mapIncomeToForm(financialData?.livingWage),
                        disabilityIncome: mapIncomeToForm(
                            financialData?.disabilityIncome,
                            financialData?.hasTaxBillForFullYear === false,
                        ),
                        foreignIncome: mapIncomeToForm(
                            financialData?.foreignIncome,
                            financialData?.hasTaxBillForFullYear === false,
                        ),
                        hasLivingWage: financialData?.hasLivingWage,
                        hasDisabilityIncome: financialData?.hasDisabilityIncome,
                        hasForeignIncome: financialData?.hasForeignIncome,
                        hasBudgetRentingDocument: financialData?.hasBudgetRentingDocument,
                        expirationDateBudgetRentingDocument: financialData?.expirationDateBudgetRentingDocument
                            ? new Date(financialData?.expirationDateBudgetRentingDocument)
                            : null,
                        hasRentSubsidy: financialData?.hasRentSubsidy,
                        rentSubsidy: mapIncomeToForm(financialData?.rentSubsidy),
                        useRentSubsidyForMaxRent: financialData?.hasRentSubsidy
                            ? financialData?.useRentSubsidyForMaxRent
                            : null,
                    },
                };
            })
            .sort(sortResident),
    };
};

const mapIncomeFromForm = (income?: string, monthly?: boolean) => {
    if (income === undefined || income === '') {
        return null;
    }
    return parseFloat(income) * (monthly ? 12 : 1);
};

export const financialDataFromFormMapper = (
    originalRentalRequest: IRentalRequest,
    financialDataForm: IFinancialDataForm,
): ISaveRentalRequest => {
    const primaryResident = financialDataForm.residents.find((r) => r.isPrimaryResident);
    return {
        ...originalRentalRequest,
        preferences: undefined,
        residents: [
            ...originalRentalRequest.residents.map((resident) => {
                const financial = financialDataForm.residents.find((r) => r.id === resident.id)?.financialData;
                return {
                    ...resident,
                    financialData: {
                        ...financial,
                        jointTaxableIncome: mapIncomeFromForm(financial?.jointTaxableIncome),
                        individualTaxableIncome: financial?.hasIndividualTaxableIncome
                            ? mapIncomeFromForm(financial?.individualTaxableIncome)
                            : null,
                        monthlyTaxableIncome: financial?.hasMonthlyTaxableIncome
                            ? mapIncomeFromForm(financial?.monthlyTaxableIncome)
                            : null,
                        taxYear: financial?.taxYear !== 'none' ? financial?.taxYear : undefined,
                        livingWage: financial?.hasLivingWage ? mapIncomeFromForm(financial?.livingWage) : null,
                        disabilityIncome: financial?.hasDisabilityIncome
                            ? mapIncomeFromForm(financial?.disabilityIncome, financial?.taxYear === 'none')
                            : null,
                        foreignIncome: financial?.hasForeignIncome
                            ? mapIncomeFromForm(financial?.foreignIncome, financial?.taxYear === 'none')
                            : null,
                        hasLivingWage: financial?.hasLivingWage,
                        hasDisabilityIncome: financial?.hasDisabilityIncome,
                        hasForeignIncome: financial?.hasForeignIncome,
                        hasTaxBillForFullYear: financial?.taxYear ? financial?.taxYear !== 'none' : null,
                        isJointTaxReturn:
                            primaryResident?.financialData?.taxYear === 'none' ? false : financial?.isJointTaxReturn,
                        hasBudgetRentingDocument: financial?.isJointTaxReturn
                            ? null
                            : financial?.hasBudgetRentingDocument,
                        expirationDateBudgetRentingDocument: financial?.expirationDateBudgetRentingDocument
                            ? mapDateString(financial?.expirationDateBudgetRentingDocument) || null
                            : null,
                        rentSubsidy: financial?.hasRentSubsidy ? mapIncomeFromForm(financial?.rentSubsidy) : null,
                        useRentSubsidyForMaxRent: financial?.hasRentSubsidy
                            ? financial?.useRentSubsidyForMaxRent
                            : null,
                    },
                };
            }),
        ],
    };
};
