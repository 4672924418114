import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React, { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CarityInputLabel } from '../input-label/input-label.component';

type Props = ComponentProps<typeof Autocomplete & typeof TextField> & {
    name: string;
    label: string;
    options: any[];
    getOptionLabel?: (option: any) => string;
    allValuesAllowed?: boolean;
    multiple?: boolean;
};

export const ControlledAutocomplete: FC<Props> = ({
    name,
    label,
    options,
    getOptionLabel,
    allValuesAllowed,
    multiple,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;

    return (
        <Controller
            name={name}
            defaultValue={multiple ? [] : null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl>
                    <CarityInputLabel
                        name={name}
                        label={label}
                        required={props.required}
                        disabled={props.disabled}
                        inlineLabel={theme.name !== 'antwerp'}
                    />

                    <Autocomplete
                        id={`${name}-field`}
                        onChange={(e, data) => onChange(data)}
                        onInputChange={allValuesAllowed ? (e, data) => onChange(data) : undefined}
                        value={value}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={(option) => option}
                        autoSelect
                        autoHighlight
                        openOnFocus
                        renderOption={(props, option) => {
                            return (
                                <span {...props} style={{ minHeight: '40px' }} key={option}>
                                    {getOptionLabel ? getOptionLabel(option) : option}
                                </span>
                            );
                        }}
                        disabled={props.disabled}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                sx={{ label: { display: 'none' } }}
                                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                                error={!!error}
                                helperText={error ? error.message || t('fallbackError') : null}
                                disabled={props.disabled}
                            />
                        )}
                    />
                </FormControl>
            )}
        />
    );
};
