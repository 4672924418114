import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ControlledSwitch,
    FormGrid,
    IPrimaryResidentForm,
    NotEligibleReason,
    RentalRequestStatus,
    useGetValidation,
    useMyRentalRequest,
    usePrimaryResidentSchema,
    useUpdateMyRentalRequest,
} from '../../../shared';
import {
    CoResidentInfo,
    InfoBox,
    ResidentAddress,
    ResidentContactInfo,
    ResidentContactPreference,
    HasResidentialHistory,
} from '../../components';
import { useRentalRequest } from '../../contexts';
import { primaryResidentFromFormMapper, primaryResidentToFormMapper } from '../../mappers';

export const RequestFormPrimaryResidentPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: rentalRequest } = useMyRentalRequest();
    const { mutateAsync: updateMyRentalRequest } = useUpdateMyRentalRequest();
    const { rentalRequest: contextRentalRequest } = useRentalRequest();
    const showHasResidentialHistory = !window.carity.environment.bondWithTheCity;
    const showAResidentOwnsAProperty = window.carity.environment.aResidentOwnsPropertyCheck;

    const readOnly = useMemo(() => rentalRequest?.status !== RentalRequestStatus.DRAFT, [rentalRequest]);
    const primaryResident = useMemo(() => rentalRequest?.residents.find((r) => r.isPrimaryResident), [rentalRequest]);

    const form = useForm<IPrimaryResidentForm>({
        mode: 'all',
        resolver: yupResolver(usePrimaryResidentSchema(showHasResidentialHistory, showAResidentOwnsAProperty)),
    });
    const aResidentOwnsPropertyWatch = form.watch('aResidentOwnsProperty');
    const hasResidentialHistoryWatch = form.watch('hasResidentialHistory');
    const ssnWatch = form.watch('primaryResident.socialSecurityNumber');
    const dateOfBirthWatch = form.watch('primaryResident.dateOfBirth');

    const { data: validation } = useGetValidation(rentalRequest?.showValidation || false);

    const setValidationErrors = useCallback(() => {
        validation?.errors.forEach((validationError) => {
            if (validationError.residentId === primaryResident?.id) {
                validationError.errors.forEach((err) => {
                    if (['socialSecurityNumber'].includes(err.property)) {
                        form.setError(`primaryResident.${err.property}` as 'primaryResident.socialSecurityNumber', {
                            type: 'custom',
                            message: t(`formError.${err.message}`),
                        });
                    }
                });
            }
        });
    }, [validation, primaryResident, form, t]);

    useEffect(() => {
        setValidationErrors();
    }, [setValidationErrors]);

    useEffect(() => {
        if (rentalRequest) {
            form.reset(primaryResidentToFormMapper(rentalRequest));
        }
    }, [rentalRequest, form]);

    const onSubmit = async (data: IPrimaryResidentForm) => {
        if (rentalRequest) {
            if (rentalRequest.status === RentalRequestStatus.DRAFT) {
                await updateMyRentalRequest({ rentalRequest: primaryResidentFromFormMapper(rentalRequest, data) });
            }
            navigate('/my-rental-request/edit/residents');
        }
    };

    const update = useCallback(
        async (invalidate = false) => {
            if (rentalRequest?.status === RentalRequestStatus.DRAFT) {
                await updateMyRentalRequest({
                    rentalRequest: primaryResidentFromFormMapper(rentalRequest, form.getValues()),
                    invalidate,
                });
                setValidationErrors();
            }
        },
        [rentalRequest, updateMyRentalRequest, form, setValidationErrors],
    );

    const isRequiredInfoInvalid = useMemo(() => {
        const isSsnInvalid = contextRentalRequest?.notEligibleReason === NotEligibleReason.RESIDENT_NOT_IN_REGISTER;
        const isToYoung = contextRentalRequest?.notEligibleReason === NotEligibleReason.PRIMARY_RESIDENT_TOO_YOUNG;

        return !ssnWatch || !dateOfBirthWatch || aResidentOwnsPropertyWatch || isSsnInvalid || isToYoung;
    }, [ssnWatch, dateOfBirthWatch, aResidentOwnsPropertyWatch, contextRentalRequest]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} onBlur={() => update()} noValidate>
                <Grid container columns={{ xs: 1, md: 2 }} justifyContent="space-between">
                    <InfoBox readOnly={readOnly} />

                    <Grid item sx={{ width: { xs: '100%', md: '79%' }, order: { xs: 2, md: 1 } }}>
                        <Card id="main-content">
                            <CardContent>
                                <FormGrid width="100%">
                                    <CoResidentInfo
                                        name="primaryResident"
                                        disabled={readOnly || primaryResident?.autofill}
                                    />

                                    <ResidentAddress
                                        name="primaryResident"
                                        disabled={readOnly || primaryResident?.autofill || isRequiredInfoInvalid}
                                    />

                                    <ResidentContactInfo disabled={readOnly || isRequiredInfoInvalid} />

                                    <Divider />

                                    {showAResidentOwnsAProperty && (
                                        <Box>
                                            <Typography variant="subtitle2">
                                                {t('aResidentOwnsPropertyTitle')}
                                            </Typography>
                                            <ControlledSwitch
                                                name="aResidentOwnsProperty"
                                                label={t('aResidentOwnsProperty', {
                                                    context: window.carity.environment.theme.name,
                                                })}
                                                labelHelpText={t('aResidentOwnsPropertyTitleHelpText')}
                                                disabled={readOnly}
                                                onChangeSelected={() => update()}
                                                required
                                            />
                                        </Box>
                                    )}
                                    {showAResidentOwnsAProperty && <Divider />}

                                    {showHasResidentialHistory && (
                                        <HasResidentialHistory
                                            readOnly={readOnly}
                                            update={update}
                                            primaryResident={primaryResident}
                                            rentalRequest={rentalRequest}
                                            validation={validation}
                                        />
                                    )}

                                    {hasResidentialHistoryWatch && <Divider />}

                                    <ResidentContactPreference disabled={readOnly || isRequiredInfoInvalid} />
                                </FormGrid>

                                <Typography variant="caption" color="grey" sx={{ display: 'block', mt: 3 }}>
                                    {t('requiredInput')}
                                </Typography>
                            </CardContent>
                        </Card>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" type="submit" disabled={isRequiredInfoInvalid}>
                                {t('next')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
