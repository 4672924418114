import { IRentalRequest, RentalRequestStatus, useBreakpoint } from '../../../shared';
import { Alert, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    myRentalRequest: IRentalRequest | undefined;
}

export const MyRentalRequestStatus: FC<Props> = ({ myRentalRequest }) => {
    const { t } = useTranslation();
    const smallScreen = useBreakpoint('sm');

    return (
        <Alert
            severity={myRentalRequest?.status === RentalRequestStatus.DRAFT ? 'warning' : 'info'}
            sx={{ mb: 2, overflow: 'hidden' }}
        >
            {myRentalRequest ? (
                <>
                    {smallScreen ? (
                        <Typography variant="subtitle2" pb={0}>
                            {t('currentStatus')}: {t(`${myRentalRequest.status}.summary`)}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2">{t(`${myRentalRequest.status}.summary`)}</Typography>
                    )}
                    <Typography>{t(`${myRentalRequest.status}.description`)}</Typography>
                </>
            ) : (
                <Typography mb={0}>{t(`startMyRentalRequest`)}</Typography>
            )}
        </Alert>
    );
};
