export default {
    residence: 'Woning',
    preferencesIntro:
        'Welke woning wil jij graag huren? We houden zoveel mogelijk rekening met je voorkeuren.  Dat lukt spijtig genoeg niet altijd. We kunnen ook een woning aanbieden die niet aan al je voorkeuren beantwoordt.\n' +
        'Op dit moment hebben we alleen een aanbod betaalbaar huren in district Antwerpen.',
    preferencesIntro_antwerp:
        'Welke woning wil jij graag huren? We houden zoveel mogelijk rekening met je voorkeuren.  Dat lukt spijtig genoeg niet altijd. We kunnen ook een woning aanbieden die niet aan al je voorkeuren beantwoordt.\n' +
        'Op dit moment hebben we alleen een aanbod budgethuren in district Antwerpen.',
    elevator:
        'Heb je nood aan een aangepaste huurwoning (lift en genoeg ruimte om te draaien in badkamer, leefkamer en slaapkamer)?',

    hasRegionPreference: 'Heb je een voorkeur van district?',
    pickRegions: 'Duid aan welke districten jouw voorkeur genieten.',

    hasPropertyTypePreference: 'Heb je een voorkeur van type woning?',
    pickPropertyTypePreferences: 'Duid aan welke types van woningen je voorkeur genieten.',

    requiredAmountOfBedrooms: 'Hoeveel slaapkamers wens je?',
    maxRentPrice: 'Hoeveel wil je maximaal maandelijks uitgeven aan huur?',
    remarks: 'Wil je ons nog iets melden? Noteer het hieronder.',
    agreeToChangeResidence:
        'Akkoord verklaren om zich binnen 2 maanden na de toewijzing op het adres van de woning te domiciliëren.',
};
