import { IRouteConfig, IRouteConfigWithComponent } from '../shared';
import {
    MyRentalRequestPage,
    RequestFormBondWithTheCityPage,
    RequestFormFinancialPage,
    RequestFormOverviewPage,
    RequestFormPage,
    RequestFormPreferencesPage,
    RequestFormPrimaryResidentPage,
    RequestFormResidentsPage,
    SubmittedPage,
} from './pages';

export const rentalRequestRoutes: IRouteConfig[] = [
    { path: '/my-rental-request/edit', redirect: '/my-rental-request/edit/primary-resident' },
    { path: '/my-rental-request', component: MyRentalRequestPage },
    { path: '/my-rental-request/edit/*', component: RequestFormPage },
    { path: '/my-rental-request/submitted', component: SubmittedPage },
];
export const requestFormSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/primary-resident', component: RequestFormPrimaryResidentPage },
    { path: '/residents', component: RequestFormResidentsPage },
    { path: '/financial', component: RequestFormFinancialPage },
    { path: '/residence', component: RequestFormPreferencesPage },
    { path: '/bond-with-the-city', component: RequestFormBondWithTheCityPage },
    { path: '/overview', component: RequestFormOverviewPage },
];
