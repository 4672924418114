import { IPreferencesForm, IRentalRequest, ISaveRentalRequest } from '../../shared';

export const preferencesToFormMapper = (rentalRequest: IRentalRequest, hasRegions = true): IPreferencesForm => {
    return {
        ...rentalRequest.preferences,
        hasRegionPreference: hasRegions ? rentalRequest.preferences?.hasRegionPreference : false,
        regions: rentalRequest.preferences?.regions?.map(({ id }) => id) || [],
        maxRentPrice: rentalRequest.preferences?.maxRentPrice ? rentalRequest.preferences.maxRentPrice.toString() : '',
        propertyTypes: rentalRequest.preferences?.propertyTypes || [],
        remarks: rentalRequest.preferences?.remarks || '',
        requiredAmountOfBedrooms:
            rentalRequest.preferences?.requiredAmountOfBedrooms?.map((val) => val.toString()) || [],
    };
};

export const preferencesFromFormMapper = (
    originalRequest: IRentalRequest,
    preferencesForm: IPreferencesForm,
    agreeToChangeResidence: boolean,
): ISaveRentalRequest => {
    return {
        ...originalRequest,
        preferences: {
            ...preferencesForm,
            regions: preferencesForm?.hasRegionPreference ? preferencesForm.regions : [],
            maxRentPrice: preferencesForm.maxRentPrice ? Number(preferencesForm.maxRentPrice) : null,
            propertyTypes: preferencesForm?.hasPropertyTypePreference ? preferencesForm.propertyTypes : [],
            requiredAmountOfBedrooms: preferencesForm.requiredAmountOfBedrooms.map((val) => Number(val)),
        },
        agreeToChangeResidence,
    };
};
