import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox, ControlledSwitch, FormGrid, Relation } from '../../../shared';
import { ageBetween18And25 } from '../../utils';
import { CoResidentInfo } from '../co-resident-info/co-resident-info.component';
import { ResidentAddress } from '../resident-address/resident-address.component';

type Props = {
    autofill?: boolean;
};
export const CoResidentForm: FC<Props> = ({ autofill }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const watchIsCurrentAddressSameAsPrimaryResident = watch('isCurrentAddressSameAsPrimaryResident');
    const watchRelation = watch('relation');
    const watchDateOfBirth = watch('dateOfBirth');

    return (
        <FormGrid width="100%">
            <Typography variant="subtitle2" mb={0}>
                {t('residentInfo')}
            </Typography>
            <CoResidentInfo disabled={autofill} />

            <ControlledCheckbox
                control={control}
                label={t('sameAddressAsRequester')}
                name={`isCurrentAddressSameAsPrimaryResident`}
                disabled={autofill}
            />

            {!watchIsCurrentAddressSameAsPrimaryResident && <ResidentAddress />}

            {watchRelation === Relation.CHILD && ageBetween18And25(watchDateOfBirth) && (
                <ControlledSwitch name={`hasChildSupport`} label={t('hasChildSupport')} />
            )}
        </FormGrid>
    );
};
