import WarningIcon from '@mui/icons-material/Warning';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, useDeleteMyRentalRequest, QueryKeys } from '../../../shared';
import { useIsMutating } from '@tanstack/react-query';

export const DeleteMyRequest: FC = () => {
    const { t } = useTranslation();
    const [showWarning, setShowWarning] = useState(false);
    const isSaving = useIsMutating({ mutationKey: [QueryKeys.MY_RENTAL_REQUEST] });

    const { mutateAsync: deleteMyRentalRequest, isPending } = useDeleteMyRentalRequest();

    const onDeleteMyRentalRequest = async () => {
        setShowWarning(false);
        await deleteMyRentalRequest();
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => setShowWarning(true)}
                disabled={isPending || isSaving !== 0}
                startIcon={isPending && <CircularProgress color="inherit" size={25} />}
            >
                {t('deleteMyRentalRequest')}
            </Button>
            <Dialog open={showWarning}>
                <DialogTitleWithClose onClose={() => setShowWarning(false)}>
                    <Grid container direction="row" alignItems="center">
                        <WarningIcon color="primary" style={{ marginRight: '0.5em' }} />
                        {t('deleteMyRentalRequestWarningTitle')}
                    </Grid>
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>{t('deleteMyRentalRequestWarningText')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteMyRentalRequest} variant="contained">
                        {t('delete')}
                    </Button>
                    <Button onClick={() => setShowWarning(false)} variant="outlined" autoFocus>
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
