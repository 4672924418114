import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Login, LogoutPage, useGetUser } from '../../../auth';
import { Loading } from '../../../shared';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';

export const Main: FC = () => {
    const { data, isLoading } = useGetUser();
    const location = useLocation();

    if (isLoading) return <Loading fullscreen />;
    if (location?.pathname === '/logout') return <LogoutPage />;
    if (!data?.success && (location.pathname !== '/' || location.search === '?login=true')) return <Login />;

    return (
        <>
            <Header />
            <Routes />
        </>
    );
};
