import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { ReadOnlyInfoBox } from '../read-only-info-box/read-only-info-box.component';
import { IsEligibleInfoBox } from '../is-eligible-info-box/is-eligible-info-box.component';

type Props = {
    readOnly: boolean;
};

export const InfoBox: FC<Props> = ({ readOnly }) => {
    return (
        <Grid
            item
            sx={{
                order: { xs: 1, md: 2 },
                width: { xs: '100%', md: '20%' },
                position: { md: 'sticky' },
                height: 'fit-content',
                top: '65px',
            }}
        >
            {readOnly ? <ReadOnlyInfoBox /> : <IsEligibleInfoBox />}
        </Grid>
    );
};
