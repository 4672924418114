import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { preferencePropertyTypeClient } from '../clients';
import { QueryKeys } from '../enums';
import { IPreferencePropertyType, ListModel, ListQueryParam } from '../models';

export function useGetPreferencePropertyTypes(
    query: ListQueryParam,
): UseQueryResult<ListModel<IPreferencePropertyType>, AxiosError> {
    return useQuery({
        queryKey: [QueryKeys.PREFERECE_PROPERTY_TYPES],
        queryFn: () => preferencePropertyTypeClient.getPreferencePropertyTypes(query),
    });
}
