import { Link, Typography } from '@mui/material';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarityInputLabel, FormGrid, IError, IRentalRequestAsset, RentalRequestAssetType } from '../../../shared';
import { Asset } from '../asset/asset.component';

interface Props {
    type: RentalRequestAssetType;
    residentId?: string;
    questionId?: string;
    assets?: IRentalRequestAsset[];
    label?: string;
    labelHelpText?: string | ReactNode;
    error?: IError;
    disabled?: boolean;
    required?: boolean;
}

export const Assets: FC<Props> = ({
    type,
    residentId,
    questionId,
    assets,
    label,
    labelHelpText,
    error,
    disabled,
    required,
}) => {
    const { t } = useTranslation();
    const [assetList, setAssetList] = useState<(IRentalRequestAsset | undefined)[]>([undefined]);

    const filteredAssets = useMemo(
        () =>
            assets
                ?.filter((asset) => asset.type === type)
                .sort((asset1, asset2) =>
                    (asset1?.asset?.createdDate || '').localeCompare(asset2?.asset?.createdDate || ''),
                ) || [],
        [assets, type],
    );

    useEffect(() => {
        if (filteredAssets?.length === 0) {
            setAssetList([undefined]);
        } else {
            setAssetList(filteredAssets || [undefined]);
        }
    }, [filteredAssets, setAssetList]);

    return (
        <>
            <CarityInputLabel
                name={`${residentId}-${questionId}-${type}`}
                label={label || t('uploadLabel')}
                required={required}
                disabled={disabled}
            />

            {labelHelpText && (
                <Typography variant="caption" sx={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined }}>
                    {labelHelpText}
                </Typography>
            )}

            <FormGrid width="100%" mb={1} containerProps={{ spacing: 0 }}>
                {assetList.map((asset, index) => (
                    <Asset
                        key={index}
                        type={type}
                        residentId={residentId}
                        questionId={questionId}
                        asset={asset?.asset}
                        error={error}
                        disabled={disabled}
                    />
                ))}
                {!disabled && filteredAssets?.length < window.carity.environment.maxAmountOfFiles && (
                    <Link
                        variant="button"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setAssetList([...assetList, undefined])}
                    >
                        + {t('addAsset')}
                    </Link>
                )}
            </FormGrid>
        </>
    );
};
