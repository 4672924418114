import { IPreferencePropertyType, ListModel, ListQueryParam } from '../models';
import { rentingApi } from './renting-api.client';

class PreferencePropertyTypeClient {
    public async getPreferencePropertyTypes({
        pageSize = 100,
        ...query
    }: ListQueryParam): Promise<ListModel<IPreferencePropertyType>> {
        const { data } = await rentingApi.get<ListModel<IPreferencePropertyType>>('/preference-property-types', {
            params: {
                pageSize,
                ...query,
            },
        });
        return data;
    }
}

export const preferencePropertyTypeClient = new PreferencePropertyTypeClient();
