import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    IBondWithTheCityForm,
    RentalRequestStatus,
    useBondWithTheCityValidator,
    useMyRentalRequest,
    useUpdateMyRentalRequest,
} from '../../../shared';
import { BondWithTheCityForm, InfoBox } from '../../components';
import { bondWithTheCityFromFormMapper } from '../../mappers';

export const RequestFormBondWithTheCityPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: rentalRequest } = useMyRentalRequest();
    const readOnly = useMemo(() => rentalRequest?.status !== RentalRequestStatus.DRAFT, [rentalRequest]);
    const { mutateAsync: updateRentalRequest } = useUpdateMyRentalRequest();

    const form = useForm<IBondWithTheCityForm>({
        mode: 'all',
        resolver: yupResolver(useBondWithTheCityValidator()),
    });

    const watchIsCurrentlyRentingFromCity = form.watch('isCurrentlyRentingFromCity');

    useEffect(() => {
        if (rentalRequest) form.reset(rentalRequest.bondWithTheCity);
    }, [rentalRequest, form]);

    const handlePreviousClick = useCallback(() => {
        navigate('/my-rental-request/edit/financial');
    }, [navigate]);

    const update = useCallback(
        async (invalidate = false) => {
            if (rentalRequest?.status === RentalRequestStatus.DRAFT) {
                await updateRentalRequest({
                    rentalRequest: bondWithTheCityFromFormMapper(rentalRequest, form.getValues()),
                    invalidate,
                });
            }
        },
        [rentalRequest, updateRentalRequest, form],
    );

    const handleSubmit = async (bondWithTheCityForm: IBondWithTheCityForm) => {
        if (rentalRequest) {
            if (rentalRequest.status === RentalRequestStatus.DRAFT) {
                await updateRentalRequest({
                    rentalRequest: bondWithTheCityFromFormMapper(rentalRequest, bondWithTheCityForm),
                });
                navigate('/my-rental-request/edit/residence');
            } else {
                navigate('/my-rental-request/edit/financial');
            }
        }
    };

    return (
        <FormProvider {...form}>
            <form onBlur={() => update()} onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <main>
                    <Grid container columns={{ xs: 1, md: 2 }} justifyContent="space-between">
                        <InfoBox readOnly={readOnly} />

                        <Grid item sx={{ width: { xs: '100%', md: '79%' }, order: { xs: 2, md: 1 } }}>
                            <Card id="main-content">
                                <CardContent>
                                    <Stack mb={3}>
                                        <Typography variant="subtitle1">{t('bondWithTheCity')}</Typography>
                                    </Stack>

                                    <BondWithTheCityForm readOnly={readOnly} update={update} />

                                    <Typography variant="caption" color="grey" sx={{ display: 'block', mt: 3 }}>
                                        {t('requiredInput')}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={handlePreviousClick}>
                                    {t('previous')}
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={watchIsCurrentlyRentingFromCity === null}
                                >
                                    {t('next')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </main>
            </form>
        </FormProvider>
    );
};
