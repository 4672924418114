import { AccountCircle, InfoOutlined, Login, Logout, Person } from '@mui/icons-material';
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../../auth';
import { useBreakpoint } from '../../../shared';

export const Account: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const faqLink = window.carity.environment.faqLink;
    const privacyPolicyLink = window.carity.environment.privacyPolicyLink;
    const smallScreen = useBreakpoint('sm');

    const { data } = useGetUser();
    const user = data?.data;

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const onLogOut = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    const onLogIn = useCallback(() => {
        navigate('?login=true');
    }, [navigate]);

    const generalInformation = [
        <ListItemIcon key="generalInformation">
            <InfoOutlined sx={{ fontSize: '20px', mr: 1 }} />
            {t('generalInformation')}
        </ListItemIcon>,
        <MenuItem key="privacyPolicy" sx={{ pl: 4 }} onClick={() => window.open(privacyPolicyLink, '__blank')}>
            <Typography>{t('privacyPolicy')}</Typography>
        </MenuItem>,
        <MenuItem key="faq" sx={{ pl: 4 }} onClick={() => window.open(faqLink, '__blank')}>
            <Typography>{t('faq')}</Typography>
        </MenuItem>,
    ];

    return (
        <Box>
            <IconButton
                aria-label={t('profileButton')}
                size="large"
                edge="end"
                color="inherit"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ borderRadius: 0 }}
            >
                <AccountCircle />
                {smallScreen && (
                    <Typography sx={{ ml: 2 }}>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                )}
            </IconButton>

            <Menu
                sx={{ mt: '55px', '.MuiPaper-root:has(ul)': { top: '0 !important' } }}
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {user ? (
                    [
                        <MenuList sx={{ p: 1 }} key="menuLoggedIn">
                            <ListItemIcon>
                                <Person sx={{ fontSize: '20px', mr: 1 }} />
                                {t('account')}
                            </ListItemIcon>
                            <MenuItem key="name" sx={{ pl: 4 }}>
                                <Typography>
                                    {user?.firstName} {user?.lastName}
                                </Typography>
                            </MenuItem>
                            {generalInformation}
                            <Divider />
                            <MenuItem onClick={onLogOut} key="logout" sx={{ pl: 1 }}>
                                <ListItemIcon sx={{ display: 'contents' }}>
                                    <Logout sx={{ fontSize: '18px' }} />
                                </ListItemIcon>
                                <Typography sx={{ ml: 1 }}>{t('logOut')}</Typography>
                            </MenuItem>
                        </MenuList>,
                    ]
                ) : (
                    <MenuList sx={{ p: 1 }} key="menuLoggedOut">
                        {generalInformation}
                        <Divider />
                        <MenuItem onClick={onLogIn} key="logIn" sx={{ pl: 1 }}>
                            <ListItemIcon sx={{ display: 'contents' }}>
                                <Login sx={{ fontSize: '18px' }} />
                            </ListItemIcon>
                            <Typography sx={{ ml: 1 }}>{t('logIn')}</Typography>
                        </MenuItem>
                    </MenuList>
                )}
            </Menu>
        </Box>
    );
};
