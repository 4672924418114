import { ExpandLess, ExpandMore, LiveHelpTwoTone } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, RentalRequestAssetType } from '../../../shared';
import { Assets } from '../assets/assets.component';

interface Props {
    rentalRequest: IRentalRequest;
}

export const AdditionalQuestions: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        if (showMore === undefined) {
            setShowMore(rentalRequest.additionalQuestions?.some((question) => !question.rentalRequestAssets?.length));
        }
    }, [showMore, rentalRequest]);
    return (
        <Card>
            <CardHeader
                onClick={() => setShowMore(!showMore)}
                title={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box mr={1} pt={0.5}>
                                <LiveHelpTwoTone color="primary" />
                            </Box>
                            <Box>{t('additionalQuestions')}</Box>
                        </Box>
                        <Box>
                            <IconButton> {showMore ? <ExpandLess /> : <ExpandMore />}</IconButton>
                        </Box>
                    </Box>
                }
                sx={{
                    borderBottom: 1,
                    borderColor: 'grey.300',
                    pb: 1,
                    pt: 1,
                    minHeight: 60,
                    flexGrow: 0,
                }}
            />
            <Collapse in={showMore}>
                <CardContent>
                    <Stack spacing={2}>
                        {rentalRequest.additionalQuestions?.map((question, index) => (
                            <Box key={question.id}>
                                <Typography>{question.question}</Typography>
                                <Assets
                                    type={RentalRequestAssetType.ADDITIONAL_DOCUMENT}
                                    questionId={question.id}
                                    label=" "
                                    assets={question.rentalRequestAssets}
                                />
                                {index !== (rentalRequest.additionalQuestions?.length || 0) - 1 && <Divider />}
                            </Box>
                        ))}
                    </Stack>
                </CardContent>
            </Collapse>
        </Card>
    );
};
