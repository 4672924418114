import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    EligibleStatus,
    IFinancialDataForm,
    RentalRequestStatus,
    useFinancialDataValidator,
    useMyRentalRequest,
    useUpdateMyRentalRequest,
} from '../../../shared';
import { FinancialDataResident, InfoBox } from '../../components';
import { financialDataFromFormMapper, financialDataToFormMapper } from '../../mappers';
import { useRentalRequest } from '../../contexts';

export const RequestFormFinancialPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { bondWithTheCity, financialHelpLink, theme } = window.carity.environment;

    const { data: rentalRequest } = useMyRentalRequest();
    const { rentalRequest: rentalRequestContext } = useRentalRequest();
    const readOnly = useMemo(() => rentalRequest?.status !== RentalRequestStatus.DRAFT, [rentalRequest]);
    const { mutateAsync: updateMyRentalRequest } = useUpdateMyRentalRequest();

    const form = useForm<IFinancialDataForm>({
        mode: 'all',
        resolver: yupResolver(useFinancialDataValidator()),
    });

    const { fields } = useFieldArray({
        control: form.control,
        name: 'residents',
        keyName: 'key',
    });

    useEffect(() => {
        if (rentalRequest) {
            form.reset(financialDataToFormMapper(rentalRequest));
        }
    }, [rentalRequest, form]);

    const onSubmit = async (data: IFinancialDataForm) => {
        if (rentalRequest) {
            if (rentalRequest.status === RentalRequestStatus.DRAFT) {
                await updateMyRentalRequest({ rentalRequest: financialDataFromFormMapper(rentalRequest, data) });
            }
            bondWithTheCity
                ? navigate('/my-rental-request/edit/bond-with-the-city')
                : navigate('/my-rental-request/edit/residence');
        }
    };

    const update = useCallback(
        async (invalidate = false) => {
            if (rentalRequest?.status === RentalRequestStatus.DRAFT) {
                await updateMyRentalRequest({
                    rentalRequest: financialDataFromFormMapper(rentalRequest, form.getValues()),
                    invalidate,
                });
            }
        },
        [rentalRequest, updateMyRentalRequest, form],
    );

    return (
        <FormProvider {...form}>
            <form
                onBlur={({ target }) => {
                    if (!target?.id?.endsWith('taxYear-select')) update();
                }}
                onSubmit={form.handleSubmit(onSubmit)}
                noValidate
            >
                <Grid container columns={{ xs: 1, md: 2 }} justifyContent="space-between">
                    <InfoBox readOnly={readOnly} />

                    <Grid item sx={{ width: { xs: '100%', md: '79%' }, order: { xs: 2, md: 1 } }}>
                        <Card id="main-content">
                            <CardContent>
                                <Stack mb={3}>
                                    <Typography variant="subtitle1">
                                        {t('financial', { context: theme.name })}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t('financialIntro')}
                                        <br />
                                        {t('financialHelpLinkText')}
                                        <Link href={financialHelpLink} target="__blank">
                                            {t('financialHelpLink')}
                                        </Link>
                                    </Typography>
                                </Stack>

                                {fields.map((field, index) => (
                                    <Fragment key={index}>
                                        <FinancialDataResident
                                            name={`residents.${index}`}
                                            field={field}
                                            disabled={readOnly}
                                            updateWithInvalidate={() => update(true)}
                                        />
                                        {index !== fields.length - 1 && <Divider sx={{ mt: 4, mb: 2 }} />}
                                    </Fragment>
                                ))}

                                <Typography variant="caption" color="grey" sx={{ display: 'block', mt: 3 }}>
                                    {t('requiredInput')}
                                </Typography>
                            </CardContent>
                        </Card>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button variant="outlined" onClick={() => navigate('/my-rental-request/edit/residents')}>
                                {t('previous')}
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={rentalRequestContext?.eligibleStatus === EligibleStatus.NOT_ELIGIBLE}
                            >
                                {t('next')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
