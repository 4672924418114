import { FC, forwardRef } from 'react';
import { InputAttributes, PatternFormat } from 'react-number-format';
import { ControlledInput } from '../controlled-input/controlled-input.component';

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
};

interface NumberFormatProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const SocialSecurityNumberInput = forwardRef<typeof PatternFormat<InputAttributes>, NumberFormatProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <PatternFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                format="##.##.##-###.##"
                mask="_"
                allowEmptyFormatting
                valueIsNumericString
            />
        );
    },
);

export const ControlledSocialSecurityNumberInput: FC<Props> = ({ name, label, disabled, required }) => {
    return (
        <ControlledInput
            InputProps={{
                inputComponent: SocialSecurityNumberInput as any,
            }}
            name={name}
            label={label}
            disabled={disabled}
            required={required}
        />
    );
};
