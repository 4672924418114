import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    CarityModal,
    ICoResident,
    ICoResidentForm,
    IRentalRequest,
    IRentalRequestValidation,
    useCoResidentSchema,
    useUpdateMyRentalRequest,
} from '../../../shared';
import { mapCoResidentFromForm, mapCoResidentToForm } from '../../mappers';
import { CoResidentForm } from '../co-resident-form/co-resident-form.component';

interface Props {
    rentalRequest: IRentalRequest;
    resident: ICoResident | null;
    validation?: IRentalRequestValidation;
    show: boolean;
    handleClose: () => void;
}

export const CoResidentModal: FC<Props> = ({ rentalRequest, resident, show, handleClose, validation }) => {
    const { t } = useTranslation();

    const form = useForm<ICoResidentForm>({
        mode: 'all',
        resolver: yupResolver(useCoResidentSchema()),
    });

    const { mutateAsync: updateRentalRequest } = useUpdateMyRentalRequest();

    const setValidationErrors = useCallback(() => {
        validation?.errors.forEach((validationError) => {
            if (validationError.residentId === resident?.id) {
                validationError.errors.forEach((err) => {
                    if (['socialSecurityNumber'].includes(err.property)) {
                        form.setError(err.property as 'socialSecurityNumber', {
                            type: 'custom',
                            message: t(`formError.${err.message}`),
                        });
                    }
                });
            }
        });
    }, [validation, resident, form, t]);

    useEffect(() => {
        form.register('id');
    }, [form]);

    useEffect(() => {
        if (resident) {
            form.reset(mapCoResidentToForm(resident));
            setValidationErrors();
        } else {
            form.reset({ isCurrentAddressSameAsPrimaryResident: true });
        }
    }, [resident, form, setValidationErrors]);

    const onSubmit = useCallback(
        async (data: ICoResidentForm) => {
            await updateRentalRequest({ rentalRequest: mapCoResidentFromForm(rentalRequest, data) });
            handleClose();
        },
        [handleClose, rentalRequest, updateRentalRequest],
    );

    return (
        <FormProvider {...form}>
            <form noValidate>
                <CarityModal
                    modalTitle={t('coResident')}
                    ariaLabel="add-resident-modal"
                    onClose={handleClose}
                    open={show}
                    onClickOk={form.handleSubmit(onSubmit)}
                    sx={{ '.MuiTypography-root': { pb: 0 }, '.MuiDialogContent-root': { mb: 2 } }}
                >
                    <CoResidentForm autofill={resident?.autofill} />
                </CarityModal>
            </form>
        </FormProvider>
    );
};
