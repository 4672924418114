import { ICoResident, ICoResidentForm, IRentalRequest, ISaveRentalRequest, Relation } from '../../shared';
import { ageBetween18And25 } from '../utils';
import { mapDateString } from './date-string.mapper';

export const mapCoResidentToForm = (coResident: ICoResident): ICoResidentForm => {
    return {
        ...coResident,
        firstName: coResident.firstName || '',
        lastName: coResident.lastName || '',
        socialSecurityNumber: coResident.socialSecurityNumber || '',
        dateOfBirth: coResident.dateOfBirth || '',
        relation: coResident.relation,
        currentAddress: {
            street: coResident.currentAddress?.street || '',
            number: coResident.currentAddress?.number || '',
            zip: coResident.currentAddress?.zip || '',
            city: coResident.currentAddress?.city || '',
            box: coResident.currentAddress?.box || '',
            country: coResident.currentAddress?.country || 'België',
        },
    };
};

export const mapCoResidentFromForm = (
    originalRentalRequest: IRentalRequest,
    coResident: ICoResidentForm,
): ISaveRentalRequest => {
    return {
        ...originalRentalRequest,
        residents: [
            {
                ...coResident,
                isPrimaryResident: false,
                dateOfBirth: mapDateString(coResident.dateOfBirth),
                hasChildSupport:
                    coResident.relation === Relation.CHILD && ageBetween18And25(mapDateString(coResident.dateOfBirth))
                        ? coResident.hasChildSupport
                        : null,
            },
            ...originalRentalRequest.residents.filter((item) => item.id !== coResident.id),
        ],
        preferences: undefined,
    };
};
