type SortObject = { createdDate?: string; firstName?: string; lastName?: string; isPrimaryResident?: boolean };

export const sortResident = (res1: SortObject, res2: SortObject) => {
    if (res1.isPrimaryResident) {
        return -1;
    } else if (res2.isPrimaryResident) {
        return 1;
    } else if (res1.createdDate === res2.createdDate) {
        return `${res1.lastName} ${res1.firstName}`.localeCompare(`${res2.lastName} ${res2.firstName}`);
    }
    return (res1.createdDate || '').localeCompare(res2.createdDate || '');
};
