export default {
    editMyRentalRequest: 'Dossier wijzigen',
    setRentalRequestIncompleteWarningTitle: 'Wijzig mijn dossier',
    setRentalRequestIncompleteWarningText:
        'Door je dossier te bewerken komt je status tijdelijk terug op incompleet tot je je dossier terug indient. Vergeet dus niet jouw dossier terug in te dienen!',
    viewMyRentalRequest: 'Bekijk dossier',
    viewReadOnlyRequest: 'Bekijk mijn ingediend dossier',

    //intro
    greeting: 'Welkom {{firstName}}',
    privacyConsentInfo:
        'We hebben nog een aantal persoonsgegevens van je nodig. Die gebruiken we alleen om je dossier te evalueren. We delen je gegevens niet met anderen of voor marketingdoelen.',
    acceptProcessingPersonalData: 'Ik geef hierbij de toestemming om mijn persoonsgegevens te verwerken',
    acceptPrivacyPolicy: 'Ik heb de privacy policy gelezen.',

    //overview
    overview: 'Overzicht',
    completeTitle: 'Bijna klaar',
    completeText: 'Goed nieuws, je bent bijna klaar, je dient enkel nog je inschrijving te bevestigen.',
    completeText_leuven:
        'Goed nieuws! Je bent bijna klaar, je dient enkel nog je inschrijving te bevestigen door op ‘dossier indienen’ te klikken.',
    singleParent:
        'Op basis van de verstrekte informatie, hebben we gemerkt dat u alleen woont met minstens 1 inwonend kind (eenoudergezin). \nDit betekent dat de voorrangsregels van toepassing zijn.',
    hasNoResidentsAbove35:
        'Op basis van de verstrekte informatie, hebben we gemerkt dat u jonger bent dan 36 jaar (starter). \nDit betekent dat de voorrangsregels van toepassing zijn.',
    singleParentAndHasNoResidentsAbove35:
        'Op basis van de verstrekte informatie, hebben we gemerkt dat u jonger bent dan 36 jaar (starter) en u alleen woont met minstens 1 inwonend kind (eenoudergezin). \nDit betekent dat de voorrangsregels van toepassing zijn.',
};
