import { IRentalRequest, IRentalRequestAssetQuery, IRentalRequestValidation, ISaveRentalRequest } from '../models';
import { rentingApi } from './renting-api.client';

class MyRentalRequestClient {
    public async getMyRentalRequest(): Promise<IRentalRequest | null> {
        const { data } = await rentingApi.get<IRentalRequest | null>('/my-rental-request');
        // When the server responds with an empty body, data is an empty string
        return data ? data : null;
    }

    public async updateMyRentalRequest(rentalRequest: ISaveRentalRequest): Promise<IRentalRequest> {
        const { data } = await rentingApi.post<IRentalRequest>('/my-rental-request', rentalRequest);
        return data;
    }

    public async uploadAsset(params: IRentalRequestAssetQuery, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('asset', file);
        await rentingApi.post('/my-rental-request/assets', formData, { params });
    }

    public async deleteAsset(id: string): Promise<void> {
        await rentingApi.delete(`/my-rental-request/assets/${id}`);
    }

    public async getValidation(): Promise<IRentalRequestValidation> {
        const { data } = await rentingApi.get<IRentalRequestValidation>('/my-rental-request/validate');
        return data;
    }

    public async setShowValidation(): Promise<IRentalRequestValidation> {
        const { data } = await rentingApi.post<IRentalRequestValidation>('/my-rental-request/validate');
        return data;
    }

    public async submit(): Promise<IRentalRequest> {
        return await rentingApi.post('/my-rental-request/submit');
    }

    public async setEditable(errorMessage?: string): Promise<IRentalRequest> {
        return await rentingApi.post('/my-rental-request/incomplete', {}, { errorMessage });
    }

    public async deleteMyRentalRequest(): Promise<IRentalRequest> {
        return await rentingApi.delete('/my-rental-request');
    }
}

export const myRentalRequestClient = new MyRentalRequestClient();
