import {
    Checkbox,
    FormControl,
    FormHelperText,
    Input,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import React, { ComponentProps, FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CarityInputLabel } from '../input-label/input-label.component';

type Props = ComponentProps<typeof Select> & {
    name: string;
    label: string;
    value?: string[];
    checkBox?: boolean;
    maxWidth?: number;
    options?: { id?: string; name?: string }[];
    includeAll?: boolean;
    handleChange?: () => void;
};

export const ControlledSelect: FC<Props> = ({
    name,
    label,
    checkBox = false,
    maxWidth,
    options = [],
    includeAll,
    handleChange,
    ...selectProps
}) => {
    const { t } = useTranslation();
    const theme = window.carity.environment.theme;

    const handleOnChangeMultipleSelect = useCallback(
        (e: any) => {
            if (e.target.value.includes('all')) {
                if (e.target.value.length === options.length + 1) {
                    e.target.value = [];
                } else {
                    e.target.value = options.map(({ id }) => id);
                }
            }
            return e;
        },
        [options],
    );

    const handleOnChange = useCallback(
        (onChange: (...event: any[]) => void, e: SelectChangeEvent<any>) => {
            !selectProps.multiple ? onChange(e) : onChange(handleOnChangeMultipleSelect(e));
            handleChange && handleChange();
        },
        [handleChange, handleOnChangeMultipleSelect, selectProps],
    );

    const renderValues = useCallback(
        (selected: string | string[]) => {
            return selectProps.multiple
                ? (selected as string[])
                      ?.map((value) => options.find((item) => item.id == value))
                      ?.map((item) => (item?.name ? item.name : ''))
                      ?.sort((a, b) => a.localeCompare(b))
                      ?.splice(0, 3)
                      ?.join(', ') + ((selected as string[])?.length > 3 ? ', ...' : '')
                : options.find((item) => item.id == (selected as string))?.name;
        },
        [options, selectProps.multiple],
    );

    return (
        <Controller
            name={name}
            defaultValue={selectProps.multiple ? [] : ''}
            render={({ field: { onChange, value }, fieldState }) => (
                <FormControl
                    sx={{ maxWidth, '.MuiOutlinedInput-root': { minWidth: label.length * 8 } }}
                    error={!!fieldState.error}
                    disabled={selectProps.disabled}
                >
                    <CarityInputLabel
                        name={name}
                        label={label}
                        disabled={selectProps.disabled}
                        required={selectProps.required}
                        inlineLabel={theme.name !== 'antwerp'}
                    />
                    <Select
                        id={`${name}-select`}
                        value={value}
                        input={theme.name === 'antwerp' ? <Input /> : <OutlinedInput notched />}
                        onChange={(e) => handleOnChange(onChange, e)}
                        required={selectProps.required}
                        disabled={selectProps.disabled}
                        multiple={selectProps.multiple}
                        renderValue={(selected) => renderValues(selected)}
                        {...selectProps}
                    >
                        {includeAll && (
                            <MenuItem value="all">
                                {checkBox && <Checkbox checked={(value as string[]).length === options.length} />}
                                <ListItemText primary={t('all')} />
                            </MenuItem>
                        )}
                        {options.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {checkBox && (
                                    <Checkbox
                                        checked={item.id && value ? (value as string[]).includes(item.id) : false}
                                    />
                                )}
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </Select>
                    {fieldState.error && (
                        <FormHelperText id={`${name}-helper-text`}>
                            {fieldState.error.message || t('fallbackError')}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
