import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RentalRequestStatus, StepStatus, useMyRentalRequest } from '../../../shared';

export const ProcessDescription: FC = () => {
    const { t } = useTranslation();

    const { data: myRentalRequest } = useMyRentalRequest();

    const rentalRequestSteps = useMemo(
        () => [
            {
                status: [RentalRequestStatus.DRAFT],
                title: t('processDescription.draft.title'),
                description: t('processDescription.draft.description', {
                    context: window.carity.environment.theme.name,
                }),
            },
            {
                status: [
                    RentalRequestStatus.SUBMITTED,
                    RentalRequestStatus.IN_VERIFICATION,
                    RentalRequestStatus.DISAPPROVED,
                    RentalRequestStatus.CHANGES_REQUESTED,
                ],
                deniedStatus: [RentalRequestStatus.DISAPPROVED],
                title: t('processDescription.registered.title'),
                description: t('processDescription.registered.description', {
                    context: window.carity.environment.theme.name,
                }),
            },
            {
                status: [RentalRequestStatus.VERIFIED, RentalRequestStatus.INVITED],
                title: t('processDescription.matched.title'),
                description: t('processDescription.matched.description', {
                    context: window.carity.environment.theme.name,
                }),
            },
        ],
        [t],
    );

    const activeIndex = useMemo(() => {
        const index = rentalRequestSteps.findIndex(({ status }) =>
            status.includes(myRentalRequest?.status as RentalRequestStatus),
        );
        if (index === -1) {
            return !myRentalRequest ? -1 : rentalRequestSteps.length + 1;
        }
        return index;
    }, [myRentalRequest, rentalRequestSteps]);

    const deniedIndex = useMemo(() => {
        return rentalRequestSteps.findIndex(({ deniedStatus }) =>
            deniedStatus?.includes(myRentalRequest?.status as RentalRequestStatus),
        );
    }, [myRentalRequest, rentalRequestSteps]);

    return (
        <>
            <Stack direction="column" spacing={3}>
                {rentalRequestSteps.map((step, index) => (
                    <Card key={step?.status[0] || index} sx={{ mt: 0, mb: 0, pl: 2, pr: 2 }}>
                        <CardContent>
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <StepStatus
                                        step={index + 1}
                                        status={
                                            deniedIndex === index
                                                ? 'error'
                                                : activeIndex === index
                                                  ? 'active'
                                                  : activeIndex > index
                                                    ? 'completed'
                                                    : 'todo'
                                        }
                                    />
                                    <Typography variant="subtitle2">{step.title}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box sx={{ width: 25 }}></Box>
                                    <Box component="small" color="text.secondary" sx={{ width: '100%' }}>
                                        {step.description}
                                    </Box>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                ))}
            </Stack>
        </>
    );
};
