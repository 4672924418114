import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes as RRRoutes } from 'react-router-dom';
import { rentalRequestRoutes } from '../../../rental-request';
import { IRouteConfig, IRouteConfigWithRedirect } from '../../../shared';
import { HomePage } from '../../pages';
import { ErrorFallback } from '../error-fallback/error-fallback.component';

export const ROUTES: IRouteConfig[] = [{ path: '/', component: HomePage }, ...rentalRequestRoutes];

function isRedirect(config: IRouteConfig): config is IRouteConfigWithRedirect {
    return !!(config as IRouteConfigWithRedirect).redirect;
}

export const Routes: FC = () => {
    return (
        <RRRoutes>
            {ROUTES.map((config, i) =>
                isRedirect(config) ? (
                    <Route path={config.path} key={i} element={<Navigate to={config.redirect} />} />
                ) : (
                    <Route
                        key={i}
                        path={config.path}
                        element={
                            <ErrorBoundary FallbackComponent={ErrorFallback}>{<config.component />}</ErrorBoundary>
                        }
                    />
                ),
            )}
        </RRRoutes>
    );
};
