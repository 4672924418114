import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { differenceInYears } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EligibleStatus, Loading, NotEligibleReason } from '../../../shared';
import { useRentalRequest } from '../../contexts';

export const CompleteRentalRequestOverview: FC = () => {
    const { t } = useTranslation();
    const { rentalRequest: contextRentalRequest } = useRentalRequest();
    const { faqLink, theme } = window.carity.environment;

    const isSingleParent =
        (contextRentalRequest?.residents.some((resident) => !resident.isAdult) ||
            contextRentalRequest?.expectingChildren?.isExpecting) &&
        contextRentalRequest?.residents.filter((resident) => resident.isAdult).length === 1;

    const hasResidentsAbove35 = contextRentalRequest?.residents.some(
        (resident) => resident.dateOfBirth && differenceInYears(new Date(), new Date(resident.dateOfBirth)) >= 36,
    );

    const contactFaq = useMemo(() => {
        if (
            contextRentalRequest?.notEligibleReason === NotEligibleReason.REFERENCE_INCOME_TOO_HIGH ||
            contextRentalRequest?.notEligibleReason === NotEligibleReason.REFERENCE_INCOME_TOO_LOW
        ) {
            return (
                <Typography
                    variant="inherit"
                    pt={1}
                    sx={{
                        a: {
                            color: 'inherit',
                            textDecorationColor: 'inherit',
                            fontWeight: 500,
                        },
                    }}
                >
                    <Trans
                        i18nKey="contactFaq"
                        components={[<Link href={faqLink ? faqLink : '/'} target="__blank" />]}
                        values={{ link: t('contactFaqLink') }}
                    />
                </Typography>
            );
        }
    }, [contextRentalRequest, faqLink, t]);

    if (!contextRentalRequest) return <Loading />;
    return contextRentalRequest?.eligibleStatus === EligibleStatus.ELIGIBLE ? (
        <>
            <Box display="flex" alignItems="center" paddingBottom={1}>
                <CheckCircleOutline color="success" />
                <Typography sx={{ ml: 1, fontWeight: 'bold' }} color="success.main">
                    {t('completeTitle')}
                </Typography>
            </Box>

            <Typography component="span" variant="body2">
                {t('completeText', { context: theme.name })}
            </Typography>
            {theme.name === 'antwerp' && (isSingleParent || !hasResidentsAbove35) && (
                <Typography component="div" variant="body2">
                    <br />
                    {isSingleParent && hasResidentsAbove35 && t('singleParent')}
                    {!isSingleParent && !hasResidentsAbove35 && t('hasNoResidentsAbove35')}
                    {isSingleParent && !hasResidentsAbove35 && t('singleParentAndHasNoResidentsAbove35')}
                </Typography>
            )}
        </>
    ) : (
        <>
            <Box display="flex" alignItems="center" paddingBottom={1}>
                <ErrorOutline color="error" />
                <Typography sx={{ ml: 1, fontWeight: 'bold' }} color="error">
                    {t('notEligibleInfoText')}
                </Typography>
            </Box>

            {contextRentalRequest?.notEligibleReason && (
                <>
                    <Typography sx={{ fontWeight: 'bold' }}>{t('reason')}:</Typography>
                    <Typography variant="body2">
                        {t(contextRentalRequest?.notEligibleReason)}
                        {contactFaq}
                    </Typography>
                </>
            )}
        </>
    );
};
