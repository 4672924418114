import { ContactsTwoTone } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, LabelValue, Widget } from '../../../shared';
import { Stack } from '@mui/material';

interface Props {
    rentalRequest: IRentalRequest;
}

export const ContactInfo: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();

    return (
        <Widget icon={<ContactsTwoTone color="primary" />} title={t('contactInformation')}>
            <Stack spacing={1}>
                <LabelValue label={t('email')} value={rentalRequest.email} />
                <LabelValue label={t('phone')} value={rentalRequest.phone} />
                <LabelValue label={t('contactPreference')} value={t(rentalRequest.contactType || '')} />
            </Stack>
        </Widget>
    );
};
