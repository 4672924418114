import WarningIcon from '@mui/icons-material/Warning';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, useSetMyRentalRequestEditable } from '../../../shared';

type Props = {
    onEdit?: () => void;
    isLoading?: boolean;
};

export const EditRentalRequest: FC<Props> = ({ onEdit, isLoading }) => {
    const { t } = useTranslation();
    const [showWarning, setShowWarning] = useState(false);

    const { mutateAsync: setEditable, isPending: setEditableIsLoading } = useSetMyRentalRequestEditable();

    const onSetEditable = async () => {
        setShowWarning(false);
        await setEditable();
        if (onEdit) {
            onEdit();
        }
    };

    return (
        <>
            <Button
                sx={{ width: 'fit-content' }}
                variant="contained"
                onClick={() => setShowWarning(true)}
                disabled={isLoading || setEditableIsLoading}
                startIcon={setEditableIsLoading && <CircularProgress color="inherit" size={25} />}
            >
                {t('editMyRentalRequest')}
            </Button>

            <Dialog open={showWarning}>
                <DialogTitleWithClose onClose={() => setShowWarning(false)}>
                    <Grid container direction="row" alignItems="center">
                        <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                        {t('setRentalRequestIncompleteWarningTitle')}
                    </Grid>
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>{t('setRentalRequestIncompleteWarningText')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onSetEditable}
                        autoFocus
                        variant="contained"
                        aria-label="confirm-delete"
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {t('edit')}
                    </Button>
                    <Button
                        onClick={() => setShowWarning(false)}
                        sx={{ width: { xs: '100%', md: 'auto' }, ml: { xs: 0, md: '10px' } }}
                    >
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
