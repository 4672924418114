import { Components, Theme } from '@mui/material';

export function getComponents({ palette, breakpoints }: Theme, options?: Components): Components {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    border: '1px solid rgba(0,0,0,0.12)',
                    '&::before': {
                        display: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    margin: '20px 0',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardInfo: {
                    border: `1px solid ${palette.info.main}`,
                    color: palette.info.main,
                    '>.MuiAlert-icon': {
                        color: palette.info.main,
                    },
                },
                standardError: {
                    border: `1px solid ${palette.error.main}`,
                    color: palette.error.main,
                    '>.MuiAlert-icon': {
                        color: palette.error.main,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiInputBase-root': {
                        padding: '0 14px',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    borderBottom: `solid 1px ${palette.grey['300']}`,
                    color: palette.primary.main,
                    background: palette.background.paper,
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${palette.grey['300']}`,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        MuiCard: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    marginBottom: 12,
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    fontSize: 18,
                    fontWeight: 'bolder',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    background: '#FAFAFA',
                    justifyContent: 'flex-start',
                    padding: '16px 24px',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    opacity: 0.6,
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    top: -5,
                    label: { marginBottom: 0 },
                    '.controlled-multiline-input > *': { height: 'fit-content' },
                    '.carity-inline-label': {
                        top: 5,
                        left: 7,
                        paddingLeft: 5,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginBottom: 6,
                    '&.Mui-error': {
                        marginLeft: 0,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#141414',
                    fontSize: 14,
                    asterisk: {
                        color: palette.error.main,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    height: 48,
                },
                input: {
                    height: 48,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: 48,
                },
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    backgroundColor: palette.background.paper,
                    transform: 'none',
                    marginBottom: 6,
                },
            },
            defaultProps: {
                shrink: true,
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    legend: {
                        fontSize: 13,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                },
                fullWidth: true,
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    [breakpoints.up('xs')]: {
                        height: 55,
                        minHeight: 55,
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                    '& .app-logo img': { width: 55, marginRight: 12 },
                    '& .MuiTab-root': { minHeight: 55 },
                    '& .MuiTypography-h1': { width: '248px' },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    maxWidth: '400px',
                    fontSize: '14px',
                    whiteSpace: 'pre-line',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                lineHeight: 1.5,
            },
        },
        ...options,
    };
}
