import { Button, Grid } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Page, QueryKeys, RentalRequestStatus, useMyRentalRequest } from '../../../shared';
import {
    AdditionalQuestions,
    ContactInfo,
    CurrentResidence,
    DeleteMyRequest,
    EditRentalRequest,
    FamilySituation,
    MyRentalRequestStatus,
} from '../../components';
import { useIsMutating } from '@tanstack/react-query';

export const MyRentalRequestPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: myRentalRequest, isLoading } = useMyRentalRequest();
    const isSaving = useIsMutating({ mutationKey: [QueryKeys.MY_RENTAL_REQUEST] });

    useEffect(() => {
        document.title = t('pages.myRentalRequest', { context: window.carity.environment.theme.name });
    }, [t]);

    const actions = useMemo(() => {
        if (!myRentalRequest && !isLoading) {
            return [
                <Button variant="contained" component={Link} to="/my-rental-request/edit">
                    {t('processDescription.start')}
                </Button>,
            ];
        } else if (myRentalRequest) {
            if (myRentalRequest?.status === RentalRequestStatus.DRAFT) {
                return [
                    <DeleteMyRequest />,
                    <Button
                        variant="contained"
                        component={Link}
                        to="/my-rental-request/edit"
                        disabled={isLoading || isSaving !== 0}
                    >
                        {t('myRentalRequest.continueRequest')}
                    </Button>,
                ];
            } else if (
                [RentalRequestStatus.CHANGES_REQUESTED, RentalRequestStatus.SUBMITTED].includes(myRentalRequest?.status)
            ) {
                return [
                    <DeleteMyRequest />,
                    <Button
                        variant="outlined"
                        component={Link}
                        to="/my-rental-request/edit/primary-resident"
                        disabled={isLoading || isSaving !== 0}
                    >
                        {t('viewReadOnlyRequest')}
                    </Button>,
                    <EditRentalRequest
                        onEdit={() => navigate('/my-rental-request/edit')}
                        isLoading={isLoading || isSaving !== 0}
                    />,
                ];
            } else {
                return [
                    <DeleteMyRequest />,
                    <Button
                        variant="outlined"
                        component={Link}
                        to="/my-rental-request/edit/primary-resident"
                        disabled={isLoading || isSaving !== 0}
                    >
                        {t('viewReadOnlyRequest')}
                    </Button>,
                ];
            }
        }
    }, [myRentalRequest, isLoading, t, isSaving, navigate]);

    return (
        <Page loading={isLoading} title={t('myRentalRequest.title')} actions={actions}>
            <MyRentalRequestStatus myRentalRequest={myRentalRequest} />
            {myRentalRequest && (
                <Grid container direction="row" spacing={3}>
                    {(myRentalRequest.additionalQuestions?.length || 0) > 0 && (
                        <Grid item xs={12}>
                            <AdditionalQuestions rentalRequest={myRentalRequest} />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FamilySituation rentalRequest={myRentalRequest} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CurrentResidence rentalRequest={myRentalRequest} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContactInfo rentalRequest={myRentalRequest} />
                    </Grid>
                </Grid>
            )}
        </Page>
    );
};
