import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    RadioGroupProps,
    Typography,
} from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CarityInputLabel } from '../input-label/input-label.component';

interface Props extends RadioGroupProps {
    name: string;
    label: string;
    labelHelpText?: string | ReactElement;
    disabled?: boolean;
    required?: boolean;
    onChangeSelected?: () => void;
}

export const ControlledSwitch: FC<Props> = ({
    name,
    label,
    labelHelpText,
    defaultValue,
    disabled,
    required,
    onChangeSelected,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <Controller
            name={name}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl disabled={disabled} sx={{ '.carity-label': { mb: labelHelpText ? 0 : 1 } }}>
                    <CarityInputLabel name={name} label={label} required={required} />
                    {labelHelpText && (
                        <Typography variant="caption" sx={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined }}>
                            {labelHelpText}
                        </Typography>
                    )}

                    <RadioGroup
                        id={`${name}-field`}
                        aria-describedby={`${name}-helper-text`}
                        value={value}
                        onChange={(event, value) => {
                            onChange(value === 'true');
                            if (onChangeSelected) {
                                onChangeSelected();
                            }
                        }}
                        {...rest}
                    >
                        <FormControlLabel control={<Radio />} label={t('yes')} value={true} />
                        <FormControlLabel control={<Radio />} label={t('no')} value={false} />
                    </RadioGroup>
                    {error && (
                        <FormHelperText error id={`${name}-helper-text`}>
                            {error.message || t('fallbackError')}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
