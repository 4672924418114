import { Divider, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ControlledAutocomplete,
    ControlledSwitch,
    FormGrid,
    RentalRequestAssetType,
    ResidentialRelation,
    useGetValidation,
    useMyRentalRequest,
    WorkStudyRelation,
} from '../../../shared';
import { useFormContext } from 'react-hook-form';
import { getErrorForRentalRequestProperty } from '../../utils';
import { Assets } from '../assets/assets.component';

interface Props {
    readOnly: boolean;
    update: (x: boolean) => void;
}

export const BondWithTheCityForm: FC<Props> = ({ readOnly, update }) => {
    const { t } = useTranslation();
    const { city, residentialRelationDocumentLink: link, theme } = window.carity.environment;

    const form = useFormContext();
    const watchIsCurrentlyRentingFromCity = form.watch('isCurrentlyRentingFromCity');
    const watchResidentialRelation = form.watch('residentialRelation');
    const watchWorkStudyRelation = form.watch('workStudyRelation');

    const { data: rentalRequest } = useMyRentalRequest();
    const { data: validation } = useGetValidation(rentalRequest?.showValidation || false);

    return (
        <>
            <FormGrid width="100%">
                <Typography variant="subtitle2" mb={0}>
                    {t('residentialRelation', { city })}
                </Typography>

                <ControlledSwitch
                    name="isCurrentlyRentingFromCity"
                    label={t('isCurrentlyRentingFromCity', { context: theme.name, city })}
                    onChangeSelected={() => update(true)}
                    disabled={readOnly}
                    required
                />
            </FormGrid>

            {watchIsCurrentlyRentingFromCity === false && (
                <FormGrid width="100%">
                    <ControlledAutocomplete
                        name="residentialRelation"
                        label={t('residentialRelationLabel', { city })}
                        options={Object.values(ResidentialRelation).map((relation) => relation)}
                        getOptionLabel={(relation) => t(`ResidentialRelation.${relation}`)}
                        disabled={readOnly}
                        required
                    />
                    {watchResidentialRelation &&
                        watchResidentialRelation !== ResidentialRelation.NEVER_OR_LESS_THAN_3_YEARS && (
                            <Assets
                                type={RentalRequestAssetType.RESIDENTIAL_RELATION_DOCUMENT}
                                assets={rentalRequest?.bondWithTheCity?.rentalRequestAssets}
                                error={getErrorForRentalRequestProperty('RESIDENTIAL_RELATION_DOCUMENT', validation)}
                                label={t('residentialRelationDocument')}
                                labelHelpText={
                                    <Typography variant="caption" display="block">
                                        {t('residentialRelationDocumentHelpText')}
                                        <Link href={link} target="_blank" rel="noreferrer">
                                            {t('residentialRelationDocumentLink')}
                                        </Link>
                                    </Typography>
                                }
                                disabled={readOnly}
                                required
                            />
                        )}
                    <Divider />
                    <Typography variant="subtitle2" mb={0}>
                        {t('workStudyRelation', { city })}
                    </Typography>
                    <ControlledAutocomplete
                        name="workStudyRelation"
                        label={t('workStudyRelationLabel', { city })}
                        options={Object.values(WorkStudyRelation).map((relation) => relation)}
                        getOptionLabel={(relation) => t(`WorkStudyRelation.${relation}`)}
                        disabled={readOnly}
                        required
                    />
                    {[
                        WorkStudyRelation.IN_THE_LAST_3_YEARS,
                        WorkStudyRelation.IN_THE_LAST_5_YEARS_FOR_AT_LEAST_3_YEARS,
                        WorkStudyRelation.IN_THE_LAST_10_YEARS_FOR_AT_LEAST_3_YEARS,
                    ].includes(watchWorkStudyRelation) && (
                        <Assets
                            type={RentalRequestAssetType.WORK_STUDY_RELATION_DOCUMENT}
                            assets={rentalRequest?.bondWithTheCity?.rentalRequestAssets}
                            error={getErrorForRentalRequestProperty('WORK_STUDY_RELATION_DOCUMENT', validation)}
                            label={t('workStudyRelationDocument')}
                            disabled={readOnly}
                            required
                        />
                    )}
                </FormGrid>
            )}
        </>
    );
};
