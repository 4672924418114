import { HomeTwoTone } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IRentalRequest, LabelValue, RentalRequestAssetType, Widget } from '../../../shared';
import { DownloadAssets } from '../download-assets/download-assets.component';

interface Props {
    rentalRequest?: IRentalRequest;
}

export const CurrentResidence: FC<Props> = ({ rentalRequest }) => {
    const { t } = useTranslation();

    const primaryResident = useMemo(() => {
        return rentalRequest?.residents?.find((resident) => resident.isPrimaryResident);
    }, [rentalRequest]);

    return (
        <Widget icon={<HomeTwoTone color="primary" />} title={t('currentResidence')}>
            <Stack spacing={1}>
                <LabelValue
                    label={t('currentAddress')}
                    value={
                        primaryResident?.currentAddress && (
                            <>
                                <Box component="span" sx={{ display: 'block' }}>
                                    {primaryResident.currentAddress.street} {primaryResident.currentAddress.number}
                                </Box>
                                <span>
                                    {primaryResident.currentAddress.zip} {primaryResident.currentAddress.city}
                                </span>
                            </>
                        )
                    }
                />

                {!window.carity.environment.bondWithTheCity && (
                    <LabelValue
                        label={t('residentialHistory', { city: window.carity.environment.city })}
                        value={
                            <Stack component="span" direction="column" spacing={1}>
                                <span>{rentalRequest?.hasResidentialHistory ? t('yes') : t('no')}</span>
                                <DownloadAssets
                                    rentalRequestAssets={rentalRequest?.rentalRequestAssets}
                                    rentalRequestAssetType={RentalRequestAssetType.RESIDENTIAL_HISTORY_DOCUMENT}
                                />
                            </Stack>
                        }
                    />
                )}

                {window.carity.environment.aResidentOwnsPropertyCheck && (
                    <LabelValue
                        label={t('aResidentOwnsProperty')}
                        value={rentalRequest?.aResidentOwnsProperty ? t('yes') : t('no')}
                    />
                )}
            </Stack>
        </Widget>
    );
};
