import { Close } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ModalProps,
    Stack,
    Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<ModalProps, 'onClose'> {
    modalTitle: string;
    ariaLabel: string;
    onClose: () => void;
    onClickOk: () => void;
}

export const CarityModal: FC<Props> = ({ children, open, onClose, onClickOk, ariaLabel, modalTitle, ...props }) => {
    const { t } = useTranslation();

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={ariaLabel}
            scroll="paper"
            fullWidth
            maxWidth="xl"
            {...props}
        >
            <DialogTitle>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Typography sx={{ flex: 1 }} variant="subtitle1">
                        {modalTitle}
                    </Typography>
                    <IconButton aria-label="close-modal" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent sx={{ p: '20px 40px 0 8px' }}>
                <Stack spacing={2} sx={{ flex: 1 }}>
                    {children}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={onClickOk}>
                        {t('save')}
                    </Button>
                    <Button variant="outlined" onClick={handleClose}>
                        {t('cancel')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
