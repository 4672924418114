import { useTranslation } from 'react-i18next';
import { boolean, mixed, object, string } from 'yup';
import { ContactType } from '../enums';
import { addressSchema } from './address.validator';
import { baseResidentSchema } from './base-resident.validator';
import { useMemo } from 'react';

export function usePrimaryResidentSchema(hasResidentialHistoryRequired: boolean, aResidentOwnsPropertyCheck: boolean) {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object({
                primaryResident: baseResidentSchema(t).shape({
                    currentAddress: addressSchema(t),
                }),
                email: string()
                    .email(t('invalidFormat', { fieldName: t('email') }))
                    .trim()
                    .min(1, t('requiredField', { fieldName: t('email') }))
                    .max(200, t('maxLength', { amount: 200 }))
                    .required(),
                phone: string()
                    .trim()
                    .min(1, t('requiredField', { fieldName: t('phone') }))
                    .max(30, t('maxLength', { amount: 30 }))
                    .matches(/^[+0-9]{1,4}(\s[0-9]+)+$/, t('phoneIsInvalid'))
                    .required(t('requiredField', { fieldName: t('phone') })),
                contactType: mixed<ContactType>()
                    .oneOf(Object.values(ContactType), t('requiredField', { fieldName: t('contactType') }))
                    .required()
                    .nullable()
                    .test('required', t('requiredQuestion'), (value) => value !== null),
                aResidentOwnsProperty: boolean()
                    .required()
                    .nullable()
                    .test('required', t('requiredQuestion'), (value) => !aResidentOwnsPropertyCheck || value !== null),
                hasResidentialHistory: boolean()
                    .required()
                    .nullable()
                    .test(
                        'required',
                        t('requiredQuestion'),
                        (value) => !hasResidentialHistoryRequired || value !== null,
                    ),
            }),
        [t, hasResidentialHistoryRequired, aResidentOwnsPropertyCheck],
    );
}
